import axios from "axios";
import tilesURL from "./axios-tiles";
import * as requestResults from "../constants/requestResults";
import db from "../store/db";
import store from '../store/';

const isOffline = () => !store.getState().app.online;

const cacheThenNetworkStrategy = (url, props) => {
  let urlKey;
  // Need to better manage this as /0/... is being replaced by /./ in the IndexedDB
  if (props.subdomains.length > 0) {
    const regexstring = new RegExp("/(" + props.subdomains.join("|") + ").");
    urlKey = url.replace(regexstring, "/" + props.subdomains.join("") + ".");
  } else {
    urlKey = url;
  }


  return db
    .table("tiles")
    .get(urlKey)
    .then(response => {
      if (response) {
        if (props.silent) {
          return "";
        } else {
          return response.data;
        }
      } else {
        return networkThenCacheStrategy(url, props);
      }
    })
    .catch(cacheError => {
      console.log("Retrieving from cache error:", cacheError);
      return networkOnlyStrategy(url, props);
    });
};

const replaceBatchUrl = (url, props) => {
  let regex, replacementUrl;
  const subdomains = props.subdomains.length > 0 ? props.subdomains : ["a"];
  const subdomain = subdomains[Math.floor(Math.random() * subdomains.length)];

  switch (props.layername) {
    case "OSM":
      regex = /osm.satelytics.io/;
      replacementUrl = subdomain + ".tile.openstreetmap.org";
      break;
    default:
      break;
  }
  return url.replace(regex, replacementUrl);
};

const storeInCache = (url, props, data) => {
  if (props.localDownload) {
    url = replaceBatchUrl(url, props);
  }
  let urlKey;
  if (props.subdomains.length > 0) {
    const regexstring = new RegExp("/(" + props.subdomains.join("|") + ").");
    urlKey = url.replace(regexstring, "/" + props.subdomains.join("") + ".");
  } else {
    urlKey = url;
  }

  db.table("tiles")
    .put({
      layername: props.layername,
      type: props.type,
      timestamp: new Date().getTime(),
      url: urlKey,
      data: data
    })
    .catch(err => {
      console.log("Error loading tile into database:", err);
    });
};

const networkOnlyStrategy = (url, props) => {
  
  // Web Worker does not have access to Redux Store  
  // and function will return true so
  // use batch parameter to get around this
  if( isOffline() && !props.batch ) {
    return "";
  }

  return tilesURL
    .get(url)
    .then(response => {
      return response.data;
    })
    .catch(fetchError => {
      return "";
    });
};

const networkThenCacheStrategy = (url, props) => {
  // Web Worker does not have access to Redux Store  
  // and function will return true so
  // use batch parameter to get around this
  if( isOffline() && !props.batch ) {
    return "";
  }

  return tilesURL
    .get(url, { cancelToken: props.cancelToken })
    .then(response => {
      storeInCache(url, props, response.data);
      if (props.silent) {
        return "";
      } else {
        return response.data;
      }
    })
    .catch(fetchError => {
      if (axios.isCancel(fetchError)) {
        return requestResults.REQUEST_CANCELED;
      } else {
        if (fetchError.response && fetchError.response.status === 404) {
          return requestResults.REQUEST_NOT_FOUND;
        } else {
          return requestResults.REQUEST_ERROR;
        }
      }
    });
};

export {
  cacheThenNetworkStrategy,
  networkThenCacheStrategy,
  networkOnlyStrategy
};
