import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import moment from "moment";

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
});

const DatePickers = props => {
  const {
    defaultDate = moment().format("YYYY-MM-DD"),
    label = "",
    helperText = "",
    id = "date",
    classes
  } = props;

  const changeHandler = event => {
    if (props.emitEvent) {
      props.emitEvent(event);
    }
  };

  return (
    <form className={classes.container} noValidate>
      <TextField
        id={id}
        label={label}
        type="date"
        onChange={changeHandler}
        helperText={helperText}
        defaultValue={defaultDate}
        className={classes.textField}
        InputLabelProps={{
          shrink: true
        }}
      />
    </form>
  );
};

DatePickers.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DatePickers);
