import React, {
  Component
} from "react";
import L from "leaflet";

import * as mapModule from "../../modules/Map/map";
import Modal from "../UI/Modal/Modal";
import LayerControl from "../../containers/LayerControl/LayerControl";
import "../../modules/Map/satelyticsLayer";
import {
  getMapZoomCenter,
  setMapZoomCenter
} from "../../store/localStorage/index";
import {
  withStyles
} from "@material-ui/core/styles";
import "../../modules/Map/Leaflet.VectorGrid.Protobuf";
import * as contentviews from "../../constants/contentViews"

const styles = theme => ({
  root: {
    width: "100%",
    height:"100%",
  }
});

function Map(props) {
  const {
    classes,
    id
  } = props;
  return <div className={classes.root} id={id} />;
}

const StyledMap = withStyles(styles)(Map);

class mapComponent extends Component {
  componentDidMount() {
    this.map = mapModule.initializeMap(this.props.id, this.props
      .mapZoomCenter);

    this.observationLayer = mapModule.initializeObservationLayer(this.map);
    this.heatmapLayer = mapModule.initializeHeatmapLayer(this.props.alerts);

    this.configureMap(this.map);
  }

  componentWillUnmount() {
    if (this.map){
      this.map.stopLocate();
    }  
  }

  configureMap(map) {
    this.props.storeMainMap(map);

    this.setMapHooks(map);

    this.refreshObservationLayer(this.observationLayer);
  }

  setMapHooks = map => {
    map.on("zoomend", () => {
      console.log("Zoom End Fired")
      const _zoom = map.getZoom();
      const _center = map.getCenter();
      setMapZoomCenter({
        zoom: _zoom,
        center: _center
      })
      this.props.onZoomEnd({
        zoom: _zoom,
        center: _center
      });
    });
    map.on("moveend", () => {
      console.log("Move End Fired")
      const _zoom = map.getZoom();
      const _center = map.getCenter();
      setMapZoomCenter({
        zoom: _zoom,
        center: _center
      })
      this.props.onZoomEnd({
        zoom: _zoom,
        center: _center
      });
    });
  };

  refreshObservationLayer = layer => {
    if (this.props.alerts.length > 0) {
      layer.clearLayers();
      
      layer.addLayers(mapModule.addMarkers(this.props.alerts.sort((a, b) =>
          a.geometry.type <= b.geometry.type ? 1 : -1), contentviews.MAIN_VIEW_MAP, this.props.alertSelectedHandler
      ))
      // layer.addData(
      //   this.props.alerts.sort((a, b) =>
      //     a.geometry.type <= b.geometry.type ? 1 : -1
      //   )
      // );
    }
  };

  componentDidUpdate(prevProps) {

    if (prevProps.alerts.length !== this.props.alerts.length) {
      this.refreshObservationLayer(this.observationLayer);
      // If we are handling refresh event, use previous extent
      // oterwise zoom to observations
      if (this.props.mapInitialized) {
        if (this.observationLayer.getLayers()
          .length > 0) {
          mapModule.centerMapOnLayer(this.map, this.observationLayer);
        }
      }

    }
    this.map.invalidateSize();
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          show={this.props.showLayerControl}
          modalClosed={this.props.toggleLayerControl}
        >
          <LayerControl 
            cancel={this.props.toggleLayerControl} 
            map={this.map} 
            heatmapLayer={this.heatmapLayer} 
            obsLayer={this.observationLayer}
            currentView={this.props.currentView}
          />
        </Modal>
        {/* <StyledMap id={this.props.id} /> */}
        <div style={{width:"100%", height:"100%"}} id={this.props.id} />
      </React.Fragment>
    );
  }
}

export default mapComponent;
