import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import SioLogger from "../../modules/Logger/logger";
import {
  TrendingDown,
  TrendingUp,
  TrendingFlat,
  ReportProblem,
  Cloud,
  Notifications,
  NotificationsOff,
  Info,
  Cancel,
  HelpOutline
} from "@material-ui/icons";

const logger = SioLogger('src/containers/Indicator/indicator.js');

const indicatorStyles = theme => ({
  secondary: {
    fontSize: 12
  },
  icon: {
    fontSize: 18
  }
});

class Indicator extends PureComponent {
  componentDidMount() {
    // console.log("Status Component mounted for ID", this.props.observation.id);
  }
  componentWillUnmount() {
    // console.log(
    //   "Status Component is unmounting for ID",
    //   this.props.observation.id
    // );
  }

  setSymbol = () => {
    const indicator = this.props.indicatorList.find(item => {
      return item.id === this.props.observation.properties.indicator_id;
    });

    if (indicator) {
      // const symbolCode = indicator.code.split(indicator.product).pop();
      const symbolCode = indicator.code;
      let itemColor = "black";
      try {
        if (indicator.render && indicator.render.hasOwnProperty("color")) {
          itemColor = indicator.render.color;
        } else {
          itemColor = JSON.parse(indicator.render_class).markerColor;
        }
      } catch (error) {
        logger.warn(`Error parsing indicator render object: ${indicator.render}`);
      }
      switch (symbolCode.toUpperCase()) {
        case "LOW":
          return (
            <Notifications
              className={
                this.props.display === "both" ? this.props.classes.icon : null
              }
              style={{ color: itemColor }}
            />
          );
        case "MOD":
          return (
            <Notifications
              className={
                this.props.display === "both" ? this.props.classes.icon : null
              }
              style={{ color: itemColor }}
            />
          );
        case "HIGH":
          return (
            <Notifications
              className={
                this.props.display === "both" ? this.props.classes.icon : null
              }
              style={{ color: itemColor }}
            />
          );
        case "DETECT":
          return (
            <Info
              className={
                this.props.display === "both" ? this.props.classes.icon : null
              }
              style={{ color: itemColor }}
            />
          );
        case "ND":
          return (
            <NotificationsOff
              className={
                this.props.display === "both" ? this.props.classes.icon : null
              }
              style={{ color: itemColor }}
            />
          );
        case "DECRCRITCL":
          return (
            <ReportProblem
              className={
                this.props.display === "both" ? this.props.classes.icon : null
              }
              style={{ color: itemColor }}
            />
          );
        case "DECRSM":
          return (
            <TrendingDown
              className={
                this.props.display === "both" ? this.props.classes.icon : null
              }
              style={{ color: itemColor }}
            />
          );
        case "NOCHG":
          return (
            <TrendingFlat
              className={
                this.props.display === "both" ? this.props.classes.icon : null
              }
              style={{ color: itemColor }}
            />
          );
        case "INCRSM":
          return (
            <TrendingUp
              className={
                this.props.display === "both" ? this.props.classes.icon : null
              }
              style={{ color: itemColor }}
            />
          );
        case "CLDSHDW":
          return (
            <Cloud
              className={
                this.props.display === "both" ? this.props.classes.icon : null
              }
              style={{ color: itemColor }}
            />
          );
        case "NA":
          return (
            <NotificationsOff
              className={
                this.props.display === "both" ? this.props.classes.icon : null
              }
              style={{ color: itemColor }}
            />
          );
        case "REMOVED":
          return (
            <Cancel
              className={
                this.props.display === "both" ? this.props.classes.icon : null
              }
              style={{ color: itemColor }}
            />
          );
        default:
          return (
            <HelpOutline
              className={
                this.props.display === "both" ? this.props.classes.icon : null
              }
            />
          );
      }
    } else {
      return null;
    }
  };

  state = {};

  render() {
    return (
      <React.Fragment>
        {["symbol", "both"].includes(this.props.display) ? (
          <div>{this.setSymbol()}</div>
        ) : null}
        {["text", "both"].includes(this.props.display) ? (
          <div className={this.props.classes.secondary}>
            {this.props.observation.properties.indicator_name}
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    indicatorList: state.lookups.indicators.filter(item => {
      // return item.product === ownProps.observation.properties.product_code;
      return item.package === ownProps.observation.properties.package_id;
    })
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(indicatorStyles)(Indicator));
