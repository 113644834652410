import React from 'react';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    chatbubble: {
        position: 'relative',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        width: 250,
        borderRadius: '5px',
        padding: '.7rem',

        '&::before': {
            position: 'absolute',
            content: '""',
            width: 0,
            height: 0,
            borderLeft: '15px solid  ' + theme.palette.primary.main,
            borderRight: '6px solid transparent',
            borderTop: '6px solid  ' + theme.palette.primary.main,
            borderBottom: '12px solid transparent',
            left: 15,
            bottom: -15,
        },
    }
}))

export default function CommentPreview({item}) {
    const classes = useStyles();
    const { comment } = item.data;

    return (
        <p variant="body2" className={classes.chatbubble}>
            {comment}
        </p>
    )

}