import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  products: [],
  status: [],
  indicators: [],
  systemdefaults: [],
  packages: [],
  classifications: [],
  sources: [],
  assets: [],
  loading: false,
  error: null
};

const fetchProductsSuccess = (state, action) => {
  return updateObject(state, {
    products: action.data,
    loading: false,
    error: null
  });
};

const fetchStatusSuccess = (state, action) => {
  return updateObject(state, {
    status: action.data,
    loading: false,
    error: null
  });
};

const fetchIndicatorsSuccess = (state, action) => {
  return updateObject(state, {
    indicators: action.data,
    loading: false,
    error: null
  });
};

const fetchSystemDefaultsSuccess = (state, action) => {
  return updateObject(state, {
    systemdefaults: action.data,
    loading: false,
    error: null
  });
};

const fetchPackagesSuccess = (state, action) => {
  return updateObject(state, {
    packages: action.data,
    loading: false,
    error: null
  });
};

const fetchClassificationsSuccess = (state, action) => {
  return updateObject(state, {
    classifications: action.data,
    loading: false,
    error: null
  });
}

const fetchSourcesSuccess = (state, action) => {
  return updateObject(state, {
    sources: action.data,
    loading: false,
    error: null
  });
}

const fetchAssetsSuccess = (state, action) => {
  return updateObject(state, {
    assets: action.data,
    loading: false,
    error: null
  });
}

const fetchLookupsStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null
  });
};

const fetchLookupsFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  });
};
//we could consolidate the refresh and fetch actions if we don't need to
//differentiate how we handle different types.
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LOOKUPS_START:
      return fetchLookupsStart(state, action);
    case actionTypes.FETCH_LOOKUPS_FAILED:
      return fetchLookupsFailed(state, action);
    case actionTypes.FETCH_PRODUCTS_SUCCESS:
      return fetchProductsSuccess(state, action);
    case actionTypes.FETCH_STATUS_SUCCESS:
      return fetchStatusSuccess(state, action);
    case actionTypes.FETCH_INDICATORS_SUCCESS:
      return fetchIndicatorsSuccess(state, action);
    case actionTypes.FETCH_SYSTEM_DEFAULTS_SUCCESS:
      return fetchSystemDefaultsSuccess(state, action);
    case actionTypes.FETCH_PACKAGES_SUCCESS:
      return fetchPackagesSuccess(state, action);
    case actionTypes.FETCH_CLASSIFICATIONS_SUCCESS:
      return fetchClassificationsSuccess(state, action);
    case actionTypes.FETCH_SOURCES_SUCCESS:
      return fetchSourcesSuccess(state, action);
    case actionTypes.FETCH_ASSETS_SUCCESS:
      return fetchAssetsSuccess(state, action);
    // case actionTypes.REFRESH_LOOKUPS_START:
    //   return fetchLookupsStart(state, action);
    // case actionTypes.REFRESH_LOOKUPS_FAILED:
    //   return fetchLookupsFailed(state, action);
    // case actionTypes.REFRESH_PRODUCTS_START:
    //   return fetchLookupsStart(state, action);
    // case actionTypes.REFRESH_PRODUCTS_FAILED:
    //   return fetchLookupsFailed(state, action);
    // case actionTypes.REFRESH_PRODUCTS_SUCCESS:
    //   return fetchProductsSuccess(state, action);
    // case actionTypes.REFRESH_STATUS_START:
    //   return fetchLookupsStart(state, action);
    // case actionTypes.REFRESH_STATUS_FAILED:
    //   return fetchLookupsFailed(state, action);
    // case actionTypes.REFRESH_STATUS_SUCCESS:
    //   return fetchStatusSuccess(state, action);
    // case actionTypes.REFRESH_INDICATORS_START:
    //   return fetchLookupsStart(state, action);
    // case actionTypes.REFRESH_INDICATORS_FAILED:
    //   return fetchLookupsFailed(state, action);
    // case actionTypes.REFRESH_INDICATORS_SUCCESS:
    //   return fetchIndicatorsSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
