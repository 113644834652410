import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: theme.spacing(2),
      },
  }));
  
  export default function SideDrawerButton(props) {
    const classes = useStyles();

    return (
        
            <React.Fragment>
                <Hidden mdUp >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={props.handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                </Hidden>
                <Hidden smDown >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={props.handleDrawerExpansion}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                </Hidden>
            </React.Fragment>
            
    );
  }