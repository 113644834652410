import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Input from "../../components/UI/Input/Input";
import Button from '@material-ui/core/Button';
import Cookies from "js-cookie";
import { withStyles } from "@material-ui/core/styles";
import Alert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import * as actions from "../../store/actions/index";
import * as settings from "../../constants/settings";
import { getAppInitialized } from "../../store/localStorage/index";
import { isUsingFusionAuth, fusionAuthLoginRedirect } from "./fusion-auth-service";

const styles = theme => ({
  login: {
    margin: "5.0rem auto",
    width: "80%",
    textAlign: "center",
    padding: "0.625rem",
    boxSizing: "border-box",
    ['@media (min-width:37.5rem)']: { // eslint-disable-line no-useless-computed-key
      width: '500px'
    }
  },
  version: {
    textAlign: "right",
    fontSize: "small"
  },
  snackbar: {
    top:"3.0rem",
  },
  close: {
    padding: theme.spacing(0.5),
  },
})

class Auth extends Component {
  state = {
    token: "",
    controls: {
      email: {
        elementType: "input",
        muiConfig: {
          label: "Email",
        },
        elementConfig: {
          type: "email",
          placeholder: "Email Address",
          autoComplete: "email",
          name:"email",
          id:"email"
        },
        value: "",
        validation: {
          required: true,
          isEmail: true
        },
        valid: false,
        touched: false
      },
      password: {
        elementType: "input",
        muiConfig: {
          label: "Password",
        },
        elementConfig: {
          type: "password",
          placeholder: "Password",
          autoComplete: "current-password"
        },
        value: "",
        validation: {
          required: true,
          minLength: 8
        },
        valid: false,
        touched: false
      },
      code: {
        elementType: "input",
        muiConfig: {
          label: "MFA Code",
        },
        elementConfig: {
          type: "text",
          placeholder: "",
          autoComplete: ""
        },
        value: "",
        validation: {
          required: true
        },
        valid: false,
        touched: false
      }
    },
    localerror: this.props.error ? true : false,
    using_fusion_auth: isUsingFusionAuth(),
  };


  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.error && this.props.error){
      this.setState({localerror:true})
    } 
  }

  checkValidity(value, rules) {
    let isValid = true;
    if (!rules) {
      return true;
    }

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid;
    }

    if (rules.isEmail) {
      const pattern =
        /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
      isValid = pattern.test(value) && isValid;
    }

    if (rules.isNumeric) {
      const pattern = /^\d+$/;
      isValid = pattern.test(value) && isValid;
    }

    return isValid;
  }

  inputChangedHandler = (event, controlName) => {
    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        valid: this.checkValidity(
          event.target.value,
          this.state.controls[controlName].validation
        ),
        touched: true
      }
    };
    this.setState({
      controls: updatedControls
    });
  };

  resetInputHandler = (resetValue, controlName) => {
    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: resetValue,
        valid: false,
        touched: false
      }
    };
    this.setState({
      controls: updatedControls
    });
  };

  /**
   * Uses the users email to generate the fusion auth endpoint
   */
  fusionAuthLogin = () => {
    fusionAuthLoginRedirect(this.state.controls.email.value);
  }

  /**
   * Handles confirmation of the token
   */
  tokenConfirmation = () => {
    this.props.onAuthConfirm(this.state.token, this.state.controls.code.value).catch(error => {
      this.setState({token:""});
      this.resetInputHandler("", "code");
    });
  }

  /**
   * Submits the username and password to the django backend.
   */
  djangoLogin = () => {
    console.log('Django login!');
    this.props.onAuth(
      this.state.controls.email.value.toLowerCase(),
      this.state.controls.password.value
    ).then(data => {

      if (data && data.hasOwnProperty("ephemeral_token")){
        this.setState({token:data.ephemeral_token});
      } else {
        
      }
    });
  }

  /**
   * Handler for the form submit
   */
  submitHandler = event => {
    event.preventDefault();
    if (this.state.using_fusion_auth) {
      this.fusionAuthLogin();
    }
    else if (this.state.token){
      this.tokenConfirmation();
    } else {
      this.djangoLogin();
    }
  };

  handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({localerror:false});
  };

  render() {
    const formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key]
      });
    }
    // ###################################################
    // Bugfix #167463559
    // iOS changes position of elements depending on which one
    // has input being entered.  Make sure the the email
    // element is always first.
    formElementsArray.sort((a, b) => {
      const controlA = a.id.toUpperCase()
      const controlB = b.id.toUpperCase()
      if (controlA < controlB) {
        return -1;
      }
      if (controlA > controlB) {
        return 1;
      }
      return 0;
    });
    // ###################################################

    const elements = [];
    let form = formElementsArray.map(formElement => {
      if (this.state.using_fusion_auth) {
        if (formElement.id === 'email') {
          return (
            <Input 
              key={formElement.id}
              elementType={formElement.config.elementType}
              muiConfig={formElement.config.muiConfig}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation}
              touched={formElement.config.touched}
              changed={event => this.inputChangedHandler(event, formElement.id)}
            />
          )
        }
      }
      else if (this.props.loading && this.state.token){
        if (formElement.id == "code") {
          return (
            <Input
              key={formElement.id}
              elementType={formElement.config.elementType}
              muiConfig={formElement.config.muiConfig}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation}
              touched={formElement.config.touched}
              changed={event => this.inputChangedHandler(event, formElement.id)}
            />
            )
        }
      } else {
        if (formElement.id != "code") {
          return (
            <Input
              key={formElement.id}
              elementType={formElement.config.elementType}
              muiConfig={formElement.config.muiConfig}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation}
              touched={formElement.config.touched}
              changed={event => this.inputChangedHandler(event, formElement.id)}
            />
            )
        }
      }
    }
    );
    elements.push(form);
    elements.push(
      <div key="submit">
          <Button variant="contained" color="primary" type="submit">
            Login
          </Button>
        </div>
    );
    
    let errorMessage = null;

    if (this.props.error) {
      
      let _message = "";
      if (this.props.error.hasOwnProperty("non_field_errors")){
        _message = this.props.error.non_field_errors[0]
      } else if (this.props.error.hasOwnProperty("message")){
        _message = this.props.error.message;
      }
      errorMessage = (
          <Snackbar 
            anchorOrigin={{vertical:'top', horizontal:'center'}} 
            open={this.state.localerror}
            onClose={this.handleSnackbarClose} 
            autoHideDuration={6000} 
            message={_message}
            key={'topcenter'}
            className = {this.props.classes.snackbar}
          >
            <Alert onClose={this.handleSnackbarClose} variant="filled" severity="error">
              {_message}
            </Alert>
          </Snackbar>
      );
    }

    let authRedirect = null;
    if (this.props.isAuthenticated) {
      // Check if user has configured settings yet, if not redirect to /settings screen
      const _initialized = getAppInitialized();
      authRedirect = (<Redirect to="/" />);
      if (!_initialized){
        authRedirect = (<Redirect to="/settings" />);
      }
      
      // authRedirect = (
      //   <Redirect
      //     to={
      //       this.props.authRedirectPath === "/auth"
      //         ? "/"
      //         : this.props.authRedirectPath
      //     }
      //   />
      // );
    }

    return (

        <Paper className={this.props.classes.login}>
          {authRedirect}
          {errorMessage}
          <form onSubmit={this.submitHandler}>
            {elements}
          </form>
          <div className={this.props.classes.version}>v{settings.CURRENT_VERSION}</div>
        </Paper>
        
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (email, password) =>
      dispatch(actions.authenticate(email, password)),
    onAuthConfirm: (token, code) => dispatch(actions.authenticateConfirm(token, code)),
    onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath("/"))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Auth));
