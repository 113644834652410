import React from "react";

import { withStyles } from "@material-ui/core/styles";
import MUIButton from "../../UI/Button/MUIButton";
import Button from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    width: "100%",
    padding: "1rem"
  },
  center: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly"
  }
});

const confirmation = props => {
  return (
    <div className={props.classes.root}>
      <div>
        <p>Please Confirm:</p>
        <br />
        <p>{props.message}</p>
        <br />
      </div>
      <div className={props.classes.center}>
        {/* <MUIButton
          clicked={() => props.close(true)}
          label="Continue"
          color="primary"
        />
        <MUIButton
          clicked={() => props.close(false)}
          label="Cancel"
          color="secondary"
        /> */}
        <Button
          onClick={() => props.close(true)}
          color="primary"
          variant="contained"
        >Continue
        </Button>
        <Button
          onClick={() => props.close(false)}
          color="default"
          variant="contained"
        >Cancel
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(confirmation);
