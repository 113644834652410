import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

// import satelyticsLogo from "../../assets/images/satelytics_symbol_c_25.png";
import satelyticsLogo from "../../assets/images/satelytics_otg.png";

const useStyles = makeStyles((theme) => ({
    logo: {
          padding: "0.6rem 0.5rem 0.4rem 0.0rem"
    },
    img: {
        height: "40px"
    }
  }));
  
  export default function Logo(props) {
    const classes = useStyles();

    return (
        <div className={classes.logo} >
            <a href="index.html" >
                <img src={satelyticsLogo} className={classes.img} alt="Satelytics OTG" />
            </a>
        </div>
    );
  }