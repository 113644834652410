import * as actionTypes from "./actionTypes";

export const updateAvailable = () => {
  return {
    type: actionTypes.UPDATE_AVAILABLE
  };
};

export const toggleThemeMode = (mode) => {
  return {
    type: actionTypes.APP_TOGGLE_THEME_MODE,
    darkMode: mode
  };
};

export const updateGPSSettings = (data) => {
  return {
    type: actionTypes.UPDATE_GPS_SETTINGS,
    data: data
  };
};

export const appOnline = () => {
  return {
    type: actionTypes.APP_ONLINE
  };
};

export const appOffline = () => {
  return {
    type: actionTypes.APP_OFFLINE
  };
};

export const enableDebug = () => {
  return {
    type: actionTypes.DEBUG_ON
  };
};

export const disableDebug = () => {
  return {
    type: actionTypes.DEBUG_OFF
  };
};
export const appIsLoading = (data) => {
  return {
    type: actionTypes.APP_ISLOADING,
    isLoading: data.loading
  };
};
