import React, {useRef, useState, useEffect} from 'react';
import { getThresholdSettings, setThresholdSettings } from '../../store/localStorage';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { CACHE_THRESHOLD_RANGE } from "../../constants/settings"

const useStyles = makeStyles({
    table: {
        maxWidth: 650,
    },
    input: {
        width: '100px'
    },
    centered: {
        margin: '0 auto',
    },
    formControl: {
        margin: '1 rem',
        minWidth: 150,
    },
});

// Constructs the ranges used to generate the dropdowns for the detail and overview dropdowns
const range = ({start, stop, step}) => Array.from(
    {length: (stop - start) / step + 1},
    (_, i) => start + i * step
);
const detailMenuItems = range({start: CACHE_THRESHOLD_RANGE.detail.start, stop: CACHE_THRESHOLD_RANGE.detail.stop, step: CACHE_THRESHOLD_RANGE.detail.step});
const overviewMenuItems = range({start: CACHE_THRESHOLD_RANGE.overview.start, stop: CACHE_THRESHOLD_RANGE.overview.stop, step: CACHE_THRESHOLD_RANGE.overview.step});

/**
 * Creates a table cell containing a MUI Select component
 * @param {props} 
 * @returns 
 */
const ThresholdCell = ({value, id, label, menuItems, onThresholdChangeEvent}) => {
    const classes = useStyles();
    const [threshold, setThreshold] = useState(value);
    const labelID = `threshold-${id}-label`;
    const selectID = `threshold-${id}`;

    const onChangeEvent = (event) => {
        setThreshold(event.target.value);
        onThresholdChangeEvent(event)
    }

    return (
        <TableCell align='center'>
            <FormControl className={classes.formControl}>
                <InputLabel id={labelID}>{label}</InputLabel>
                <Select
                    labelId={labelID}
                    id={selectID}
                    value={threshold}
                    onChange={onChangeEvent}
                >
                    {menuItems.map((item, id) => (
                        <MenuItem key={id} value={item}>{item}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </TableCell>
    )
};


/**
 * Creates a form for editing the users download threshold settings.
 * @returns {JSX.Element}
 */
export default function ThresholdSettingsForm({updateThresholdSettings}) {
    const classes = useStyles();
    const thresholdSettings = getThresholdSettings();
    
    /**
     * Function decorator to create state setters for the parent Settings.js UpdateThresholdSettings function
     *
     * @param {string} key - overview | detail
     * @param {string} subkey - basemap | reference | naturalColor | analysis
     * @returns {Function} - The onChange Event Handler to update the parents state.
     */
    const onThresholdChangeEvent = (key, subkey) => {
        return (event) => {
            updateThresholdSettings(key, subkey, event.target.value);
        }
    }

    return <>
       <div style={{padding: '0 1rem', maxWidth: '700px'}}>
        <h4>Caching Threshold Settings</h4>
            <p>
                When caching the imagery for offline use, these parameters control how much imagery to cache based on the distance (in meters) from each alert. Increase these values to include imagery further away from the alert. 
            </p>
            <p>
                The overview thresholds apply to the main map view which displays all of the available alerts. The detail thresholds apply to the detail map view when viewing a single alert. These thresholds control how much imagery, relative to the distance from the alert, will be available in the offline state.
            </p>
       </div>
        <TableContainer component={'div'}>
            <Table className={classes.table} aria-label="Threshold Settings Table">
                <TableHead>
                    <TableRow>
                        <TableCell align='center' >Sources</TableCell>
                        <TableCell align='center' >Detail Threshold (meters)</TableCell>
                        <TableCell align='center' >Overview Threshold (meters)</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {/* Base Map Settings */}
                    <TableRow>
                        <TableCell align='left'>
                            Base Map Layer
                        </TableCell>
                        
                        <ThresholdCell 
                            value={thresholdSettings.detail.basemap} 
                            id='detail-bm'
                            label=''
                            menuItems={detailMenuItems}
                            onThresholdChangeEvent={onThresholdChangeEvent('detail', 'basemap')}
                        />
                        
                        <ThresholdCell 
                            value={thresholdSettings.overview.basemap}
                            id='overview-bm'
                            label=''
                            menuItems={overviewMenuItems}
                            onThresholdChangeEvent={onThresholdChangeEvent('overview', 'basemap')}
                        />
                    </TableRow>

                    {/* Reference Map Settings */}
                    <TableRow>
                        <TableCell align='left'>
                            Reference Layers
                        </TableCell>

                        <ThresholdCell 
                            value={thresholdSettings.detail.reference} 
                            id='detail-ref'
                            label=''
                            menuItems={detailMenuItems} 
                            onThresholdChangeEvent={onThresholdChangeEvent('detail', 'reference')}
                        />

                        <ThresholdCell 
                            value={thresholdSettings.overview.reference}  
                            id='overview-ref'
                            label=''
                            menuItems={overviewMenuItems}
                            onThresholdChangeEvent={onThresholdChangeEvent('overview', 'reference')}
                        />

                    </TableRow>

                    {/* Natural Color Map Settings */}
                    <TableRow>
                        <TableCell align='left'>
                            Natural Color Imagery
                        </TableCell>
   
                        <ThresholdCell 
                            value={thresholdSettings.detail.naturalColor}
                            id='detail-nc'
                            label=''
                            menuItems={detailMenuItems}
                            onThresholdChangeEvent={onThresholdChangeEvent('detail', 'naturalColor')}
                        />


                        <TableCell align='center'>
                            Not Applicable
                        </TableCell>
                    </TableRow>

                    {/* Analysis Map Settings */}
                    <TableRow>
                        <TableCell align='left'>
                            Analysis Imagery
                        </TableCell>

                        <ThresholdCell 
                            value={thresholdSettings.detail.analysis} 
                            id='detail-sb'
                            label=''
                            menuItems={detailMenuItems}
                            onThresholdChangeEvent={onThresholdChangeEvent('detail', 'analysis')}
                        />
                        
                        <TableCell align='center'>
                            Not Applicable
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    </>

}