import db from "../store/db";
import VectorTile from "vector-tile/lib/vectortile";
import Pbf from "pbf";
import store from '../store/';
import { isUsingFusionAuth } from "../containers/Auth/fusion-auth-service";

const isOffline = () => !store.getState().app.online;

/**
 * Parses data from the blob that was obtained from the server.
 * @param {FileStream} blob
 * @returns new VectorTile
 */
const readPBF = async blob => {
  return new Promise( resolve => {
    const reader = new FileReader();
    reader.addEventListener('loadend', () => {
      const parsedPBF = new Pbf(reader.result);
      return resolve(new VectorTile(parsedPBF));
    });
    reader.readAsArrayBuffer(blob);

  });
}

/**
 * requestDataFromServer
 * Sends the get request to obtain the reference layer information,
 * Parses it and loads the geometry for its features.
 * @param {string} url
 * @param {Object} options - Fetch Options
 * @returns VectorTile Objects
 */
const requestDataFromServer = async (url, options) => {

  if( typeof url !== 'string' ) { return null; } // random urls were coming in as promises?
  
  if (!options.hasOwnProperty("fetchOptions")){
    const token = store.getState().auth.token;
    const authorization_prefix = isUsingFusionAuth() ? 'Bearer' : 'JWT';
    options = {...options, fetchOptions:{headers:{"Authorization":`${authorization_prefix} ${token}`}}}
  }
  
  const serverResponse = await fetch(url, options.fetchOptions); // Get data from server
  if (serverResponse.ok){
    const blob = await serverResponse.blob(); // get the blob
    const referenceData = await readPBF(blob); // parse it
  
    // Manipulate the json to load the geometry
    Object.values(referenceData.layers).map(layer => {
      layer.features = Array.from(Array(layer.length).keys()).map( i => {
        let feat = layer.feature(i)
        feat.geometry = feat.loadGeometry()
        return feat;
      });
    })
  
    return referenceData;
  } else {
    return {
      layers: []
    }
  }
  
  
}

/**
 * CacheThenNetworkStrategy
 * The default strategy. Prefers to use cached data instead of server data.
 * - Gets server data if the cache is missing.
 * - If the datababase fails it just returns the server data.
 * @param {string} url
 * @param {Object} options - Fetch Options
 * @returns Object
 */
export const cacheThenNetworkStrategy = async (url, options) => {

  // If the requests happen too fast, they pass a function instead of the value. Don't try to cache this value.
  if (typeof url === 'object' && typeof url.then === 'function') {
    return
  }

  try {
    let cachedReferenceLayers  = await db
      .table('reference_layers')
      .get(url)
      .catch(err => {
        console.log('found error with url: ', url);
        console.log(err)
        return null;
      });

    if( cachedReferenceLayers  ) {
      return cachedReferenceLayers.data
    }

    return networkThenCacheStrategy(url, options);
  }
  catch(err) {
    networkOnlyStrategy(url, options);
  }
}

/**
 * Requests reference data from the server and caches it for future use.
 * - primary used because the data was not in the DB for reference.
 * @param {string} url
 * @param {Object} options Fetch Options
 * @returns {Object} - location data received from the server.
 */
export const networkThenCacheStrategy = async (url, options) => {
  // Web Worker does not have access to Redux Store  
  // and function will return true so
  // use batch parameter to get around this
  if( isOffline() && !options.batch ) {
    console.log('Not retrieving reference data due to app setting.');
    return "";
  }

  const data = await requestDataFromServer(url, options);

  db.table('reference_layers').put({url, data})
    .catch(err => console.log('error storing Data in cache', err));

  return data;
}

/**
 * Fall back option in case the database wasn't an option.
 * @param {string} url
 * @param {Object} options Fetch Options
 * @returns {Object} - location data received from the server.
 */
export const networkOnlyStrategy = (url, options) => {
  // Web Worker does not have access to Redux Store  
  // and function will return true so
  // use batch parameter to get around this
  if( isOffline() && !options.batch ) {
    console.log('Not retrieving reference data due to app setting.');
    return "";
  }
  const data = requestDataFromServer(url, options);
  console.log('Unable to obtain access cache, pulling from server: ', data);
  return data;
}
