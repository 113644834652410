import moment from "moment";
import axios from "axios";

import db from "../db";
import store from "../index";
import axios_api from "../../api/axios-api";
import {
  removeSyncTask,
  removeTemporaryEntry
} from "./utils";

import * as requestResults from "../../constants/requestResults";
import { getBucket } from "../localStorage/index"
import SioLogger from "../../modules/Logger/logger";

const logger = SioLogger('processors/comments-media.js');

export const getCommentsFromCache = obsid => {
  return db
    .table("comments")
    .where("observation")
    .equals(obsid)
    .reverse()
    .toArray()
    .then(response => {
      return response;
    });
};

export const createComment = source => {
  return postComment(source.data ? source.data : source)
    .then(response => {
      if (source.data) {
        removeSyncTask(source);
        logger.info('Created a comment: ' + source.data.comment, source);
        removeTemporaryEntry(source);
      }
      // TODO this may be incorrect now given the change to the interceptor
      // if (!response.error) {
      db.table("comments").put(response);
      // }

      return response;
    })
    .catch(error => {
      // TODO figure out what error codes should result in still removing the synctask entry
      logger.error("Failed to create comment", error)
    });
};

const postComment = data => {
  const _obs =
    data instanceof FormData ? data.get("observation") : data.observation;

  return axios_api
    .post("/v2/observations/" + _obs + "/comments", data)
    .then(response => {
      return response.data;
    });
};

export const updateComment = source => {
  return patchComment(source.data ? source.data : source)
    .then(response => {
      if (source.data) {
        removeSyncTask(source);
        logger.info('Updated a comment: ' + source.data.comment, source)
      }
      // TODO this may be incorrect now given the change to the interceptor
      // if (!response.error) {
      db.table("comments").put(response);
      // }

      return response;
    })
    .catch(error => {
      // TODO figure out what error codes should result in still removing the synctask entry
      logger.error("Failed to update comment", error)
    });
};

const patchComment = data => {
  const _obs =
    data instanceof FormData ? data.get("observation") : data.observation;
  const _id = data instanceof FormData ? data.get("id") : data.id;
  return axios_api
    .patch("/v2/observations/" + _obs + "/comments/" + _id, data)
    .then(response => {
      return response.data;
    });
};

export const removeComment = source => {
  return deleteComment(source.data ? source.data : source)
    .then(response => {
      if (source.data) {
        removeSyncTask(source);
        logger.info('Removed a comment: ' + source.data.comment, source)
      }

      db.table("comments")
        .delete(source.data ? source.data["id"] : source["id"]);

      return response;
    })
    .catch(error => {
      // TODO figure out what error codes should result in still removing the synctask entry
      logger.error("Failed to remove comment", error)
    });
};

const deleteComment = data => {
  const _obs =
    data instanceof FormData ? data.get("observation") : data.observation;
  const _id = data instanceof FormData ? data.get("id") : data.id;
  return axios_api
    .delete("/v2/observations/" + _obs + "/comments/" + _id)
    .then(response => {
      return response.data;
    });
};

// export const setCommentForObservation = record => {
//   const stamp = new Date();
//   return {
//     id: stamp.getTime(),
//     observation: record["id"],
//     comment: record["comment"] + " (Updates:[status=" + record[
//       "status_name"] + "])"
//   };
// };

export const setCommentProperties = data => {
  const _data = {};

  Object.keys(data)
    .forEach(property => {
      let _val;
      switch (property) {
        case "id":
          _val = parseInt(data[property]);
          break;
        case "observation":
          _val = parseInt(data[property]);
          break;
        default:
          _val = data[property];
      }
      _data[property] = _val;
    });

  _data.userid = store.getState()
    .auth.userId;
  _data.username = store.getState()
    .auth.userName;

  return _data;
};


export const createMedia = source => {
  return postMedia(source.data ? source.data : source)
    .then(response => {
      if (source.data) {
        removeSyncTask(source);
        logger.info('Created photo: ' + source.data.get('file').name)
        removeTemporaryEntry(source);
      }

      // if (!response.error) {
      db.table("media").put(response);
      // }

      return response;
    })
    .catch(error => {
      // TODO figure out what error codes should result in still removing the synctask entry
      logger.error('Failed to create photo', error);
      return Promise.reject(error);
    });
};

const postMedia = data => {
  const _obs =
    data instanceof FormData ? data.get("observation") : data.observation;
  return axios_api
    .post("/v3/observations/" + _obs + "/media", data)
    .then(response => {
      return response.data;
    });
};

export const updateMedia = source => {
  return patchMedia(source.data ? source.data : source)
    .then(response => {
      if (source.data) {
        removeSyncTask(source);
        logger.info('Updated photo')
      }

      // if (!response.error) {
      db.table("media").put(response);
      // }

      return response;
    })
    .catch(error => {
      // TODO figure out what error codes should result in still removing the synctask entry
      logger.error('Failed to update photo', error);
      return Promise.reject(error);
    });
};

const patchMedia = data => {
  const _obs =
    data instanceof FormData ? data.get("observation") : data.observation;
  const _id = data instanceof FormData ? data.get("id") : data.id;
  return axios_api
    .patch("/v2/observations/" + _obs + "/media/" + _id, data)
    .then(response => {
      return response.data;
    });
};

export const removeMedia = source => {
  return deleteMedia(source.data ? source.data : source)
    .then(response => {
      if (source.data) {
        removeSyncTask(source);
        logger.warn('Removed photo: ' + source.data.get('filename'));
      }

      // if (!response.error) {
      db.table("media").delete(source.data ? source.data["id"] : source["id"]);
      // }

      return response;
    })
    .catch(error => {
      // TODO figure out what error codes should result in still removing the synctask entry
      logger.error("Failed to remove photo", error)
    });
};

const deleteMedia = data => {
  const _obs =
    data instanceof FormData ? data.get("observation") : data.observation;
  const _id = data instanceof FormData ? data.get("id") : data.id;
  return axios_api
    .delete("/v2/observations/" + _obs + "/media/" + _id)
    .then(response => {
      return response.data;
    });
};

export const setMediaProperties = data => {
  const _data = {};

  Object.keys(data)
    .forEach(property => {
      let _val;
      switch (property) {
        case "id":
          _val = parseInt(data[property]);
          break;
        case "observation":
          _val = parseInt(data[property]);
          break;
        case "comment":
          _val = parseInt(data[property]);
          break;
        default:
          _val = data[property];
      }
      _data[property] = _val;
    });

  _data.userid = store.getState()
    .auth.userId;
  _data.username = store.getState()
    .auth.userName;

  return _data;
};

export const setMediaDownloadProperties = data => {
  if (data.hasOwnProperty("file")) {
    data["filename"] = data["file"].name;
  }
  data["thumbnail"] = "tb-" + data["filename"];

  const uuid = store.getState()
    .auth.account;
  let capDate;
  try {
    capDate = moment(data["capture_date"])
      .format("YYYY-MM-DD");
  } catch (error) {
    logger.error('Invalid Date was provided for capture date parameter: ' + data['capture_date'], error);
    capDate = moment()
      .format("YYYY-MM-DD");
  }
  
  const urlBase =
    "https://" + getBucket() + ".s3.amazonaws.com/" + uuid + "/uploads/" + capDate +
    "/";
  
  data["download_url"] = urlBase + data["filename"];
  data["thumbnail_url"] = urlBase + data["thumbnail"];
  
  return data;
};

export const removeMediaCache = async (data) => {
  if (data.thumbnail_url && data.thumbnail_url.length > 0) {
    try {
      const options = {
        ignoreSearch: true
      };
      const reqThumb = new Request(data.thumbnail_url);
      const req = new Request(data.download_url);
      const cache = await caches.open("uploaded-media");
      const responseThumb = await cache.match(reqThumb, options);
      const response = await cache.match(req, options);
      await cache.delete(req, options);
      await cache.delete(reqThumb, options);
      return {
        response,
        responseThumb
      };
    } catch (error) {
      logger.error('Failed to remove photo from cache', error);
    }

  }
};

export const setMediaCache = (data, responses) => {
  const reqThumb = new Request(data.thumbnail_url);
  const req = new Request(data.download_url);
  let resp, respThumb;
  if (data.file) {
    resp = new Response(data.file, {
      type: "opaque",
      status: 200,
      headers: new Headers({
        "Accept-Ranges": "bytes",
        "Content-Type": "image/jpeg, image/png"
      })
    });
    respThumb = new Response(data.file, {
      type: "opaque",
      status: 200,
      headers: new Headers({
        "Accept-Ranges": "bytes",
        "Content-Type": "image/jpeg, image/png"
      })
    });
  } else if (responses) {
    resp = responses['response'];
    respThumb = responses['responseThumb'];
  } else {
    logger.info('Set Photo Cache: nothing to cache');
  }
  logger.info("[Offline] Store photo in cache:" + data.download_url)
  return caches
    .open("uploaded-media")
    .then(cache => {
      return Promise.all([cache.put(req, resp), cache.put(reqThumb,
        respThumb)]);
    })
    .catch(error => {
      // console.log("Error trying to add entry to the cache:", error, data);
      logger.error('Failed to add photo to cache', error);
      return Promise.resolve({});
    });
};
