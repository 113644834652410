import moment from "moment";
import * as actionTypes from "./actionTypes";
import axios_api from "../../api/axios-api";
import { getTimeWindow, validateDateRange } from "../../api/functions";
import { setDataRefreshDate } from "../localStorage/index";
import { replaceTableInDB } from "../utility";
import db from "../db";
import { processItem } from "../processors/index";
import * as modelActions from "../modelActions";
import * as modelTypes from "../modelTypes";
import { syncUpdates } from "./index";
import store from "../index";
import SioLogger from "../../modules/Logger/logger";

const logger = SioLogger('store/alerts.js');

export const updateAlertSelection = () => {
  // I believe this function is not used
  return (dispatch, getState) => {
    const indicators = getState()
      .lookups.indicators.filter(val => val.checked)
      .map(val => val.id);

    const products = getState()
      .lookups.products.filter(val => val.checked)
      .map(val => val.id);

    const status = getState()
      .lookups.status.filter(val => val.checked)
      .map(val => val.id);

    const projects = getState()
      .projects.projects.filter(val => val.checked)
      .map(val => val.id);

    const timeFilter = getState().app.timeFilter;
    const hasTimeFilter = timeFilter && timeFilter.start && timeFilter.end;

    const alerts = getState().alerts.alerts.map(alert => {
      const obs_date = moment(alert.properties.obs_date).valueOf();
      alert.checked =
        (products.length === 0 ||
          products.indexOf(alert.properties.product_id) >= 0) &&
        (status.length === 0 || status.indexOf(alert.properties.status_id) >= 0) &&
        (projects.length === 0 ||
          projects.indexOf(alert.properties.project_id) >= 0) &&
        (indicators.length === 0 ||
          indicators.indexOf(alert.properties.indicator_id) >= 0) &&
        (!hasTimeFilter ||
          (obs_date >= timeFilter.start && obs_date <= timeFilter.end));
      return alert;
    });
    dispatch(fetchAlertsSuccess(alerts));
  };
};

export const fetchAlertsSuccess = alerts => {
  return {
    type: actionTypes.FETCH_ALERTS_SUCCESS,
    alerts: alerts
  };
};

const fetchAlertsFailed = error => {
  return {
    type: actionTypes.FETCH_ALERTS_FAILED,
    error: error
  };
};

const appIsloadingStart = () => {
  return {
    type: actionTypes.APP_ISLOADING,
    isLoading: true
  };
};

const appIsloadingFinish = () => {
  return {
    type: actionTypes.APP_ISLOADING,
    isLoading: false
  };
};

const fetchAlertsStart = () => {
  return {
    type: actionTypes.FETCH_ALERTS_START
  };
};

export const saveAlertSuccess = alert => {
  return {
    type: actionTypes.SAVE_ALERT_SUCCESS,
    alert: alert
  };
};

const selectAlertSuccess = alert => {
  return {
    type: actionTypes.SELECT_ALERT_SUCCESS,
    alert: alert
  };
};

export const initializeObservations = () => {
  return dispatch => {
    dispatch(appIsloadingStart());
    dispatch(fetchAlertsStart());
    validateDateRange()
      .then(dateRange => {
        logger.info(`Fetching records for the date range: ${dateRange.startDate} to ${dateRange.endDate}`)
      })
      .then(async () => {
        return getAlertsFromServer().then(response => {
          setDataRefreshDate();
          logger.info("Received " + response.length + " alerts from server", {alertCount: response.length})
          replaceTableInDB("alerts", response);
          return response;
        });
      })
      .then(data => {
        // Don't update the store and apply any offline updates
        // before the full list is returned so that we ensure
        // the update is the most current state
        dispatch(fetchAlertsSuccess(data));
        dispatch(syncUpdates());
      })
      .catch(err => {
        // TODO Provide notice to user 
        if(err.status == 502){
          logger.error("Error getting alerts from server:" + err.message, {})  
        } else {
          logger.error("Error getting alerts from server:" + err.data.message, {error: err.data})
        }
        dispatch(fetchAlertsFailed());
      })
      .finally(() => {
        // TODO Do not let this happen if there was a date range error from the server
        // When new user first logs into app
        dispatch(appIsloadingFinish());
      });
  };
};

const getAlertsFromServer = async () => {
  const timeWindow = getTimeWindow();
  const startTimer = new Date();
  let additionalRequestCount;
  let totalCount;
  let currentCount = 0;
  let lastid;
  var obsResults = [];

  return axios_api
    .get(
      "/v3/observations?mapcount=1&startdate=" +
      timeWindow.startDate +
      "&enddate=" +
      timeWindow.endDate 
      // + "&bypass=1"
    )
    .then(async datacount => {
      totalCount = datacount.data.count;
      additionalRequestCount = Math.ceil(totalCount / 5000);
      logger.info("Total number of alerts: " + totalCount);
      logger.info("Additional request count: " + additionalRequestCount);

      return axios_api
        .get(
          "/v3/observations?mapquery=1&startdate=" +
          timeWindow.startDate +
          "&enddate=" +
          timeWindow.endDate 
          // + "&bypass=1"
        )
        .then(async function(response) {
          currentCount = currentCount + 1  
          logger.info("Fetch request " + currentCount + " completed.")

            const _observations = response.data.features;
            renderData(_observations);
            obsResults = obsResults.concat(_observations);

            if (additionalRequestCount > 1) {
              lastid = _observations[_observations.length - 1].id;
              for (var i = 2; i <= additionalRequestCount; i++) {
                await axios_api
                  .get(
                    "/v3/observations?mapquery=1&startdate=" +
                    timeWindow.startDate +
                    "&enddate=" +
                    timeWindow.endDate +
                    "&lastid=" +
                    lastid 
                    // + "&bypass=1"
                  )
                  .then(function(response) {
                    currentCount = currentCount + 1  
                    logger.info("Fetch request " + currentCount + " completed.")
                    const tempObs = response.data.features;
                    renderData(tempObs);
                    obsResults = obsResults.concat(tempObs);
                    lastid = tempObs[tempObs.length - 1].id;
                  });
              }
              logger.info("Additional Requests have finished");
            } else {
              logger.info("Additional requests not required.");
            }
            const stopTimer = new Date();
            logger.info("Fetching " + obsResults.length + " records took " + (stopTimer.getTime() - startTimer.getTime()) / 1000 + " seconds.");
            return obsResults;
          }
        );
        
    });
};

export const renderData = (observations) => {
  const lookups = store.getState().lookups;
  const project_lookups = store.getState().projects.projects;
  const indicator_lookups = lookups.indicators;
  const status_lookups = lookups.status;
  const product_lookups = lookups.products;
  const package_lookups = lookups.packages;
  const classification_lookups = lookups.classifications;
  const source_lookups = lookups.sources;
  const assets_lookups = lookups.assets;

  if (observations instanceof Array){
    observations.map(obs => {
      obs.show_on_map = true;
  
      hydrateByProject(project_lookups, obs);
      hydrateByProduct(product_lookups, obs);
      hydrateByIndicator(indicator_lookups, obs);
      hydrateByStatus(status_lookups, obs);
      hydrateByPackage(package_lookups, obs);
      hydrateByClassification(classification_lookups, obs);
      hydrateBySource(source_lookups, obs);
      hydrateByAsset(assets_lookups, obs);
    });
  } else if (observations instanceof Object) {
    
    observations.show_on_map = true;
  
    hydrateByProject(project_lookups, observations);
    hydrateByProduct(product_lookups, observations);
    hydrateByIndicator(indicator_lookups, observations);
    hydrateByStatus(status_lookups, observations);
    hydrateByPackage(package_lookups, observations);
    hydrateByClassification(classification_lookups, observations);
    hydrateBySource(source_lookups, observations);
    hydrateByAsset(assets_lookups, observations);
  }
  
}

const hydrateByClassification = (lookup, observation) => {
  if (Number.isInteger(observation.properties.classification_id)){
    const classification = lookup.find(item => {
      return item.id === observation.properties.classification_id;
    });

    if (classification){
      observation.properties.classification_name = classification.name;
    }
  }
}

const hydrateByPackage = (lookup, observation) => {
  if (Number.isInteger(observation.properties.package_id)){
    const _package = lookup.find(item => {
      return item.id === observation.properties.package_id;
    });
  
    if (_package){
      observation.properties.package_name = _package.name;
    }
  }
}

const hydrateByProject = (lookup, observation) => {
  if (Number.isInteger(observation.properties.project_id)){
    const project = lookup.find(item => {
      return item.id === observation.properties.project_id;
    });
  
    if (project){
      observation.properties.project_name = project.project_name;
    }
  }
}

const hydrateBySource = (lookup, observation) => {
  if (Number.isInteger(observation.properties.source_id)){
    const source = lookup.find(item => {
      return item.id === observation.properties.source_id;
    });
  
    if (source){
      observation.properties.source_image = source.properties.filename;
    }
  }

  if (Number.isInteger(observation.properties.prv_source_id)){
    const source = lookup.find(item => {
      return item.id === observation.properties.prv_source_id;
    });
  
    if (source){
      observation.properties.prv_source_date = source.properties.capture_date;
      observation.properties.prv_source_image = source.properties.filename;
    }
  }

  if (Number.isInteger(observation.properties.obs_result_id)){
    const source = lookup.find(item => {
      return item.id === observation.properties.obs_result_id;
    });
  
    if (source){
      observation.properties.obs_result_image = source.properties.filename;
      observation.properties.obs_result_renderer = source.properties.renderconfig;
    }
  }

  if (Number.isInteger(observation.properties.prv_result_id)){
    const source = lookup.find(item => {
      return item.id === observation.properties.prv_result_id;
    });
  
    if (source){
      observation.properties.prv_result_image = source.properties.filename;
      observation.properties.prv_result_renderer = source.properties.renderconfig;
    }
  }

  if (Number.isInteger(observation.properties.diff_result_id)){
    const source = lookup.find(item => {
      return item.id === observation.properties.diff_result_id;
    });
  
    if (source){
      observation.properties.diff_result_image = source.properties.filename;
      observation.properties.diff_result_renderer = source.properties.renderconfig;
    }
  }
}

const hydrateByAsset = (lookup, observation) => {
  if (Number.isInteger(observation.properties.asset_id)){
    const asset = lookup.find(item => {
      return item.id === observation.properties.asset_id;
    });
  
    if (asset){
      observation.properties.asset_name = asset.properties.name;
      observation.properties.asset_key = asset.properties.asset_key;
    }
  }
}

const hydrateByStatus = (lookup, observation) => {
  // if (Number.isInteger(observation.properties.status_id)){
  if (observation.properties.status_code_id) {
    const status = lookup.find(item => {
      return item.code === observation.properties.status_code_id;
    });
  
    if (status) {
      // For whatever reason, the API is returning status_code_id instead of status_code (how the viewer does it)
      // Hydrating it here so that we can have a similar data structure.
      observation.properties.status_code = status.code; 

      // Hydrating the status name on the observation
      observation.properties.status_name = status.name;
    }
  }
}

const hydrateByIndicator = (lookup, observation) => {
  if (Number.isInteger(observation.properties.indicator_id)){
    const _radius = 10;
    const _color = "#000000";
    const _style = {color:_color, radius:_radius};
    
    const indicator = lookup.find(item => {
      return item.id === observation.properties.indicator_id;
    });
    
    if (indicator){
      const _renderClass = JSON.parse(indicator.render_class)
      if (_renderClass.hasOwnProperty("muiColor")){
        _style.color = _renderClass.muiColor;
      }  
      observation.properties.indicator_name = indicator.name; 
      observation.show_on_map = (indicator.hasOwnProperty("show_on_map") ? indicator.show_on_map : true);
    } 

    observation.marker_style = _style
  }
}

const hydrateByProduct = (lookup, observation) => {
  if (Number.isInteger(observation.properties.product_id)){
    const product = lookup.find(item => {
      return item.id === observation.properties.product_id;
    });
    
    if (product) {
      observation.properties.product_name = product.name;
      observation.show_on_map = (product.hasOwnProperty("scene_based") ? !product.scene_based : true);
    }
  }
}


export const fetchAlertsFromCache = () => {
  const timerStart = new Date();

  return dispatch => {

    const alerts = [];

    db.alerts.each(alert => {
      alerts.push(alert);

    }).catch(err => {
      console.warn('Error Fetching alerts from indexeddb', err);
      alerts.length = 0;

    }).finally(() => {
      if (alerts.length === 0) {
        logger.info('No Alerts retrieved from cache');
        return;
      };

      const timeTaken = (new Date().getTime() - timerStart.getTime()) / 1000;
      logger.info(`Retrieving ${alerts.length} alerts from cache took ${timeTaken} seconds.`);
      dispatch(fetchAlertsSuccess(alerts));

    });
  };
};

export const saveAlert = item => {
  return dispatch => {
    return processItem(modelActions.UPDATE, modelTypes.OBSERVATION, item)
      .then(response => {
        dispatch(saveAlertSuccess(response));
        return response;
      })
      .catch(error => {
        logger.error("Error saving alert: " + error.message, {error, item});
        return { error: error.message };
      });
  };
};

export const selectAlert = item => {
  return dispatch => {
    dispatch(selectAlertSuccess(item));
  };
};

