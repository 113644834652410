import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CloudOffIcon from '@material-ui/icons/CloudOff';

const useStyles = makeStyles((theme) => ({
    notificationicon: {
        display: 'flex',
    },
    offline: {
        color: theme.palette.secondary.main
    }, 
  }));
  
  export default function OfflineIndicator(props) {
    const classes = useStyles();

    return (
        <div className={classes.notificationicon}>
            <Tooltip title="Offline">
                <CloudOffIcon className={classes.offline}/>
            </Tooltip>
        </div>
    );
  }