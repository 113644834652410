import React, { Component } from "react";

// import classes from "./Modal.css";
import { withStyles } from "@material-ui/core/styles";
import Backdrop from "../Backdrop/Backdrop";
import Paper from "@material-ui/core/Paper";
import useMediaQuery from "@material-ui/core/useMediaQuery"

const styles = theme => ({
  // paper: {
  //   padding: "10px",
  //   width: "100%",
  // },
  modal: {
    position: "fixed",
    zIndex: "5000",
    backgroundColor: "inherit",
    width: "70%",
    border: "1px solid #ccc",
    boxShadow: "1px 1px 1px black",
    left: "15%",
    top: "3.5rem",
    boxSizing: "border-box",
    transition: "all 0.3s ease-out",
    maxHeight: "calc(100% - 6rem)",
    overflowY: "scroll",
    ['@media (min-width:37.5rem)']: { // eslint-disable-line no-useless-computed-key
      width: '500px',
      left: 'calc(50% - 15.625rem)'
    }
  }
});

class Modal extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.show !== this.props.show ||
      nextProps.children !== this.props.children
    );
  }

  render() {
    return (
      <React.Fragment>
        <Backdrop show={this.props.show} clicked={this.props.modalClosed} />
        <Paper>
        <div
          className={this.props.classes.modal}
          style={{
            transform: this.props.show ? "translateY(0)" : "translateY(-100vh)",
            opacity: this.props.show ? "1" : "0"
          }}
        >
          {this.props.children}
        </div>
        </Paper>
        
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Modal);
