import store from "../../store/index";
import moment from "moment";

/**
 * Takes an array from the store and filters out the ids that are checked.
 * @param {array} stateObject 
 * @returns [store ids]
 */
const getIdsFromState = stateObject => {
    return stateObject.filter( val => val.checked)
        .map(val => val.id);
}

const getCodesFromState = (state_object) => {
    return state_object
    .filter(val => val.checked)
    .map(val => val.code)
}

/**
 * Takes the alerts loaded from redux and filters them based on user settings.
 * @returns [filtered alerts]
 */
const getFilteredAlerts = () => {
    const state = store.getState();
    const indicatorIDList = getIdsFromState(state.lookups.indicators);
    const productIDList = getIdsFromState(state.lookups.products);
    const statusIDList = getCodesFromState(state.lookups.status);
    const projectIDList = getIdsFromState(state.projects.projects);
    const hasTimeFilter = state.map.timeFilter && state.map.timeFilter.start && state.map.timeFilter.end;

    return state.alerts.alerts.filter(record => {
        const {
            properties
        } = record;

        const obs_date = moment(properties.obs_date)
            .valueOf();
        return (
            (productIDList.length === 0 ||
            productIDList.indexOf(properties.product_id) >= 0) &&
            (statusIDList.length === 0 || statusIDList.indexOf(properties.status_code) >=
            0) &&
            (projectIDList.length === 0 || projectIDList.indexOf(properties.project_id) >=
            0) &&
            (indicatorIDList.length === 0 ||
            indicatorIDList.indexOf(properties.indicator_id) >= 0) &&
            (!hasTimeFilter ||
            (obs_date >= state.map.timeFilter.start && obs_date <= state.map.timeFilter.end))
        );
    });
    
}

export default getFilteredAlerts;