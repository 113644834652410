import L from "leaflet";

import { getSettings } from "../../store/localStorage/index";
import * as settings from "../../constants/settings";
import * as actions from "../../store/actions/index";
import store from "../../store/index";
import tileLoaderWorker from "../../api/tileloader.worker.js";
import * as workerActionTypes from "../../constants/workerActionTypes";
import getFilteredAlerts from "../../containers/alertContainer/getFilteredAlerts";
import SioLogger from "../Logger/logger";
import { getBoundsOfDistance } from "geolib";
import { getThresholdSettings } from "../../store/localStorage/index";
import { CACHING_ZOOM_LEVELS } from "../../constants/settings"
import { downloadPmTileFile } from "../../modules/Map/Pmtiles";
import { isUsingFusionAuth } from "../../containers/Auth/fusion-auth-service.js";

const logger = SioLogger('modules/BatchDownload.js')
const tlWorker = new tileLoaderWorker();


/**
 * Strategy for Tile Caching
 * Threshold units are in meters
 */
const OVERVIEW_ZOOM_LEVELS = CACHING_ZOOM_LEVELS.overview;
const DETAIL_ZOOM_LEVELS = CACHING_ZOOM_LEVELS.detail;

export const generateTiles = map => {
  logger.info("Starting Tile Generation Process...");

  const start = Date.now();
  const offlineSettings = getSettings();
  const bmLayers = []; // These layer arrays are filled if the settings are enabled.
  const ncLayers = [];
  const sbLayers = [];
  const observationCoordinates = [];
  let refLayers = [];
  const workPackage = { // The package given to the service worker to start the caching process.
    observations: [],
    addlDataTypes: [],
    bm: [],
    bmCount: 0,
    bmSizeEstimate: 0,
    nc: [],
    ncCount: 0,
    ncSizeEstimate: 0,
    sb: [],
    sbCount: 0,
    sbSizeEstimate: 0,
    ref: [],
    refCount: [],
    refSizeEstimate: 0,
    totalCount: 0,
    totalSizeEstimate: 0,
    concurrentRequests: 0
  };

  // Use setTimeout to give React a chance to update
  // the UI before performing resource intensive tasks
  setTimeout(() => {
    const workEntries = setWorkBoundaryParameters(offlineSettings, map);
    const userPreferences = getThresholdSettings();

    if (workEntries.length === 0) { return; }

    workEntries.forEach(workEntry => {
      const ncArray = [];
      const sbArray = [];

      if ( offlineSettings.imageryTypes.includes(settings.IMAGERY_TYPE_BASEMAP) ) {
        bmLayers.push(getBasemapLayers(workEntry.bounds));
      }

      if( offlineSettings.imageryTypes.includes(settings.IMAGERY_TYPE_REFERENCE) ) {
        refLayers = refLayers.concat(getReferenceLayers(workEntry));
      }

      workEntry.observations.forEach(obs => {
        workPackage.observations.push(obs.id);
        let coordinate = new L.geoJSON(obs.geometry).getBounds().getCenter()
        observationCoordinates.push(coordinate);
        // const [southWest, northEast] = createBoundsFromCentroid(centroid, threshold);

        if ( offlineSettings.imageryTypes.includes(settings.IMAGERY_TYPE_NATURAL_COLOR) ) {
          if (obs.properties.source_image) {
            if (ncArray.indexOf(obs.properties.source_image) === -1)
              ncArray.push(obs.properties.source_image);
          }
          if (obs.properties.prv_source_image) {
            if (ncArray.indexOf(obs.properties.prv_source_image) === -1)
              ncArray.push(obs.properties.prv_source_image);
          }
        }

        if ( offlineSettings.imageryTypes.includes(settings.IMAGERY_TYPE_SINGLE_BAND) ) {
          if (obs.properties.obs_result_image) {
            if (sbArray.indexOf(obs.properties.obs_result_image) === -1)
              sbArray.push(obs.properties.obs_result_image);
          }
          if (obs.properties.prv_result_image) {
            if (sbArray.indexOf(obs.properties.prv_result_image) === -1)
              sbArray.push(obs.properties.prv_result_image);
          }
          if (obs.properties.diff_result_image) {
            if (sbArray.indexOf(obs.properties.diff_result_image) === -1)
              sbArray.push(obs.properties.diff_result_image);
          }
        }
      });

      // Generate Layer Definition for Natural Color Sources
      ncArray.forEach(item => {
        // Need to send account
        ncLayers.push(
          getNCLayer(
            item,
            workEntry.bounds,
            DETAIL_ZOOM_LEVELS[0],
            DETAIL_ZOOM_LEVELS[DETAIL_ZOOM_LEVELS.length - 1]
          )
        );
      });
      // Generate Layer Definition for Single Band Sources
      sbArray.forEach(item => {
        // Need to send account and color map
        // Need to not include /sb
        sbLayers.push(
          getSBLayer(
            "/sb" + item,
            workEntry.bounds,
            workEntry.minZoom,
            workEntry.maxZoom
          )
        );
      });
    });

    // Initialize the counters to 0;
    let bmCount, ncCount, sbCount, refCount;
    bmCount = ncCount = sbCount = refCount = 0;

    // Prepare Work Package for Web Worker
    bmLayers.forEach(layer => {      
      const layerDef = createLayerDefinition(layer, [
        ...generateLayerTiles(map, layer, observationCoordinates, userPreferences.overview.basemap, OVERVIEW_ZOOM_LEVELS),
        ...generateLayerTiles(map, layer, observationCoordinates, userPreferences.detail.basemap, DETAIL_ZOOM_LEVELS)
      ])
      bmCount = bmCount + layerDef.tiles.length;
      workPackage.bm.push(layerDef);
    });
    ncLayers.forEach(layer => {
      const layerDef = createLayerDefinition(layer, 
        generateLayerTiles(map, layer, observationCoordinates, userPreferences.detail.naturalColor, DETAIL_ZOOM_LEVELS)
      )
      ncCount = ncCount + layerDef.tiles.length;
      workPackage.nc.push(layerDef);
    });
    sbLayers.forEach(layer => {
      const layerDef = createLayerDefinition(layer, 
        generateLayerTiles(map, layer, observationCoordinates, userPreferences.detail.analysis, DETAIL_ZOOM_LEVELS)
      )
      sbCount = sbCount + layerDef.tiles.length;
      workPackage.sb.push(layerDef);
    });
    refLayers.forEach( layer => {
      // #############################################################
      // TODO Use pmtile file format - waiting for rendering updates
      // const layer_is_in_pmtile_format = layer.options.format && layer.options.format === 'pmtile';
      // const tiles = (layer_is_in_pmtile_format) 
      //   ? [layer.options.download_url] 
      //   : [
      //     ...generateLayerTiles(map, layer, observationCoordinates, userPreferences.overview.reference, OVERVIEW_ZOOM_LEVELS),
      //     ...generateLayerTiles(map, layer, observationCoordinates, userPreferences.detail.reference, DETAIL_ZOOM_LEVELS)
      //   ]
      const tiles =  [
          ...generateLayerTiles(map, layer, observationCoordinates, userPreferences.overview.reference, OVERVIEW_ZOOM_LEVELS),
          ...generateLayerTiles(map, layer, observationCoordinates, userPreferences.detail.reference, DETAIL_ZOOM_LEVELS)
        ]
      // #############################################################
      
      if (tiles.length && tiles.length > 0 ) {
        const layerDef = createLayerDefinition(layer, tiles);
        refCount = refCount + layerDef.tiles.length;
        workPackage.ref.push(layerDef);
      }
    });

    // store.dispatch(actions.finishTileGeneration());
    logger.info("Completed Tile Generation Process in " + (Date.now() - start) / 1000 + " seconds.");

    workPackage.addlDataTypes = [...offlineSettings.addlDataTypes];
    workPackage.bmCount = bmCount;
    workPackage.bmSizeEstimate = bmCount * 2; // 2KB/tile
    workPackage.ncCount = ncCount;
    workPackage.ncSizeEstimate = ncCount * 5; // 5 KB/tile
    workPackage.sbCount = sbCount;
    workPackage.sbSizeEstimate = sbCount * 1.2; // 1.2 KB/tile
    workPackage.refCount = refCount;
    workPackage.refSizeEstimate = refCount * 1.2; // 1.2 KB/tile
    workPackage.totalCount = bmCount + ncCount + sbCount + refCount;
    workPackage.totalSizeEstimate =
      workPackage.bmSizeEstimate +
      workPackage.ncSizeEstimate +
      workPackage.sbSizeEstimate +
      workPackage.refSizeEstimate;
    workPackage.concurrentRequests = offlineSettings.concurrentRequests;

    store.dispatch(actions.readyTileDownload(workPackage));

  }, 500);
};

const setWorkBoundaryParameters = (offlineSettings, map) => {
  let workEntries = [];
  // if (offlineSettings.selectedTDB === settings.TILE_DOWNLOAD_BOUNDARY_PROJECT) {
  //   const projects = store.getState().projects.projects;
  //   offlineSettings.selectedProjects.forEach(projectId => {
  //     const _p = projects.find(proj => {
  //       return proj.id === projectId;
  //     });
  //     if (_p) {
  //       const feature = L.geoJSON(_p.project_boundary);
  //       // Get the zoom level that will fit the project extent
  //       const minZoom = map.getBoundsZoom(feature.getBounds()) - 1;
  //       workEntries.push({
  //         id: _p.id,
  //         bounds: feature.getBounds(),
  //         observations: filterByProject(_p.id),
  //         minZoom: minZoom,
  //         maxZoom: offlineSettings.maxZoomLevel
  //       });
  //     }
  //   });
  // } else if (
  //   offlineSettings.selectedTDB === settings.TILE_DOWNLOAD_BOUNDARY_MAP
  // ) {
  // const minZoom = map.getZoom() - 1;
  workEntries.push({
    id: 1,
    bounds: map.getBounds(),
    observations: filterByExtent(map.getBounds()),
    // minZoom: minZoom,
    // maxZoom: 20 //offlineSettings.maxZoomLevel
  });
  // }

  return workEntries;
};

// const filterByProject = projectId => {
//   return getFilteredAlerts().filter(obs => {
//     return obs.properties.project === projectId;
//   });
// };

const filterByExtent = mapBounds => {
  return getFilteredAlerts().filter(obs => {
    if (obs.geometry) {
      const feature = L.geoJSON(obs);
      // Only include those records that are within the current map extent
      return mapBounds.contains(feature.getBounds());
    } else {
      return false;
    }
  });
};

const getBasemapLayers = bounds => {
  return L.satelyticsLayer(
    "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    {
      minZoom: 5,
      maxZoom: 20,
      layername: "OSM",
      subdomains: "abc",
      bounds: bounds,
      localDownload: true,
      silent: true
    }
  );
};


const getNCLayer = (source, bounds, minZoom, maxZoom) => {
  const state = store.getState();
  const account = state.auth.account;

  // The tiling server isn't expecting a leading /0/
  if (source.startsWith('/0/')) {
    source = source.replace('/0/', '/')
  }
  const prefix = (/\.json$/.test(source) ? "mosaic" : "single")
  const suffix = (/\.json$/.test(source) ? "" : ".tif")
  return L.satelyticsLayer(
    `/${prefix}/tiles/{z}/{x}/{y}?url=` + 
    `${account}` + 
    `${source}` +
    `${suffix}`, {
    tms: false,
    minZoom: minZoom || 5,
    maxZoom: maxZoom || 20,
    layername: source,
    subdomains: [],
    zIndex: 10,
    bounds: bounds
  });
}

const getSBLayer = (source, bounds, minZoom, maxZoom) => {
  return L.satelyticsLayer(source + "/{z}/{x}/{y}.png", {
    layername: source,
    tms: false,
    silent: true,
    bounds: bounds,
    minZoom: minZoom,
    maxZoom: maxZoom,
    subdomains: []
  });
}

// Deprecating so we can separate the logic into getNCLayer and getSBLayer
// const getLayer = (source, bounds, minZoom, maxZoom) => {
//   // #################################################
//   // NC Format
//   // #################################################
//   // source_name = source_name.replace('/0/', '/')
//   // url = 
//   //     `${AppConfig.TilerUrl_v2}` + 
//   //     `/single/tiles/{z}/{x}/{y}?url=` + 
//   //     `${vm.account}` + 
//   //     `${source_name}` +
//   //     `.tif`

//   // #################################################
//   // SB Format
//   // #################################################
//   // const stops = Object.values(source.properties.renderconfig.color_map).sort((a, b) => a.stop - b.stop).map(item => item.stop);
//   // const colors = Object.values(source.properties.renderconfig.color_map).sort((a, b) => a.stop - b.stop).map(item => item.color);
  
//   // stops.unshift(0)
//   // const colormap = []
//   // for (let i = 0; i < colors.length; i++) {
//   //   if (colors[i] == "transparent")
//   //     var color_hex = "#00000000"
//   //   else if (colors[i].toString().indexOf('rgba') != -1)
//   //     var color_hex = Utils.rgbaToHex(colors[i])
//   //   else
//   //     var color_hex = Utils.rgbToHex(colors[i])
//   //   colormap.push([[stops[i], stops[i + 1]], color_hex])
//   // }
  
//   // source_name = source_name.replace('/0/', '/')
//   // url = 
//   //     `${AppConfig.TilerUrl_v2}` + 
//   //     `/single/tiles/{z}/{x}/{y}?url=` + 
//   //     `${vm.account}` + 
//   //     `${source_name}` +
//   //     `.tif` +
//   //     `&colormap=${encodeURIComponent(
//   //       JSON.stringify(colormap)
//   //     )}`
// };

const getReferenceLayers = (workEntry) => {
  const {minZoom, maxZoom, bounds} = workEntry

  const state = store.getState();
  const token = state.auth.token;
  const refLayers = state.map.refLayers;
  const projects = state.projects.projects.filter(project => {
    return project.checked
  });
  let layerArray = [];

  // Pulling in the reflayer data to start a layer defintion
  // : data is deep copied to prevent state changes to the zoom levels and boundaries.
  projects.map( project => {
    const refMaps = refLayers.filter( layer => project.reference_layers.includes(layer.id));
    refMaps.map(item => {
      let layerDef;
      try {
        if(item.hasOwnProperty(('data'))) {
          layerDef = JSON.parse(JSON.stringify(item.data)); // Parse Stringify combo prevents the state change
          
        }
        else {
          layerDef = getRootNode(JSON.parse('{' + item.code + '}')); // didn't feel the need to deep copy this.
        }
      }
      catch( err ) {
        logger.warn('Error parsing reference layer config', item);
      }

      if( layerDef ) {
        layerDef.layerOptions.name = layerDef.name;
        layerDef.layerOptions.minZoom = minZoom;
        layerDef.layerOptions.maxZoom = maxZoom;
        layerDef.layerOptions.bounds = bounds;
        layerDef.layerOptions.format = layerDef.format;
        layerDef.layerOptions.download_url = layerDef.download_url;

        const layer = L.vectorGrid.protobuf(layerDef.url, layerDef.layerOptions);
        
        layerArray.push(layer);

       
      }
    });
  })
  
  return layerArray;
}


/**
 * Creates two points
 * @param {point} centroid the original point or center of a polygon
 * @param {int} threshold distance in meters to expand the centroid
 * @returns [coord, coord]
 */
const createBoundsFromCentroid = (centroid, threshold) => {
  
  let [southWest, northEast] = getBoundsOfDistance(
    {latitude: centroid.lat, longitude: centroid.lng},
    threshold
  );

  // Rewriting the lats and longs to leaflets shorthand.
  southWest.lat = southWest.latitude;
  southWest.lng = southWest.longitude;
  northEast.lat = northEast.latitude;
  northEast.lng = northEast.longitude;

  return [southWest, northEast];
}

/**
 * Creates the boundary for a given zoom level
 */
const createBoundsAtZoom = (southWest, northEast, zoomLevel, map) => (
  L.bounds(
    map.project(southWest, zoomLevel),
    map.project(northEast, zoomLevel)
  )
);

const generateLayerTiles = (map, layer, centroids, threshold, zoomLevels) => {
  let tiles = new Set();
  let invalidCentroidCount = 0;

  centroids.forEach(centroid => {
    const [southWest, northEast] = createBoundsFromCentroid(centroid, threshold);

    for (const zoomLevel of zoomLevels) {
      let boundsAtZoom = {};
      let possibleTiles = [];
      try {
        boundsAtZoom = createBoundsAtZoom(southWest, northEast, zoomLevel, map);
        possibleTiles = layer.getTileUrls(boundsAtZoom, zoomLevel,);
        possibleTiles.forEach((tile) => tiles.add(tile));
      } catch (err){
        console.warn('Invalid Centroid: ', {centroid, boundsAtZoom, possibleTiles, southWest, northEast, threshold});
        console.log(err);
        invalidCentroidCount += 1;
      }
      
    }
  });

  if (invalidCentroidCount > 0) {
    logger.warn(`Layer: ${layer._layername} had ${invalidCentroidCount} invalid alerts`);
  }

  return [...tiles];
}

const createLayerDefinition = (layer, tiles) => {
  const {localDownload = false, subdomains=[], format } = layer.options;

  return {
    type: "batch",
    tiles: tiles,
    layername: layer._layername,
    subdomains: subdomains,
    localDownload: localDownload,
    format: format
  };
}

export const downloadTiles = () => {
  logger.info('Batch process module is starting the download.')

  const autorization_prefix = isUsingFusionAuth() ? 'Bearer' : 'JWT';
  const state = store.getState();
  const token = state.auth.token;
  const account = state.auth.account;
  const authorization = `${autorization_prefix} ${token}`;
  let host = window.location.hostname;
  // Localhost testing
  // host = "otg.dev.satelytics.io";
  
  tlWorker.postMessage({
    action: workerActionTypes.START_PROCESSING,
    account: account,
    token: token,
    host: host,
    authorization,
    data: store.getState().map.tilesDownloadPackage
  });

  tlWorker.onmessage = event => {
    if (event.data.action === workerActionTypes.PROCESSING_COMPLETE) {
      logger.info('Batch Download has finished.')
      store.dispatch(actions.finishTileDownload());
    }
    else if (event.data.action === workerActionTypes.MODULE_COMPLETED) {
      logger.info('Batch Download for ' + event.data.entity + ' has finished in ' + event.data.duration + '.')
    }
    else {
      logger.info('Batch download has received data.');
    }
  };
};

export const stopTileDownload = () => {
  logger.warn('Batch Download, cancel has been issued.')
  tlWorker.postMessage({
    action: workerActionTypes.CANCEL_PROCESSING
  });
};
