import axios from "axios";

const instance = axios.create({
  baseURL: "",
  rootURL: "",
  headers: {}
});

// instance.interceptors.request.use(
//   request => {
//     console.log("Instance inteceptor", request);
//     // Update headers
//     return request;
//   },
//   error => {
//     // Pass on in case local component needs to
//     // do anything special
//     console.log("Request interceptor", error);
//     return Promise.reject(error);
//   }
// );

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 413: error.response.message = "Requested file is too large."
        break;
        
        case 502: error.response.message = "Timeout issue on the server."
        break;
  
        default: error.response.message = error.response.statusText
      }
    
      return Promise.reject(error.response);

    } else if (error.request) {
      
      return Promise.reject(error);

    } else {
      
      return Promise.reject(error);
      
    }
  }
);

export default instance;
