import LoggerWorker from '../../api/logger.worker.js';
import * as workerActionTypes from "../../constants/workerActionTypes";



const logWorker = new LoggerWorker()
console.info('Creating the logging web worker');

const writeLog = (log) => {
    try {
        logWorker.postMessage({
            action: workerActionTypes.STORE_LOG,
            log
        })
    } catch (err) {
        console.error("Error sending log message to Logger worker.", JSON.stringify(log), err)
    }
    
}

export default writeLog;