import React, {
  useState
} from "react";
import moment from "moment";
import classes from "./comment.css";
import {
  withStyles
} from "@material-ui/core/styles";
import {
  PhotoCamera,
  RemoveRedEye,
  Attachment,
  Comment,
  Create,
  Delete
} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Notification from '../../../UI/Notification/Notification';
const styles = theme => ({
  root: {
    padding: 0
  }
});

const comment = (props) => {
  const {
    comment,
    onDelete,
    onAddMedia,
    media,
    onUpdateComment,
    onReloadCommentsMedia
  } = props;
  const [pictureVisible, setPictureVisible] = useState(false);
  const [SnackBarVisible, setSnackBarVisible] = useState(false);
  const [comment_text, setComment] = useState(comment.comment);
  const oldComment = comment.comment;
  //<PhotoCamera onClick={() => {if (media) onDeleteMedia(media);}} color='error'/> :
  //{comment.has_media ? <RemoveRedEye fontSize="small"  onClick={()=>setPictureVisible(!pictureVisible)}/> : null}
  const editComment = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const comment_text = event.target.value;
    setComment(comment_text);
  };

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      saveCommet();
    }
  };

  const saveCommet = () => {
    //this is not true clone. todo
    //    const payload = JSON.parse(JSON.stringify(comment));
    const payload = comment;
    payload.comment_text = comment.comment;
    payload.comment = comment_text;
    let message = '';
    if (comment_text.length === 0) {
      message = 'Comment cannot be empty';
      setComment(oldComment);
      onReloadCommentsMedia(message);
      return;
    } else if (oldComment === comment_text) return;

    message = 'comment successfully updated';
    onUpdateComment(payload)
      .then(data => {
        onReloadCommentsMedia(message);
      });
  };

  const colorCamera = comment.has_media ?
        <IconButton onClick={()=>setPictureVisible(!pictureVisible)}>
    <RemoveRedEye fontSize="small" /> </IconButton>:
        <IconButton onClick={()=>onAddMedia(comment)}><PhotoCamera fontSize="small"/></IconButton>;
  const picture = media && pictureVisible ?
    <img src={media.download_url} className={classes.picture}/> : null;
  return (

    <div className={classes.Comment}>
      <div>
      {colorCamera}
      </div>
      <div className={classes.Main}>
      <input type='text' value={comment_text}
    onChange={editComment} onKeyPress={onKeyPress} onBlur={saveCommet}
    style={{display:"table-cell", width:"100%"}} />
        <div className={classes.subHeader}>
          <div>
            {moment(comment.created).format("lll")}{" "}
            {comment.username}{" "}
          </div>
          <div>
            {/* <Create fontSize="small" /> {"    "} */}
            <IconButton
              className={classes.root}
              color="inherit"
              onClick={() => onDelete(comment)}
            >
              <Delete fontSize="small" />
            </IconButton>
      </div>
        </div>
      {picture}
    </div>
      </div>
  );
};

export default withStyles(styles)(comment);
