
import React from "react";
import L from "leaflet";
import { MarkerClusterGroup } from "leaflet.markercluster";
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import moment from "moment";
import "./satelyticsLayer";
import {getMapZoomCenter} from "../../store/localStorage/index";
import * as contentviews from "../../constants/contentViews"
import SioLogger from "../Logger/logger";
import "leaflet.heat";
import { getOfflineMode } from "../../store/localStorage/index";
import { createOfflineLayer} from "./Pmtiles";
import store from "../../store/index";

const logger = SioLogger('src/modules/Map/map.js');

export const initializeMap = (mapId, initialZoomCenter) => {

  const _lastZoomCenter = getMapZoomCenter()
  let _zoom;
  if (initialZoomCenter) {
    _zoom = (initialZoomCenter.zoom > 0 ? initialZoomCenter.zoom :
      _lastZoomCenter.zoom)
  }
  const map = L.map(mapId ? mapId : "map", {
    center: initialZoomCenter ? initialZoomCenter.center : _lastZoomCenter
      .center,
    zoom: _zoom,
    maxZoom: 20
  });

  // Set up Reference Layer Pane
  // Situated between the tile pane and the overlay pane (Observations)
  map.createPane("referencePane")
    .style.zIndex = 350;

  // Set up map events
  map.on("locationfound", onLocationFound);
  map.on("locationerror", onLocationError);
  map.on("zoomend", () => {
    // Use this if you need to handle some internal functionality
    logger.info("Zoomed to Level:" + map.getZoom());
  });
  map.on("moveend", () => {
    // Use this if you need to handle some internal functionality
    //console.log("Map Center:", map.getCenter());
  });

  return map;
};

const onLocationFound = e => {
  //logger.info("Location GPS updated", e)
  logger.info("Location GPS updated.");
  // var radius = e.accuracy / 2;
  // L.circle(e.latlng, radius).addTo(e.target);
  let map = e.target;
  map.eachLayer(layer => {
    if (layer.options.hasOwnProperty('marker_name') && layer.options
      .marker_name === 'locateme') {
      map.removeLayer(layer);
    }
  });
  L.marker(e.latlng, {
      marker_name: 'locateme'
    })
    .addTo(e.target);
};

const onLocationError = e => {
  //logger.error("Location error:" + e.message, e);
  logger.error("Location error:" + e.message);
};

export const initializeHeatmapLayer = (data) => {
  const heatMap = L.heatLayer([],
    {
      radius: 20,
      blur: 15,
      max: 1,
    });

  for (var i = 0; i < data.length; i++) {
    if (data[i].hasOwnProperty("show_on_map") && data[i].show_on_map){
      var feat = L.geoJson(data[i], {});
      if (feat){
        var latlng = feat.getBounds().getCenter();
        heatMap.addLatLng([latlng.lat, latlng.lng, 1]);
      }
    }
  }
  
  return heatMap;
}

export const initializeObservationLayer = (map) => {

  const markerCG = new MarkerClusterGroup({
    chunkLoading: true,
    showCoverageOnHover: false,
    disableClusteringAtZoom: 17
  });

  map.addLayer(markerCG);

  return markerCG;

  // Need to change this rendering of observations on the map
  // const localLayer = L.geoJSON([], {
  //     style: function(feature) {
  //       console.log("Styling...", feature)
  //       let _style = {
  //         color: "#BE1E2D",
  //         weight: 3,
  //         opacity: 1,
  //         fillOpacity: 1.0
  //       };
  //       if (feature.hasOwnProperty("mapStyle")) {
  //         _style = feature.mapStyle
  //       }
  //       return _style;
  //     },
  //     pointToLayer: function(feature, latlng) {
  //       // Use this area if we want to render based on a property
  //       return L.circleMarker(latlng, {
  //         radius: 6
  //       });
  //     },
  //     onEachFeature: onEachFeature
  //   })
  //   .addTo(map);

  // const onEachFeature = (feature, layer) => {
  //   console.log("Feature", feature)

  //   // Point, LineString, Polygon, MultiPoint, MultiLineString, and MultiPolygon
  //   if (feature.geometry.type != "Point")
  //   const p = feature.properties;

  //   if (selectionHandler) {
  //     layer.on("click", () => selectionHandler(feature.id));
  //   } else {
  //     layer.bindPopup(
  //       "Project: " +
  //       p.project_name +
  //       "<br/>Product: " +
  //       p.product_name +
  //       "<br/>Status: " +
  //       p.status_name +
  //       "<br/>Indicator: " +
  //       p.indicator_name
  //     );
  //   }
  // };

  // return localLayer;
};

export const highlightSelectedObservation = (obsLayer, id) => {
  obsLayer.eachLayer(layer => {
    if (layer.feature.id === id) {
      layer.setStyle({
        color: "#76ff03"
      });
      if (layer instanceof L.CircleMarker) {
        layer.bringToFront();
      }
    } else {
      layer.setStyle({
        color: "#BE1E2D"
      });
    }
  });
};

export const addMarkers = (data, mapType, selectionHandler) => {
  var _markers = [];
  var _m;

  for (var i = 0; i < data.length; i++) {

    if (data[i].hasOwnProperty("show_on_map")){

      if (data[i].show_on_map){
        _m = setMarkerSymbol(data[i], mapType, selectionHandler);
      }
    } else {
      _m = setMarkerSymbol(data[i], mapType, selectionHandler);
    }
    if (_m) _markers.push(_m);
  }
  return _markers;
};

function setMarkerSymbol(feature, mapType, selectionHandler) {
  try {
    var marker, latlng, _style;

    var _layer = L.geoJson(feature, {});

    latlng = _layer.getBounds().getCenter();

    if (feature.hasOwnProperty("marker_style")){
      _style = feature.marker_style;
      // if (mapType == contentviews.DETAIL_VIEW_MAP){
      //   _style.color="#fff";
      // }
    } else {
      _style = {
        color: "#BE1E2D",
        radius: 6
      }
    }
    marker = L.circleMarker(latlng, _style);

    if (selectionHandler) {
      marker.on("click", () => selectionHandler(feature.id));
    } else {
      marker.bindPopup(setMarkerPopup(feature));
    }

    return marker;

  } catch (err) {
    logger.error('Error setting the map marker symbol.', {error:err, feature});
    return;
  }
};

const setMarkerPopup = (feature) => {
  const _p = feature.properties
  let pro_attr = "";
  let class_name = ""

  if (_p.hasOwnProperty("classification_name")){
    class_name = "<br/>Classification:" +  _p.classification_name
  }

  if (_p.hasOwnProperty("product_attribution") && _p.product_attribution){
    let _product_attribution = _p.product_attribution;
    if (!Array.isArray(_product_attribution)) {
      _product_attribution = Object.values(_product_attribution)  
    }
    // Ensure alphabetical listing
    _product_attribution.sort((a, b) => (a.label > b.label) ? 1 : -1)

    if (_product_attribution && _product_attribution.length > 0){
      for (var i=0;i<_product_attribution.length;i++){
        pro_attr = pro_attr + "<br/>" + _product_attribution[i]["label"] + ": " +
        _product_attribution[i]["value"] + " " + _product_attribution[i]["uom"]
      }
    }
  }
  

  let _text = ""
  if (_p){
    _text = "Project: " +
    _p.project_name +
    "<br/>Product: " +
    _p.product_name +
    class_name +
    "<br/>Status: " +
    _p.status_name +
    "<br/>Indicator: " +
    _p.indicator_name +
    pro_attr;
  }

  return _text
}


export const centerMapOnLayer = (map, layer) => {
  const {
    x,
    y
  } = map.getSize();
  const bounds = layer.getBounds();

  if (bounds.isValid() && x !== 0 && y !== 0) {
    map.fitBounds(bounds);
  }
  // else map.setView([41.6, -83], 9);
};

const createBasemapLayer = (source, options) => {
  const layer = L.satelyticsLayer(source, options);
  return layer;
};

const createReferenceLayer = (reference_layer) => {
  // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
  // TODO: PMTILE file off-line
  // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
  
  // const is_online = getOfflineMode() !== true;
  // const not_using_pmtiles = reference_layer.format !== 'pmtile';
  
  reference_layer.layerOptions.pane = "referencePane";

  // Having a vector tile server render us protobuf files 
  // if ( not_using_pmtiles || is_online ) {
  
  return L.vectorGrid.protobuf(reference_layer.url, reference_layer.layerOptions);
  // }

  // console.log('Using pmtiles offline reference layer', { reference_layer});

  // // We're offline using PMTiles. 
  // return  createOfflineLayer(reference_layer);
};

const createNCLayer = (source, options) => {
  const state = store.getState();
  const account = state.auth.account;
  source = source.substring(2)
  const prefix = (/\.json$/.test(source) ? "mosaic" : "single")
  const suffix = (/\.json$/.test(source) ? "" : ".tif")
  return L.satelyticsLayer(
    `/${prefix}/tiles/{z}/{x}/{y}?url=` + 
    `${account}` + 
    `${source.toLowerCase()}` +
    `${suffix}`, {
    tms: false,
    minZoom: options.minZoom || 5,
    maxZoom: options.maxZoom || 20,
    layername: source,
    subdomains: [],
    zIndex: options.zIndex || 10,
    bounds: options.bounds || null
  });

};

function rgbToHex(rgbString) {
  const regex = /rgb\((\d+),(\d+),(\d+)\)/;
  const match = rgbString.match(regex);

  if (match) {
    const red = parseInt(match[1], 10).toString(16).padStart(2, '0');
    const green = parseInt(match[2], 10).toString(16).padStart(2, '0');
    const blue = parseInt(match[3], 10).toString(16).padStart(2, '0');

    return `#${red}${green}${blue}FF`;
  } else {
    return null; // Return null if the input string is not in the correct format
  }
}

function rgbaToHex(rgbaString) {
  const regex = /rgba\((\d+),(\d+),(\d+),([\d.]+)\)/;
  const match = rgbaString.match(regex);

  if (match) {
    const red = parseInt(match[1], 10).toString(16).padStart(2, '0');
    const green = parseInt(match[2], 10).toString(16).padStart(2, '0');
    const blue = parseInt(match[3], 10).toString(16).padStart(2, '0');

    // Convert alpha to a value between 0 and 255 (8-bit)
    const alpha = Math.round(parseFloat(match[4]) * 255).toString(16).padStart(2, '0');

    return `#${red}${green}${blue}${alpha}`;
  } else {
    return null; // Return null if the input string is not in the correct format
  }
}

const createSBLayer = (observation, options) => {
  
  const stops = Object.values(observation.properties.obs_result_renderer.color_map).sort((a, b) => a.stop - b.stop).map(item => item.stop);
  const colors = Object.values(observation.properties.obs_result_renderer.color_map).sort((a, b) => a.stop - b.stop).map(item => item.color);
  stops.unshift(0)
  
  const colormap = []
  for (let i = 0; i < colors.length; i++) {
    if (colors[i] == "transparent")
      var color_hex = "#00000000"
    else if (colors[i].toString().indexOf('rgba') != -1)
      var color_hex = rgbaToHex(colors[i])
    else
      var color_hex = rgbToHex(colors[i])
    colormap.push([[stops[i], stops[i + 1]], color_hex])
  }

  var source = observation.properties.obs_result_image.substring(2);
  const state = store.getState();
  const account = state.auth.account;
  const prefix = (/\.json$/.test(source) ? "mosaic" : "single")
  const suffix = (/\.json$/.test(source) ? "" : ".tif")
  const layer = L.satelyticsLayer(
    `/${prefix}/tiles/{z}/{x}/{y}?url=` + 
    `${account}` + 
    `${source.toLowerCase()}` +
    `${suffix}` + 
    `&colormap=${encodeURIComponent(
      JSON.stringify(colormap)
    )}`, {
      tms: false,
      minZoom: options.minZoom || 5,
      maxZoom: options.maxZoom || 20,
      layername: source,
      subdomains: [],
      zIndex: options.zIndex || 20,
      bounds: options.bounds || null
    }
  );
  return layer;
};

export const initializeObsRasterLayers = (observation, project) => {
  const layerArray = [];
  let layer;

  if (observation) {
    let projectBounds = null,
      legend;
    try {
      projectBounds = L.geoJSON(project.project_boundary)
        .getBounds();
    } catch (error) {
      // console.log("Error getting bounds for project:", error, project);
      logger.error('Error intializing Observation raster layers', {error, boundary:project.project_boundary});
    }

    if (observation.properties.prv_source_image) {
      layer = createNCLayer(observation.properties.prv_source_image, {
        zIndex: 10,
        bounds: projectBounds
      });
      layer.dbId = observation.properties.prv_source_id;
      layer.displayOrder = 2;
      layer.source = observation.properties.prv_source_image;
      layer.effectiveDate = observation.properties.prv_source_date;
      layer.checked = false;
      layer.title =
        "Natural Color (" +
        moment(observation.properties.prv_source_date)
        .format("ll") +
        ")";
      layer.legend = null;
      layer.type = "NC";

      layerArray.push(layer);
    }

    if (observation.properties.source_image) {
      layer = createNCLayer(observation.properties.source_image, {
        zIndex: 11,
        bounds: projectBounds
      });
      layer.dbId = observation.properties.source_id;
      layer.displayOrder = 1;
      layer.source = observation.properties.source_image;
      layer.effectiveDate = observation.properties.obs_date;
      layer.checked = true;
      layer.title =
        "Natural Color (" +
        moment(observation.properties.obs_date)
        .format("ll") +
        ")";
      layer.legend = null;
      layer.type = "NC";

      layerArray.push(layer);
    }
    // Not used in pwa for now
    // if (observation.properties.prv_result_image) {
    //   legend = observation.properties.prv_result_renderer;

    //   layer = createSBLayer(observation.properties.prv_result_image, {
    //     zIndex: 20,
    //     bounds: projectBounds
    //   });
    //   layer.dbId = observation.properties.prv_result_id;
    //   layer.displayOrder = 4;
    //   layer.source = observation.properties.prv_result_image;
    //   layer.effectiveDate = observation.properties.prv_source_date;
    //   layer.checked = false;
    //   layer.title = legend ? legend.legend_title : null;
    //   layer.legend = legend ? (legend.hasOwnProperty("color_map")? legend.color_map : JSON.parse(legend.color_stops)) : null;
    //   layer.type = "SB";

    //   layerArray.push(layer);
    // }

    if (observation.properties.obs_result_image) {
      legend = observation.properties.obs_result_renderer;

      layer = createSBLayer(observation, {
        zIndex: 21,
        bounds: projectBounds
      });
      layer.dbId = observation.properties.obs_result_id;
      layer.displayOrder = 3;
      layer.source = observation.properties.obs_result_image;
      layer.effectiveDate = observation.properties.obs_date;
      layer.checked = true;
      layer.title = legend ? legend.legend_title : null;
      layer.legend = legend ? (legend.hasOwnProperty("color_map")? legend.color_map : JSON.parse(legend.color_stops)) : null;
      layer.type = "SB";

      layerArray.push(layer);
    }
    // Not used in pwa for now
    // if (observation.properties.diff_result_image) {
    //   legend = observation.properties.diff_result_renderer;

    //   layer = createSBLayer(observation.properties.diff_result_image, {
    //     zIndex: 22,
    //     bounds: projectBounds
    //   });
    //   layer.dbId = observation.properties.diff_result_id;
    //   layer.displayOrder = 5;
    //   layer.source = observation.properties.diff_result_image;
    //   layer.effectiveDate = observation.properties.obs_date;
    //   layer.checked = false;
    //   layer.title = legend ? legend.legend_title : null;
    //   layer.legend = legend ? (legend.hasOwnProperty("color_map")? legend.color_map : JSON.parse(legend.color_stops)) : null;
    //   layer.type = "SB";

    //   layerArray.push(layer);
    // }
  }
  return layerArray;
};

const createBasemapLayerArray = (refLayers) => {
  const layerArray = [];

  refLayers.basemaps.forEach( item => {
    let _json;
    try {
      if (item.hasOwnProperty("data")){
        _json = item.data;
      } else {
        _json = getRootNode(JSON.parse("{" + item.code + "}"));
      }

    } catch (error) {
      // console.log("Error parsing Basemap configuration:", error, item);
      logger.error('Issues parsing the Basemap configuration', {error, item});
    }
    if (_json) {
      const layerDef = _json;

      const layer = createBasemapLayer(layerDef.url, layerDef
        .layerOptions);
      layer.dbId = item.id + 100000;
      layer.displayOrder = item.display_order;
      layer.source = item.name;
      layer.effectiveDate = null;
      layer.checked = layerDef.layerOptions.layername === "OSM" ? true :
        false;
      layer.title = layerDef.name;
      layer.legend = null;
      layer.type = "BM";

      layerArray.push(layer);
    }
  })

  return layerArray;
}

export const createReferenceLayerArray = (refLayers, projects) => {

  const layerArray = [];

  for (const project of projects) {
    const refMaps = refLayers.overlays.filter(item => {
      return project.reference_layers.includes(item.id);
    });

    let projectBounds = null;
    try {
      projectBounds = L.geoJSON(project.project_boundary)
        .getBounds();
    } catch (error) {
      logger.error('Error getting bounds for the project', {error, boundary:project.project_boundary});
    }

    for (const item of refMaps) { 
      let _json;
      try {
        if (item.hasOwnProperty("data")){
          _json = item.data;
        } else {
          _json = getRootNode(JSON.parse("{" + item.code + "}"));
        }
      } catch (error) {
        logger.error('Error parsing reference layer configuration', {error, item});
      }
      if (_json) {
        const layerDef = _json;

        if (!layerDef.layerOptions.hasOwnProperty("bounds") &&
          projectBounds) {
          layerDef.layerOptions.bounds = projectBounds;
        }
      
        layerDef.layerOptions.layername = layerDef.name;
        const layer = createReferenceLayer(layerDef);
        layer.dbId = item.id + 110000;
        layer.displayOrder = item.display_order;
        layer.source = item.name;
        layer.effectiveDate = null;
        layer.checked = layerDef.hasOwnProperty("visible") ?
          layerDef.visible :
          true;
        layer.title = layerDef.name;
        layer.legend = getRootNode(layerDef.layerOptions.vectorTileLayerStyles);
        layer.type = "RF";

        // Check if layer already added
        if( !layerArray.find(l =>  l.dbId === layer.dbId)) {
          layerArray.push(layer)
        }

      }
    }
  }

  return layerArray;
}


export const initializeRefLayers = (refLayers, projects) => {
  const baseMapLayerArray = createBasemapLayerArray(refLayers)
  const referenceLayerArray = createReferenceLayerArray(refLayers, projects);

  return baseMapLayerArray.concat(referenceLayerArray);
};

const getRootNode = source => {
  let rootKey;
  for (const prop in source) {
    rootKey = prop;
  }
  const _object = source[rootKey];

  return _object;
};

export const renderLegend = style => {
  if (style.hasOwnProperty("geomtype")) {
    switch (style.geomtype.toLowerCase()) {
      case "string":
        return renderLine(style);

      case "polygon":
        return renderPolygon(style);

      case "multipolygon":
        return renderPolygon(style);

      case "point":
        return renderPoint(style);

      default:
        logger.warn("Geometry Type (" + style.geomtype + ") is not valid for reference layer.", style);
        return null;
    }
  } else {
    logger.warn("Geometry Type has not been defined for reference layer.", style);
    return null;
  }
};

const renderLine = layerstyle => {
  let dashArray = "";
  if (layerstyle.hasOwnProperty("dashArray")) dashArray = layerstyle
    .dashArray;

  const _style = {
    stroke: layerstyle.color,
    strokeWidth: 2
  };

  return (
    <svg height="12" width="24">
      <line
        x1="0"
        y1="6"
        x2="24"
        y2="6"
        strokeDasharray={dashArray}
        style={_style}
      />
    </svg>
  );
};

const renderPolygon = layerstyle => {
  let _style,
    _dashArray = "";

  const _fill_opacity = layerstyle.hasOwnProperty("fillOpacity") ?
    layerstyle.fillOpacity :
    1.0;

  const _fill_color = layerstyle.hasOwnProperty("fillColor") ?
    layerstyle.fillColor :
    layerstyle.color;

  _style = {
    stroke: layerstyle.color,
    strokeWidth: 2
  };

  if (layerstyle.hasOwnProperty("dashArray")) _dashArray = layerstyle
    .dashArray;

  if (_fill_opacity == 0.0)
    return (
      <svg height="12" width="24">
        <line
          x1="0"
          y1="6"
          x2="24"
          y2="6"
          strokeDasharray={_dashArray}
          style={_style}
        />
      </svg>
    );
  else {
    _style.fill = _fill_color;
    _style.fillOpacity = _fill_opacity;
    return (
      <svg height={12} width={12}>
        <rect width={12} height={12} style={_style} />
      </svg>
    );
  }
};

const renderPoint = layerstyle => {
  const _fill_opacity = layerstyle.hasOwnProperty("fillOpacity") ?
    layerstyle.fillOpacity :
    1.0;
  const _fill_color = layerstyle.hasOwnProperty("fillColor") ?
    layerstyle.fillColor :
    layerstyle.color;

  const _style = {
    stroke: layerstyle.color,
    strokeWidth: 1,
    fill: _fill_color,
    fillOpacity: _fill_opacity
  };

  return (
    <svg height="12" width="12">
      <circle cx="6" cy="6" r="5" style={_style} />
    </svg>
  );
};

export const getSelectedProjectsZoomCenter = (map, projectList,
  selectedProjectIDs) => {
  var fg = new L.FeatureGroup();
  projectList.forEach(project => {
    if (selectedProjectIDs.indexOf(project.id) > -1) {
      const projectBoundary = new L.polygon(
        L.GeoJSON.coordsToLatLngs(
          project.project_boundary.coordinates,
          2
        )[0], {
          color: "#DF353D",
          fill: false,
          stroke: true,
          dashArray: "10,5"
        }
      );
      fg.addLayer(projectBoundary);
    }
  })

  let bounds;
  if (fg.getLayers()
    .length > 0) {
    bounds = fg.getBounds();
  } else {
    bounds = L.latLngBounds([
      [-80, -170],
      [80, 170]
    ]);
  }

  return {
    zoom: (map ? map.getBoundsZoom(bounds) : 5),
    center: bounds.getCenter()
  }

}
