import './Leaflet.VectorGrid';
import {
  VectorTile
} from 'vector-tile';
import {
  cacheThenNetworkStrategy
} from "../../api/referenceStrategies";

/*
 * 🍂class VectorGrid.Protobuf
 * 🍂extends VectorGrid
 *
 * A `VectorGrid` for vector tiles fetched from the internet.
 * Tiles are supposed to be protobufs (AKA "protobuffer" or "Protocol Buffers"),
 * containing data which complies with the
 * [MapBox Vector Tile Specification](https://github.com/mapbox/vector-tile-spec/tree/master/2.1).
 *
 * This is the format used by:
 * - Mapbox Vector Tiles
 * - Mapzen Vector Tiles
 * - ESRI Vector Tiles
 * - [OpenMapTiles hosted Vector Tiles](https://openmaptiles.com/hosting/)
 *
 * 🍂example
 *
 * You must initialize a `VectorGrid.Protobuf` with a URL template, just like in
 * `L.TileLayer`s. The difference is that the template must point to vector tiles
 * (usually `.pbf` or `.mvt`) instead of raster (`.png` or `.jpg`) tiles, and that
 * you should define the styling for all the features.
 *
 * <br><br>
 *
 * For OpenMapTiles, with a key from [https://openmaptiles.org/docs/host/use-cdn/](https://openmaptiles.org/docs/host/use-cdn/),
 * initialization looks like this:
 *
 * ```
 * L.vectorGrid.protobuf("https://free-{s}.tilehosting.com/data/v3/{z}/{x}/{y}.pbf.pict?key={key}", {
 * 	vectorTileLayerStyles: { ... },
 * 	subdomains: "0123",
 * 	key: 'abcdefghi01234567890',
 * 	maxNativeZoom: 14
 * }).addTo(map);
 * ```
 *
 * And for Mapbox vector tiles, it looks like this:
 *
 * ```
 * L.vectorGrid.protobuf("https://{s}.tiles.mapbox.com/v4/mapbox.mapbox-streets-v6/{z}/{x}/{y}.vector.pbf?access_token={token}", {
 * 	vectorTileLayerStyles: { ... },
 * 	subdomains: "abcd",
 * 	token: "pk.abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRTS.TUVWXTZ0123456789abcde"
 * }).addTo(map);
 * ```
 */
L.VectorGrid.Protobuf = L.VectorGrid.extend({

  options: {
    // 🍂section
    // As with `L.TileLayer`, the URL template might contain a reference to
    // any option (see the example above and note the `{key}` or `token` in the URL
    // template, and the corresponding option).
    //
    // 🍂option subdomains: String = 'abc'
    // Akin to the `subdomains` option for `L.TileLayer`.
    subdomains: 'abc', // Like L.TileLayer
    //
    // 🍂option fetchOptions: Object = {}
    // options passed to `fetch`, e.g. {credentials: 'same-origin'} to send cookie for the current domain
    fetchOptions: {}
  },

  initialize: function(url, options) {
    // Inherits options from geojson-vt!
    // 		this._slicer = geojsonvt(geojson, options);
    this._url = url;
    this._layername = options.name;
    L.VectorGrid.prototype.initialize.call(this, options);
  },

  // 🍂method setUrl(url: String, noRedraw?: Boolean): this
  // Updates the layer's URL template and redraws it (unless `noRedraw` is set to `true`).
  setUrl: function(url, noRedraw) {
    this._url = url;

    if (!noRedraw) {
      this.redraw();
    }

    return this;
  },

  _getSubdomain: L.TileLayer.prototype._getSubdomain,

  _isCurrentTile: function(coords, tileBounds) {
    if (!this._map) {
      return true;
    }

    var zoom = this._map._animatingZoom ? this._map._animateToZoom : this
      ._map._zoom;

    // ########################################################################################
    //  Satelytics Bug Fix #168293616 Reference Data does not render after pinch gesture on map
    // Need to accomodate the integer only aspect of the coord.z value
    // var currentZoom = zoom === coords.z;
    var currentZoom = Math.round(zoom) === coords.z;
    // ########################################################################################

    var tileBounds = this._tileCoordsToBounds(coords);
    var currentBounds = this._map.getBounds()
      .overlaps(tileBounds);
    return currentZoom && currentBounds;

  },

  getTileUrl: function(coords, tileBounds) {
    var data = {
      s: this._getSubdomain(coords), 
      x: coords.x,
      y: coords.y,
      z: coords.z
      // 			z: this._getZoomForUrl()	/// TODO: Maybe replicate TileLayer's maxNativeZoom
    };
    if (this._map && !this._map.options.crs.infinite) {
      var invertedY = this._globalTileRange.max.y - coords.y;
      if (this.options
        .tms) { // Should this option be available in Leaflet.VectorGrid?
        data['y'] = invertedY;
      }
      data['-y'] = invertedY;
    }

    if (!this._isCurrentTile(coords, tileBounds)) {
      return Promise.resolve({
        layers: []
      });
    }

    return L.Util.template(this._url, L.extend(data, this
      .options));
  },

  // Copied from satelyticsLayer to get all of the tile urls of a project for caching.
  getTileUrls: function(bounds, zoomLevel) {
    const origurl = this._url;
    this.setUrl(
      this._url.replace("{z}", zoomLevel),
      true // Skip the redraw
    );

    const tileBounds = L.bounds(
      bounds.min.divideBy(this.getTileSize().x).floor(),
      bounds.max.divideBy(this.getTileSize().x).floor()
    );

    let { max: {x: maxx, y: maxy}, min: {x: minx, y:miny}} = tileBounds;

    let tiles = new Array((maxx - minx) * (maxy - miny));
    let count = 0;
    for( let i = minx; i <= maxx; i++) {
      for(let j = miny; j <= maxy; j++) {
        const tilePoint = new L.Point(i,j);
        tiles[count++] = this.getTileUrl(tilePoint);
      }
    }

    this.setUrl(origurl, true);

    return tiles;
  },

  _getVectorTilePromise: function(coords, tileBounds) {
    const tileUrl = this.getTileUrl(coords, tileBounds);
    return cacheThenNetworkStrategy(tileUrl, this.options);
  },
});

// 🍂factory L.vectorGrid.protobuf(url: String, options)
// Instantiates a new protobuf VectorGrid with the given URL template and options
L.vectorGrid.protobuf = function(url, options) {
  return new L.VectorGrid.Protobuf(url, options);
};
