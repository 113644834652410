import * as actionTypes from "./actionTypes";
import db from "../db";

export const changeFilters = filters => {
  return {
    type: actionTypes.CHANGE_FILTERS,
    filters: filters
  };
};

/**
 * Used to modify the response of a lookup request in place
 * - Uses a "precheck" to 
 * @param {axios response} response 
 * @param {string} lookup_table the name of the table in indexedDB
 */
export const persistLookupFilterSettings = async (response, lookup_table) => {

  // Find the ids of the lookups that are currently checked in indexedDB
  const lookups_in_database = await db.table(lookup_table).toArray();
  const checked_lookup_ids = lookups_in_database.filter(lookup => (
    lookup.checked && lookup.checked === true
  )).map(checked_lookup => (
    checked_lookup.code
  ));


  // Overwrite the response adding a checked value if the lookup was checked in the database
  response.data.forEach(d => {
    if (checked_lookup_ids.includes(d.code)) {
      d.checked = true;
    }
  })
}
