import db from "./db";
import AlertsWorker from '../api/alerts.worker'

const WorkerSingleton = (() => {
  let instance = null;

  return {
    getInstance: () => {
      if (!instance) {
        instance = new AlertsWorker();
      }
      return instance;
    },
    hello: () => console.log('Alerts Worker is active.')
  }
})();

export const replaceTableInDB = (table, response) => {
  if (!db) throw new Error('Database could not be opened');

  let worker = WorkerSingleton.getInstance();

  worker.postMessage({
    response: response,
    table: table
  });
}

export const formDataToJson = formdata => {
  const _data = {};

  for (var pair of formdata.entries()) {
    _data[pair[0]] = pair[1];
  }

  return _data;
};

export const updateObject = (oldObject, updatedValues) => {
  return {
    ...oldObject,
    ...updatedValues
  };
};

export const decodeJWT = token => {
  // console.log("Decoding token...", token)
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+")
    .replace("_", "/");
  return JSON.parse(window.atob(base64));
};
