import axios from "axios";

// Need to use an ArrayBuffer instead of Blob as there are issues
// with storing blobs in IndexedDB on iOS
const instance = axios.create({
  baseURL: "",
  rootURL: "",
  responseType: "arraybuffer"
  // by catching errors, there is no need to pretend 401 or 404 is okay
  // validateStatus: (status) => status === 200 || status === 401 || status === 404
});

instance.interceptors.request.use(
  request => {
    // All Satelytics-based urls will begin with '/' while
    // All other external basemap tiles with start with 'http/https'
    if (request.url.slice(0, 1) !== "/") {
      delete request.headers["Authorization"];
    }

    // if (request.imageType && request.imageType === "SB") {
    //   const regex = /\/[a-f0-9]{8}-?[a-f0-9]{4}-?4[a-f0-9]{3}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}/;
    //   request.baseURL = request.baseURL.replace(regex, "/sb" + "$&");
    // }

    return request;
  },
  error => {
    // Pass on in case local component needs to
    // do anything special
    return Promise.reject(error);
  }
);

export default instance;
