import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  comments: [],
  media: [],
  loading: false,
  error: null
};

const fetchCommentsSuccess = (state, action) => {
  return updateObject(state, {
    comments: action.data,
    loading: false,
    error: null
  });
};

const addCommentSuccess = (state, action) => {
  return updateObject(state, {
    comments: state.comments.concat(action.data),
    loading: false,
    error: null
  });
};

const saveCommentSuccess = (state, action) => {
  return updateObject(state, {
    comments: state.comments.map(item => {
      return item.id === action.data.id ? action.data : item;
    }),
    loading: false,
    error: null
  });
};

const removeCommentSuccess = (state, action) => {
  return updateObject(state, {
    comments: state.comments.filter(item => item.id !== action.data.id),
    loading: false,
    error: null
  });
};

const manageMediaStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null
  });
};

const fetchMediaSuccess = (state, action) => {
  return updateObject(state, {
    media: action.data,
    loading: false,
    error: null
  });
};

const addMediaSuccess = (state, action) => {
  return updateObject(state, {
    media: state.media.concat(action.data),
    loading: false,
    error: null
  });
};

const saveMediaSuccess = (state, action) => {
  return updateObject(state, {
    media: state.media.map(item => {
      return item.id === action.data.id ? action.data : item;
    }),
    loading: false,
    error: null
  });
};

const removeMediaSuccess = (state, action) => {
  return updateObject(state, {
    media: state.media.filter(item => item.id !== action.data.id),
    loading: false,
    error: null
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_COMMENT_SUCCESS:
      return addCommentSuccess(state, action);
    case actionTypes.SAVE_COMMENT_SUCCESS:
      return saveCommentSuccess(state, action);
    case actionTypes.REMOVE_COMMENT_SUCCESS:
      return removeCommentSuccess(state, action);
    case actionTypes.FETCH_COMMENTS_SUCCESS:
      return fetchCommentsSuccess(state, action);
    case actionTypes.MANAGE_MEDIA_START:
      return manageMediaStart(state, action);
    case actionTypes.ADD_MEDIA_SUCCESS:
      return addMediaSuccess(state, action);
    case actionTypes.SAVE_MEDIA_SUCCESS:
      return saveMediaSuccess(state, action);
    case actionTypes.REMOVE_MEDIA_SUCCESS:
      return removeMediaSuccess(state, action);
    case actionTypes.FETCH_MEDIA_SUCCESS:
      return fetchMediaSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
