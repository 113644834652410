import React, {useState} from 'react';
import classes from './Notification.css';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
const Notification = ({
  duration,
  close,
  message
}) => {
  const [openState, setOpenState] = useState(true);
  const dura = duration ? duration : 6000;
  const closeHandeler =  (event, reason) => {
    if (reason === 'clickaway') {
      if (close) close();
      return;
    }
    setOpenState(false);
    if (close) close();
  };
  const snack = <Snackbar open={openState} autoHideDuration={dura} onClose={closeHandeler} >
        <SnackbarContent message={<span id="client-snackbar" className={classes.message}> {message} </span>}
  action={[<IconButton key="close" aria-label="close" color="inherit" onClick={closeHandeler}>
           <CloseIcon className={classes.icon} /> </IconButton>]} /></Snackbar>;
  return snack
}
export default Notification;
