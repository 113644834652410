import * as actionTypes from "./actionTypes";
import db from "../db";
import axios from "../../api/axios-api";
import {
  replaceTableInDB
} from "../utility";
// import { updateAlertSelection } from "./alerts";
import { persistLookupFilterSettings } from "./filters";
import SioLogger from "../../modules/Logger/logger";

const logger = SioLogger('src/store/actions/lookups.js');

const fetchLookupsStart = () => {
  return {
    type: actionTypes.FETCH_LOOKUPS_START
  };
};

const fetchLookupsFailed = error => {
  return {
    type: actionTypes.FETCH_LOOKUPS_FAILED,
    error: error
  };
};

const fetchProductsSuccess = data => {
  return {
    type: actionTypes.FETCH_PRODUCTS_SUCCESS,
    data: data
  };
};

const fetchIndicatorsSuccess = data => {
  return {
    type: actionTypes.FETCH_INDICATORS_SUCCESS,
    data: data
  };
};

const fetchStatusSuccess = data => {
  return {
    type: actionTypes.FETCH_STATUS_SUCCESS,
    data: data
  };
};

const fetchSystemDefaultsSuccess = data => {
  return {
    type: actionTypes.FETCH_SYSTEM_DEFAULTS_SUCCESS,
    data: data
  };
};

const fetchPackagesSuccess = data => {
  return {
    type: actionTypes.FETCH_PACKAGES_SUCCESS,
    data: data
  };
};

const fetchClassificationsSuccess = data => {
  return {
    type: actionTypes.FETCH_CLASSIFICATIONS_SUCCESS,
    data: data
  };
}

const fetchSourcesSuccess = data => {
  return {
    type: actionTypes.FETCH_SOURCES_SUCCESS,
    data: data
  };
}

const fetchAssetsSuccess = data => {
  return {
    type: actionTypes.FETCH_ASSETS_SUCCESS,
    data: data
  };
}

export const fetchLookups = () => {
  return dispatch => {
    dispatch(fetchLookupsStart());
    const p1 = fetchProducts();
    const p2 = fetchStatus();
    const p3 = fetchIndicators();
    const p4 = fetchSystemDefaults();
    const p5 = fetchPackages();
    const p6 = fetchClassifications();
    const p7 = fetchSources();
    const p8 = fetchAssets();
    return Promise.all(
        [p1, p2, p3, p4, p5, p6, p7, p8].map(p =>
          p.catch(e => {
            logger.error(`Error fetching lookup[${p}]: ${e.message}`, e);
            return {
              error: e.message
            };
          })
        )
      )
      .then(results => {
        let err = "";

        results.forEach((item, idx) => {
          switch (idx) {
            case 0:
              item.error ?
                (err = err + item.error + ", ") :
                dispatch(fetchProductsSuccess(item));
              break;
            case 1:
              item.error ?
                (err = err + item.error + ", ") :
                dispatch(fetchStatusSuccess(item));
              break;
            case 2:
              item.error ?
                (err = err + item.error + ", ") :
                dispatch(fetchIndicatorsSuccess(item));
              break;
            case 3:
              item.error ?
                (err = err + item.error + ", ") :
                dispatch(fetchSystemDefaultsSuccess(item));
              break;
            case 4:
              item.error ?
                (err = err + item.error + ", ") :
                dispatch(fetchPackagesSuccess(item));
              break;
            case 5:
              item.error ?
                (err = err + item.error + ", ") :
                dispatch(fetchClassificationsSuccess(item));
              break;
            case 6:
              item.error ?
                (err = err + item.error + ", ") :
                dispatch(fetchSourcesSuccess(item));
              break;
            case 7:
              item.error ?
                (err = err + item.error + ", ") :
                dispatch(fetchAssetsSuccess(item));
              break;
            default:
              break;
          }
        });
        if (err.length > 0) {
          dispatch(fetchLookupsFailed(err));
        }
      });
  };
};


const fetchProducts = async () => {
  const response = await axios.get('/v2/products');
  response.data.sort((a,b) => a.state_order > b.state_order);
  await persistLookupFilterSettings(response, 'products');
  replaceTableInDB('products', response.data);

  return response.data;
};

const fetchStatus = async () => {
  const response = await axios.get('/v4/status');
  response.data.forEach(status => {
    status.id = status.code;
  });
  await persistLookupFilterSettings(response, 'status');

  replaceTableInDB('status', response.data);
  return response.data;
};

const fetchIndicators = async () => {
  const response = await axios.get('/v2/indicators');
  await persistLookupFilterSettings(response, 'indicators');

  replaceTableInDB('indicators', response.data);
  return response.data;
};

const fetchSystemDefaults = () => {
  return axios.get("/v2/systemdefaults?application=MOBILE")
    .then(response => {
      replaceTableInDB("systemdefaults", response.data);
      return response.data
    });
};

const fetchPackages = () => {
  return axios.get("/v2/packages")
    .then(response => {
      replaceTableInDB("packages", response.data);
      return response.data
    });
};

const fetchClassifications = () => {
  return axios.get("/v2/classifications")
    .then(response => {
      replaceTableInDB("classifications", response.data);
      return response.data
    });
}

const fetchSources = () => {
  return axios.get("/sources")
    .then(response => {
      replaceTableInDB("sources", response.data.features);
      return response.data.features
    });
}

const fetchAssets = () => {
  return axios.get("/assets")
    .then(response => {
      replaceTableInDB("assets", response.data.features);
      return response.data.features
    });
}

//get data from local indexedDB
export const refreshLookups = () => {
  return dispatch => {
    dispatch(refreshProducts());
    dispatch(refreshStatus());
    dispatch(refreshIndicators());
    dispatch(refreshSystemDefaults());
    dispatch(refreshPackages());
    dispatch(refreshClassifications());
    dispatch(refreshSources());
    dispatch(refreshAssets());
    dispatch(fetchLookupsStart());
  };
};

const refreshIndicators = () => {
  return dispatch => {
    // dispatch(fetchIndicatorsStart());
    db.indicators.toArray()
      .then(response => {
        logger.info("Retrieving indicators from cache...");
        dispatch(fetchIndicatorsSuccess(response));
      });
    // .catch(error => {
    //   dispatch(fetchIndicatorsFailed(error));
    // });
  };
};

const refreshProducts = () => {
  return dispatch => {
    // dispatch(fetchProductsStart());
    db.products.toArray()
      .then(response => {
        logger.info("Retrieving products from cache...");
        dispatch(fetchProductsSuccess(response));
      });
    // .catch(error => {
    //   dispatch(fetchProductsFailed(error));
    // });
  };
};

const refreshStatus = () => {
  return dispatch => {
    // dispatch(fetchStatusStart());
    db.status.toArray()
      .then(response => {
        logger.info("Retrieving status from cache...");
        dispatch(fetchStatusSuccess(response));
      });
    // .catch(error => {
    //   dispatch(fetchStatusFailed(error));
    // });
  };
};

const refreshSystemDefaults = () => {
  return dispatch => {
    db.systemdefaults.toArray()
      .then(response => {
        logger.info("Retrieving system defaults from cache...");
        dispatch(fetchSystemDefaultsSuccess(response));
      });
    // .catch(error => {
    //   dispatch(fetchStatusFailed(error));
    // });
  };
};

const refreshPackages = () => {
  return dispatch => {
    db.packages.toArray()
      .then(response => {
        logger.info("Retrieving packages from cache...");
        dispatch(fetchPackagesSuccess(response));
      });
    // .catch(error => {
    //   dispatch(fetchStatusFailed(error));
    // });
  };
};

const refreshClassifications = () => {
  return dispatch => {
    db.classifications.toArray()
      .then(response => {
        logger.info("Retrieving classifications from cache...");
        dispatch(fetchClassificationsSuccess(response));
      });
    // .catch(error => {
    //   dispatch(fetchStatusFailed(error));
    // });
  };
}

const refreshSources = () => {
  return dispatch => {
    db.sources.toArray()
      .then(response => {
        logger.info("Retrieving sources from cache...");
        dispatch(fetchSourcesSuccess(response));
      });
    // .catch(error => {
    //   dispatch(fetchStatusFailed(error));
    // });
  };
}

const refreshAssets = () => {
  return dispatch => {
    db.assets.toArray()
      .then(response => {
        logger.info("Retrieving assets from cache...");
        dispatch(fetchAssetsSuccess(response));
      });
    // .catch(error => {
    //   dispatch(fetchStatusFailed(error));
    // });
  };
}

export const checkProducts = selected => {
  return (dispatch, getState) => {
    let products = getState()
      .lookups.products.map(product => {
        product.checked = selected.indexOf(product.id) >= 0;
        return product;
      });
    replaceTableInDB("products", products);
    dispatch(fetchProductsSuccess(products));
    // dispatch(updateAlertSelection());
  };
};

export const checkStatus = selected => {
  return (dispatch, getState) => {
    let status = getState()
      .lookups.status.map(status => {
        status.checked = selected.indexOf(status.id) >= 0;
        return status;
      });
    replaceTableInDB("status", status);
    dispatch(fetchStatusSuccess(status));
    // dispatch(updateAlertSelection());
  };
};

export const checkIndicators = selected => {

  console.log('checkIndicators called!', selected);
  return (dispatch, getState) => {
    let indicators = getState()
      .lookups.indicators.map(indicator => {
        indicator.checked = selected.indexOf(indicator.id) >= 0;
        return indicator;
      });
    replaceTableInDB("indicators", indicators);
    dispatch(fetchIndicatorsSuccess(indicators));
    // dispatch(updateAlertSelection());
  };
};