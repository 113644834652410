import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import { updateApp } from "../../../registerServiceWorker";

const useStyles = makeStyles((theme) => ({
    
  }));
  
  export default function UpdateIndicator(props) {
    const classes = useStyles();

    const handleAppUpdate = (event) => {
        updateApp();
      };
      
    return (
        <Tooltip title="Update App">
            <IconButton color="inherit"
                    aria-label="update application"
                    edge="start"
                    onClick={handleAppUpdate}
            >
                <GetAppIcon />
            </IconButton>
        </Tooltip> 
    );
  }