import React from "react";
import moment from "moment";
import L from "leaflet";

import Status from "../../../containers/Status/Status";
import Indicator from "../../../containers/Indicator/Indicator"
import classes from "./properties.css";

import Grid from '@material-ui/core/Grid';
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const observationProperties = props => {
  const feature = L.geoJSON(props.observation)
    .getBounds()
    .getCenter();

  let class_name = null;

  if (props.observation.properties.hasOwnProperty("classification_name")) {
    class_name = (
      <div className={classes.propertyLayout}>
        <div className={classes.propertyKey}>
          Classification:
        </div>
        <div className={classes.propertyValue}>
        {props.observation.properties.classification_name}
        </div>
      </div>  
    )
  }

  let pro_attr;
  if (
    props.observation.properties.hasOwnProperty("product_attribution") &&
    props.observation.properties.product_attribution
  ) {
    let _product_attribution = props.observation.properties.product_attribution;
    if (!Array.isArray(_product_attribution)) {
      _product_attribution = Object.values(_product_attribution);
    }
    // Ensure alphabetical listing
    _product_attribution.sort((a, b) => (a.label > b.label ? 1 : -1));

    if (_product_attribution && _product_attribution.length > 0) {
      pro_attr = _product_attribution.map((item, index) => {
        return (
          <span key={index} align="left">
            <span className={classes.Name}>{item.label}: </span>
            <span>{item.value} {item.uom}</span>
          </span>
        );
      });
    }
  }

  const addlInfoShow = (props.observation.properties.hasOwnProperty("product_attribution") &&
  props.observation.properties.product_attribution) || props.observation.properties.notes

  return (
    <div className={classes.PropertiesPane}>
      <Table aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell style={{padding: "5px", borderBottomStyle:"none"}}>
              <Grid container spacing={1}>
              <Grid item xs={12}>
                  <Box
                    sx={{
                      padding: "1rem",
                      margin: "0.5rem",
                      paddingTop: "5px",
                      border: "1px solid rgba(0, 0, 0, 0.5)",
                      backgroundColor: "white"
                    }}
                  >

                    <div style={{ textAlign: "center", padding: '2px', backgroundColor: 'rgba(43, 57, 144, 0.1)', border:"1px", borderStyle:"solid", borderColor:"rgba(0, 0, 0, 0.5)"}}><strong>Identification</strong></div>

                    <div className={classes.propertyLayout}>
                      <div className={classes.propertyKey}>
                        Id:
                      </div>
                      <div className={classes.propertyValue}>
                        {props.observation.id}
                      </div>
                    </div>

                    <div className={classes.propertyLayout}>
                      <div className={classes.propertyKey}>
                      Project:
                      </div>
                      <div className={classes.propertyValue}>
                        {props.observation.properties.project_name}
                      </div>
                    </div>  
                    
                    <div className={classes.propertyLayout}>
                      <div className={classes.propertyKey}>
                      Product:
                      </div>
                      <div className={classes.propertyValue}>
                      {props.observation.properties.product_name}
                      </div>
                    </div>  

                    {props.observation.properties.asset_name ? (
                    
                      <div className={classes.propertyLayout}>
                        <div className={classes.propertyKey}>
                        Asset Name:
                        </div>
                        <div className={classes.propertyValue}>
                          {props.observation.properties.asset_name}
                        </div>
                      </div>
                    
                    ) : null}   
                  
                    {props.observation.properties.organization ? (
                    
                      <div className={classes.propertyLayout}>
                        <div className={classes.propertyKey}>
                        Organization:
                        </div>
                        <div className={classes.propertyValue}>
                          {props.observation.properties.organization}
                        </div>
                      </div>
                    
                    ) : null}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      padding: "1rem",
                      margin: "0.5rem",
                      paddingTop: "5px",
                      border: "1px solid rgba(0, 0, 0, 0.5)",
                      backgroundColor: "white"
                    }}
                  >

                    <div style={{ textAlign: "center", padding: '2px', backgroundColor: 'rgba(43, 57, 144, 0.1)', border:"1px", borderStyle:"solid", borderColor:"rgba(0, 0, 0, 0.5)"}}><strong>Assessments</strong></div>

                    <div className={classes.propertyLayout}>
                      <div className={classes.propertyKey}>
                        Status:
                      </div>
                      <div className={classes.propertyValue}>
                        <Status
                          observation={props.observation}
                          changedEvent={props.statusChangeHandler}
                          display={"dropdown"}
                        />
                      </div>
                    </div>

                    <div className={classes.propertyLayout}>
                      <div className={classes.propertyKey}>
                        Indicator:
                      </div>
                      <div className={classes.propertyValue}>
                        {props.observation.properties.indicator_name}
                      </div>
                    </div>

                    {class_name}
                    
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                <Box
                    sx={{
                      padding: "1rem",
                      margin: "0.5rem",
                      paddingTop: "5px",
                      border: "1px solid rgba(0, 0, 0, 0.5)",
                      backgroundColor: "white"
                    }}
                  >

                    <div style={{ textAlign: "center", padding: '5px', backgroundColor: 'rgba(43, 57, 144, 0.1)', border:"1px", borderStyle:"solid", borderColor:"rgba(0, 0, 0, 0.5)"}}><strong>Date & Location</strong></div>

                    <div className={classes.propertyLayout}>
                      <div className={classes.propertyKey}>
                      Date:
                      </div>
                      <div className={classes.propertyValue}>
                        {moment(props.observation.properties.obs_date).format("lll")}
                      </div>
                    </div>

                    <div className={classes.propertyLayout}>
                      <div className={classes.propertyKey}>
                      Lat/Long:
                      </div>
                      <div className={classes.propertyValue}>
                        {feature.lat.toFixed(4)}, {feature.lng.toFixed(4)}
                      </div>
                    </div>
                </Box>
                </Grid>
                {addlInfoShow ? 
                <Grid item xs={12}>
                  <Box
                    sx={{
                      padding: "1rem",
                      margin: "0.5rem",
                      paddingTop: "5px",
                      border: "1px solid rgba(0, 0, 0, 0.5)",
                      backgroundColor: "white"
                    }}
                  >
                    <div style={{ textAlign: "center", padding: '5px', backgroundColor: 'rgba(43, 57, 144, 0.1)', border:"1px", borderStyle:"solid", borderColor:"rgba(0, 0, 0, 0.5)"}}><strong>Additional Information</strong></div>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left" style={{ display: "grid", gridTemplateColumns: "50% 50%", gap: ".5rem", padding: "5px", borderBottomStyle:"none" }}>
                    
                            {pro_attr}
                            {props.observation.properties.notes ? (
                              <span><strong>Notes: </strong>{props.observation.properties.notes}
                              </span>
                            ) : null}
              
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    
                  </Box>
                </Grid> : null}
              </Grid>
            </TableCell>
          </TableRow>
          
        </TableBody>
      </Table>
    </div>
  );
};

export default observationProperties;
