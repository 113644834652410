import React from 'react';
import { processItem } from '../../store/processors';
import db from '../../store/db';

/**
 * 
 * @param {*} props - State and functions from redux: is_online, processSyncItems, goOnline 
 * @returns 
 */
export default function useSyncItems(sync_items, setSyncItems) {    

    const removeSyncItemFromState = (sync_item) => {
        const filtered_items = sync_items.filter(i => i.id !== sync_item.id);
        setSyncItems(filtered_items);
    }

    /**
     * Deletes the sync item from the sync items table.
     */
    const deleteSyncItem = (sync_item) => {
        if (!confirm('Are you sure you want to delete this item?')) return;

        // Removing the item from dexie
        db.table('syncitems').delete(sync_item.id);
        
        // Removing it from the state.
        removeSyncItemFromState(sync_item);
    };

    
    const deleteAllSyncItems = async () => {
        if (!confirm('Are you sure you want to delete all of the sync items?')) return;
        
        await db.table('syncitems').clear();

        setSyncItems([]);
    }   

    /**
     * Processes a single sync item.
     */
    const uploadSyncItem = async (sync_item) => {

        try {
            if (sync_item.type === 'MEDIA') {
                const post_data = new FormData();

                // Media creation is expecting form data for the request
                Object.keys(sync_item.data).forEach(property => {
                    post_data.append(property, sync_item.data[property]);
                })

                // Cloning the sync_item to prevent issues in other areas of state
                const cloned_sync_item = {...sync_item, data: post_data}; 

                const response = await processItem(cloned_sync_item.action, cloned_sync_item.type, cloned_sync_item);

                if (response.status === 'error') {
                    throw response.error;
                }

                if (response === undefined) {
                    throw { status: 'error', error: 'No response from API'}
                }
                removeSyncItemFromState(sync_item);
                return true;

            } else {
                  // Process the item via the API
            const response = await processItem(sync_item.action, sync_item.type, sync_item);

            // If an error occurred and was caught by the try/catch block in the redux processors
            if (response.status === 'error') {
                throw response.error
            }

            // General catchall in case there is no response
            if (response === undefined) {
                throw {
                    status: 'error',
                    error: 'No response from API'
                }
            }
            
            // Update the local store.
            removeSyncItemFromState(sync_item);

            return true
            }

          
        } catch (error) {
            console.log('%cUpdating sync item with error code', 'color: red', {error});
        
            // Update Dexie with the error 
            const updated_sync_item = {
                ...sync_item,
                error: true,
                message: error && error.data && error.data.message ? error.data.message : 'Something went wrong!',
            }


            // Update indexedDB
            db.table('syncitems').put(updated_sync_item); // update the sync item in dexie

            // Update local state
            downloadSyncItems();

            return false
        }

    };

    /**
     * Processes all of the the upload all sync items currently in the sync items table.
     */
    const uploadAllSyncItems = async () => {

        try {

            const failed_sync_items = [];

            for( const sync_item of sync_items) {
                const item_was_processed = await uploadSyncItem(sync_item);

                if (!item_was_processed) {
                    failed_sync_items.push(sync_item);
                }
            }

            const percentage_complete = ((failed_sync_items.length / sync_items.length) * 100).toFixed(2);

            return {error: false, message: `Uploaded ${percentage_complete}% of sync items.`};
        }
        catch (error) {
            console.log('Error Uploading sync items: ', error);
            return {error: true, message: error.toString()};
        }
    }

    /**
     * Creates / Modifies an "enabled" property on the sync item entry used when uploading the sync items. IE: Going online.
     */
    const toggleSyncItem = (item) => {
        const sync_item = sync_items.find(i => i.id === item.id);
        
        // Toggle the enabled status
        sync_item.enabled = sync_item.hasOwnProperty('enabled') ? !sync_item.enabled : true;

        // Update the react state
        const new_sync_items = sync_items.map(i => {
            return i.id === item.id ? sync_item : i;
        })
        setSyncItems(new_sync_items);

        // Update the dexie table
        db.table('syncitems').put(sync_item);
        console.log('item getting updated!', item)
    }

    /**
     * Queries indexeddb to fill the hooks internal sync_items table. 
     */
    const downloadSyncItems = () => {
        db.table('syncitems').toArray().then(items => {
            console.log('Sync Items: ', items);
            setSyncItems(items ? items : [])
        })
    };

    return {
        deleteSyncItem: deleteSyncItem,
        uploadSyncItem: uploadSyncItem,
        uploadAllSyncItems: uploadAllSyncItems,
        toggleSyncItem: toggleSyncItem,
        downloadSyncItems: downloadSyncItems,
        deleteAllSyncItems: deleteAllSyncItems,
    }
}

