import React from 'react';
import clsx from 'clsx';
import { fade, makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';

import SideDrawerList from "./SideDrawerList"

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({    
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
  },
}));

export default function SideDrawer(props) {
    const classes = useStyles();
    const theme = useTheme();
  
    return (
        
          <React.Fragment>
              <Hidden mdUp implementation="css">
              <Drawer
                  variant="temporary"
                  anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                  open={props.mobileOpen}
                  onClose={props.handleDrawerToggle}
                  classes={{
                  paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                  }}
              >
                  <SideDrawerList drawerWidth={drawerWidth} mobileOpen={props.mobileOpen} handleDrawerToggle={props.handleDrawerToggle} />
              </Drawer>
              </Hidden>
              <Hidden smDown implementation="css">
              <Drawer
                  className={clsx(classes.drawer, {
                      [classes.drawerOpen]: props.open,
                      [classes.drawerClose]: !props.open,
                  })}
                  classes={{
                      paper: clsx({
                      [classes.drawerOpen]: props.open,
                      [classes.drawerClose]: !props.open,
                      }),
                  }}
                  variant="permanent"
                  open
              >
                  <SideDrawerList drawerWidth={drawerWidth} mobileOpen={false} handleDrawerToggle={props.handleDrawerToggle} />
              </Drawer>
              </Hidden>
          </React.Fragment>
      
    );
}