import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const LogTab = ({logs}) => {
    
    /**
     * Renders a list of items that will be uploaded to see whats going on. 
     */
    return (<TableContainer style={{height: '80vh', padding:'5px'}}>
                <Table sx={{ minWidth: 650 }} aria-label="Sio Debug Table" stickyHeader >
                    <TableHead>
                        <TableRow>
                            {/* user,timestamp,data,level,message */}
                            <TableCell style={{padding:'2px', minWidth:'100px'}}>Timestamp</TableCell>
                            {/* <TableCell>Source</TableCell> */}
                            <TableCell style={{padding:'2px'}}>Message</TableCell>
                            {/* <TableCell>Data</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {logs.map((log, index) => (
                            <TableRow hover key={index} style={{backgroundColor: log.level === 'WARNING' ? '#ffc400' : ['ERROR', 'CRITCAL'].includes(log.level) ? '#f44336' : 'inherit', margin: 0, padding: 0}}>
                                <TableCell style={{padding:'2px', minWidth:'100px'}}>{(new Date(log.timestamp)).toLocaleString()}</TableCell>
                                {/* <TableCell>{log.source}</TableCell> */}
                                <TableCell style={{padding:'2px'}}>{log.message}</TableCell>
                                {/* <TableCell style={{fontSize: '.8rem'}}>{JSON.stringify(log.data)}</TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        
    )
}

export default LogTab;