import React, { Component } from "react";
import { connect } from "react-redux";
import Spinner from "../../UI/Spinner/Spinner";
import Input from "../../UI/Input/Input";
import Button from '@material-ui/core/Button';
import * as settings from "../../../constants/settings";
import * as appActions from "../../../store/actions/index";
import ImagePreview from "../../ImagePreview/ImagePreview";
import EXIF from 'exif-js';
import moment from 'moment'
import { withStyles } from "@material-ui/core/styles"

const styles = theme => ({
  mediapane: {
    width: "100%",
    textAlign: "center",
    padding: "1rem"
  },
  spinnerdiv: {
    position: "static"
  },
  imagecontainer: {
    position: "relative",
    width: "100%",
    height: "80%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(43, 57, 144, 0.25)"
  }
  
});


class NewMedia extends Component {
  state = {
    controls: {
      mediaFile: {
        elementType: "input",
        muiConfig: {
          label: "",
          fullWidth: true,
        },
        elementConfig: {
          type: "file",
          placeholder: "",
          accept: "image/*"
        },
        value: "",
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        visible: true,
      },
      comment: {
        elementType: "textarea",
        muiConfig: {
          label: "Comment:",
          fullWidth: true,
          multiline: true,
          rows: 5,
          placeholder: "Comment..."
        },
        elementConfig: {
          },
        value: "",
        validation: {
          maxLength: 10240
        },
        valid: false,
        touched: false,
        visible: this.props.visible ? true : false,
      }
    },
    selectedFile: null,
    uploading: false
  };

  checkValidity(value, rules) {
    let isValid = true;
    if (!rules) {
      return true;
    }

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid;
    }

    if (rules.isEmail) {
      const pattern =
        /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
      isValid = pattern.test(value) && isValid;
    }

    if (rules.isNumeric) {
      const pattern = /^\d+$/;
      isValid = pattern.test(value) && isValid;
    }

    return isValid;
  }

  getEXIFCaptureDate = (file) => {

    let str = EXIF.getTag(file, "DateTimeOriginal");  
    
    if (!str) {
      // Apple device does not have this tag set 
      // when picture loaded from camera roll
      // compared to using a photo in the gallery.
      // Andoid does not have this problem
      str = moment().format("YYYY:MM:DD HH:mm:ss")
    }

    str = str.split(" ")
    const dateStr = str[0].replace(/:/g, "-");
    const properDateStr = dateStr + " " + str[1];
  
    return moment(properDateStr).toISOString();
  }

  getEXIFData = (file) => {
    EXIF.getData(file, () => {
      file.captureDate = this.getEXIFCaptureDate(file);
      this.setFileState(file)
    })
  }

  setFileState = (file) => {
    this.setState({
      selectedFile: file
    });
  }

  inputChangedHandler = (event, controlName) => {
    if (controlName === "mediaFile") {
      let file = event.target.files[0];
      // Need to build a new file object to ensure we can set a unique filename

      if (file) {
        if (file.name == 'image.jpg'){
          const _file = new File([file], "image-" + ("0000" + String(Math.floor((Math.random()*1000) + 1))).slice(-4) + ".jpg",{type: file.type})  
          file = _file;
        }
        
        if (file.size > settings.FILE_UPLOAD_LIMIT) {
          // TODO: (#166676832) Need to update the UI
          console.log("File is too large.");
          event.target.value = "";
          file = null;
        } else {
          if (['jpg','jpeg','tiff'].includes((file.name.split(".").pop()).toLowerCase())){
            this.getEXIFData(file)          
          } else {
            file.captureDate = moment().toISOString();
            this.setFileState(file);
          }    
        }
      }
    }

    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        valid: this.checkValidity(
          event.target.value,
          this.state.controls[controlName].validation
        ),
        touched: true
      }
    };

    this.setState({
      controls: updatedControls
    });
  };

  submitHandler = event => {
    event.preventDefault();
    
    if (this.state.selectedFile) {
      
      this.setState({
        uploading: true
      });

      const {
        observation,
        comment,
        onAddMedia,
        onRemoveComment,
        onFetchComments,
        cancel
      } = this.props;
      
      const stamp = new Date();
      const postData = new FormData();
      postData.append("id", stamp.getTime());
      postData.append("observation", observation.id);
      postData.append("capture_date", this.state.selectedFile.captureDate);
      postData.append("file", this.state.selectedFile, this.state.selectedFile.name);
      
      if (this.state.controls.comment.value.length > 0) {
        postData.append("comment", this.state.controls.comment.value);
      } else if (comment) {
        postData.append("comment", comment.comment);
      }
      
      onAddMedia(postData)
        .then(response => {
          // console.log(
          //   "UI media creation response:",
          //   response.message ? response.message : response
          // );
          
          if (comment) {

            onRemoveComment(comment)
              .then(data => {
                onFetchComments(observation.id);
                cancel('NewMedia');
              });

          } else {
            onFetchComments(observation.id);
            cancel('NewMedia');
          }
          // });
        });
    }
  };

  imageChangedHandler = changedImage => {
   
    const {name, captureDate} = this.state.selectedFile

    // Use the existing blob source and 
    // add properties to appear as a File object
    // given that MS Edge early versions do not
    // support FIle API
    
    changedImage.name = name.substr(0, name.lastIndexOf(".")) + ".png";
    changedImage.captureDate = captureDate
  
    this.setState({
      selectedFile: changedImage
    });
  }

  render() {
    const formElementsArray = [];
    for (let key in this.state.controls) {
      if (this.state.controls[key].visible) formElementsArray.push({
        id: key,
        config: this.state.controls[key]
      });
    }
    const spinner = this.state.uploading ? (
      <div className={this.props.classes.spinnerdiv}>
        Loading photo....
        <Spinner />
      </div>
    ) : null;

    let form = formElementsArray.map(formElement => (
      <Input
        key={formElement.id}
        elementType={formElement.config.elementType}
        muiConfig={formElement.config.muiConfig}
        elementConfig={formElement.config.elementConfig}
        value={formElement.config.value}
        invalid={!formElement.config.valid}
        shouldValidate={formElement.config.validation}
        touched={formElement.config.touched}
        changed={event => this.inputChangedHandler(event, formElement.id)}
      />
    ));
    const img = this.state.selectedFile ?
      <ImagePreview file={this.state.selectedFile} dataURL={this.imageChangedHandler}/> : null;
    return (
      <div className={this.props.classes.mediapane}>
        {spinner}
      {!this.state.uploading ? (
          <React.Fragment>
          <div className={this.props.classes.imagecontainer}>
            {img}
          </div>
          <form onSubmit={this.submitHandler}>
            {form}
            <div>
            <Button
              disabled = {!this.state.selectedFile}
              variant="contained"
              type="submit"
              color="primary"
            >
              Save
            </Button>
            
            <Button 
              variant="contained" 
              color="default" 
              onClick={this.props.cancel}
            >
              Cancel
            </Button>
            </div>
          </form>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    onAddMedia: media => dispatch(appActions.addMedia(media)),
    onFetchComments: itemId => dispatch(appActions.fetchComments(itemId)),
    onUpdateComment: comment => dispatch(appActions.updateComment(comment)),
    onRemoveComment: item => dispatch(appActions.removeComment(item)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NewMedia));
