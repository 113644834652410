import React from "react";

import classes from "./Legend.css";
import { renderLegend } from "../../modules/Map/map";

const legend = props => {
  let _legend;
  if (props.legend instanceof Array && props.legend[0].hasOwnProperty("stop")) {
    _legend = props.legend.map(item => {
      if (!item.hidden) {
        return (
          <div key={item.stop} className={classes.Entry}>
            <div className={classes.Box} style={{ background: item.color }} />
            <div className={classes.Label}>{item.label}</div>
          </div>
        );
      } else {
        return null;
      }
    });
  } else if (props.legend.hasOwnProperty("geomtype")) {
    // Reference Layer
    const output = renderLegend(props.legend);

    _legend = <div className={classes.Entry}>{output}</div>;
  }
  return <div className={classes.LegendPane}>{_legend}</div>;
};

export default legend;
