import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import React, {
  Component
} from "react";
import {
  withStyles
} from "@material-ui/core/styles";
import classes from "./photos.css";
import Photo from "./Photo/photo";

import {
  Delete,
  Comment
} from "@material-ui/icons";
import ImagePreview from '../../../components/ImagePreview/ImagePreview';

const styles = theme => ({
  root: {
    padding: 0
  }
});

class Photos extends Component {
  state = {
    selectedMedia: this.props.media.length > 0 ? this.props.media[0] : null,
    commentVisible: false,
    message: '',
    open: false,
    comment_text: '',
  };

  componentDidMount() {
    this.setState({
      comment_text: this.state.selectedMedia ? this.cleanComment(this.state.selectedMedia) :'',
    });
  }

  componentDidUpdate(prevProps) {
     if (prevProps.media !== this.props.media) {
      if (this.props.media.length > 0) {
        this.setState({
          selectedMedia: this.props.media[0]
        });
      } else {
        this.setState({
          selectedMedia: null
        });
      }
     }
  }

  cleanComment = media => {
    let comment_text = media.comment_text;
    const pos = comment_text ? comment_text.indexOf(' (Photo)'): -1;
    if (pos !== -1) comment_text = comment_text.slice(0,pos);
    return comment_text;
  }

  changePhotoHandler = id => {
    const source = this.props.media.find(item => {
      return item.id === id;
    });
    if (source) {
      this.setState({
        selectedMedia: source ,
        comment_text: this.cleanComment(source),
      });
    }
  };

  removePhotoHandler = mediaEntry => {
    this.props.onDelete(mediaEntry);
  };

  toggleCommentHandler = () => {
    this.setState(prevState => ({
      commentVisible: !prevState.commentVisible
    }));
  };

  editComment = event => {
    event.preventDefault();
    event.stopPropagation();
    const comment_text = event.target.value;
    this.setState({
      comment_text,
    });
  }

  saveCommnet = event => {
    //this is not true clone. todo
    if (event.key === 'Enter') {
      // const payload = JSON.parse(JSON.stringify(this.state.selectedMedia));
      // the post requires file object exists on the payload
      // either we modify that code, or we just copy in the selectedMedia
      const {comment_text, selectedMedia} = this.state;
      const payload = selectedMedia;
      //preserve comment id if exists for offline updating
      payload.comment_text = payload.comment;
      payload.comment = comment_text;
      //we need to somehow allow update the image in the future
      // for example, rotate the images
      if ('file' in payload) delete payload.file;
      let message = '';
      if (selectedMedia.comment) {
        if (comment_text.length === 0)
          message = 'comment successfully deleted';
        else message = 'comment successfully updated';
      } else message = 'comment successfully created';
      console.log(payload);
      this.props.onUpdate(payload)
        .then(data => {
          this.props.onReloadCommentsMedia(message);
        });
    }
  }

  render() {
    const {selectedMedia, commentVisible, comment_text} = this.state;
    const media = this.props.media.map(item => {
      return (
        <Photo key={item.id} media={item} clicked={this.changePhotoHandler} />
      );
    });

    const actionIcons = selectedMedia ? (
      <React.Fragment>
        <div className={classes.CommentIcon}>
        {selectedMedia.comment ? (
            <IconButton
          className={this.props.classes.root}
          color="inherit"
          onClick={this.toggleCommentHandler}
            >
            <Comment />
            </IconButton>
        ) : <IconButton
         className={this.props.classes.root}
         color="primary"
         onClick={this.toggleCommentHandler}
         >
         <Comment />
         </IconButton> }
      </div>
        <div className={classes.TrashIcon}>
        <IconButton
      className={this.props.classes.root}
      color="inherit"
      onClick={() => this.removePhotoHandler(this.state.selectedMedia)}
        >
        <Delete />
        </IconButton>
        </div>
        </React.Fragment>
    ) : null;

    const comment =
      selectedMedia && commentVisible ? (
        <div className={classes.Comment}>
              <input type='text' value={comment_text}
onChange={this.editComment} onKeyPress={this.saveCommnet} style={{display:"table-cell", width:"100%"}} />
        </div>
      ) : null;
    const img = selectedMedia ?
      <ImagePreview  clsName={classes.Image} imgURL={selectedMedia.download_url} /> :
      null;
    return (
      <React.Fragment>

        <div className={classes.MediaPane}>
          <div className={classes.ImageContainer}>
            {img}
            {comment}
            {actionIcons}
          </div>
          <div className={classes.ListContainer}>{media}</div>
        </div>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(Photos);
