import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    title: {
        display: 'none',
        [theme.breakpoints.up('xs')]: {
        display: 'block',
        },
    },
  }));
  
  export default function Title(props) {
    const classes = useStyles();
      
    return (
        <Typography className={classes.title} variant="h6" noWrap>
            Satelytics OTG
          </Typography>
    );
  }