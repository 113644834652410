import React from "react";
import moment from "moment";

import classes from "./photo.css";

const photo = props => {
  let size = props.media.file_size,
    sizeUnit = "B";
  if (props.media.file_size / 1024 < 1024) {
    sizeUnit = "KB";
    size = props.media.file_size / 1024;
  } else if (props.media.file_size / 1048576 < 1024) {
    sizeUnit = "MB";
    size = props.media.file_size / 1048576;
  }

  return (
    <div
      className={classes.Media}
      onClick={() => props.clicked(props.media.id)}
    >
      <img src={props.media.thumbnail_url} height="65px" width="65px" />
    </div>
    // <div className={classes.Media}>
    //   <div>
    //     <img src={props.media.thumbnail_url} height="100px" width="100px" />
    //   </div>

    //   <div className={classes.subHeader}>
    //     {" "}
    //     {moment(props.media.created).format("lll")}{" "}
    //   </div>
    //   <div className={classes.subHeader}>
    //     Size: {size.toFixed(2)} {sizeUnit}{" "}
    //   </div>
    //   <div className={classes.subHeader}> {props.media.username} </div>
    // </div>
  );
};

export default photo;
