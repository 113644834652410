import axios from "axios";

const instance = axios.create({
  baseURL: "https://auth.satelytics.io/auth",
  headers: {},
  withCredentials: true
});

// instance.interceptors.request.use(
//   request => {
//     console.log("Auth Instance inteceptor", request);
//     // Update headers
//     return request;
//   },
//   error => {
//     // Pass on in case local component needs to
//     // do anything special
//     return Promise.reject(error);
//   }
// );
// instance.interceptors.response.use(
//   response => {
//     console.log("Auth Instance inteceptor", response);
//     return response;
//   },
//   error => {
//     // Pass on in case local component needs to
//     // do anything special
//     return Promise.reject(error);
//   }
// );

export default instance;
