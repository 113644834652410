import React, {useState, useEffect} from 'react';
import store from '../../store';
import { getClients, changeClient, fusionAuthLogout } from '../Auth/fusion-auth-service';
import {Select, MenuItem, FormControl, InputLabel,} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MUIButton from '../../components/UI/Button/MUIButton';

const useStyles = makeStyles(theme => ({
    offset: {paddingLeft: '1rem'},
    heading: {
        marginTop: '1rem',
        width: "100%",
        fontSize: "1.35em",
    }, 
    formControlSelect: {
        margin: '1rem 0',
        minWidth: 200,
        display: 'block',
    },
    select: {
        minWidth: 200,
    },
    formControlButton: {
        margin: '1rem 0',
        display: 'block',
    },
}))

export default function AccountContainer () {
    const classes = useStyles();
    const { account: original_client_account_id, userType, token, userId: users_fusion_auth_id } = store.getState().auth;
    const [client_accounts, setClientAccounts] = useState([]);
    const [selected_client, setSelectedClient] = useState(original_client_account_id);
    const can_change_client = selected_client !== original_client_account_id;
    const active_client_name = client_accounts.length === 0 
        ? '' 
        :client_accounts.find(client => client.id === original_client_account_id).name;

    useEffect(() => {
        if (client_accounts.length > 0) return;

        debug('Account Container Mounted', {
            auth: store.getState().auth
        })

        getClientList();
    }, []);

    const debug = (message, data = {} ) => {
        console.log(`%c[AccountContainer] ${message}`, 'color: #BADA55', {...data})
    }


    async function getClientList() {
        const client_response = await getClients(token);
        setClientAccounts(client_response.data);
    }

    const onChangeHandler = (event) => {
        setSelectedClient(event.target.value);
    }

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        const original_client = client_accounts.find(item => item.id === original_client_account_id);
        const new_client = client_accounts.find(item => item.id === selected_client);

        debug('changeAccount', {original_client, new_client})

        if (!original_client || !new_client) return;
        
        const confirmation_message = `Are you sure you want to change your account from ${original_client.name} to ${new_client.name}?`;

        if (!confirm(confirmation_message)) return;

        await changeClient({
            token,
            fromTenant: original_client_account_id,
            toTenant: selected_client,
            id: users_fusion_auth_id
        })

        setTimeout(() => {
            fusionAuthLogout(token);
        }, 2000)
    }

    // Preventing non internal users from seeing this page
    if (userType !== 'I' ) {
        return <>
            <h1>Unauthorized</h1>
        </>
    }

    return <>
       <div className={`${classes.heading} ${classes.offset}`}>
          Change Account
        </div>

        <p className={classes.offset}>You're currently on:  <strong>{active_client_name}</strong></p>

        <form autoComplete="off" className={classes.offset} onSubmit={onSubmitHandler}>

            <FormControl className={classes.formControlSelect}>
                <InputLabel>Change Your Account</InputLabel>
                <Select value={selected_client} onChange={onChangeHandler} className={classes.select}>
                    {client_accounts.map((item, id) => (
                        <MenuItem key={id} value={item.id}>{item.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl className={classes.formControlButton}>
                <MUIButton 
                    clicked={onSubmitHandler}
                    disabled={!can_change_client}
                    label="Change Account"
                    color="primary"
                />
            </FormControl>
        </form>
    </>
}