import React from "react";

import classes from "./BatchDownload.css";
import MUIButton from "../../UI/Button/MUIButton";
import Button from '@material-ui/core/Button';

const batchDownload = props => {
  return (
    <div className={classes.BatchDownload}>
      <p>Download Estimate:</p>
      <br />
      <p>
        File Size: {(props.downloadPackage.totalSizeEstimate / 1024).toFixed(1)}{" "}
        MB
      </p>
      <p>Basemap Tiles: {props.downloadPackage.bmCount}</p>
      <p>Natural Color Tiles: {props.downloadPackage.ncCount}</p>
      <p>Analysis Tiles: {props.downloadPackage.sbCount}</p>
      <p>Reference Tiles: {props.downloadPackage.refCount}</p>
      <br />
      <p>
        It is recommended that you are on a WiFi connection and power connected
        before starting this download.
      </p>
      <br />
      <p>
        You may continue to use this application while the download is in
        progress, but closing this application will cause this download to stop.
      </p>
      <div className={classes.Center}>
        {/* <MUIButton clicked={props.continue} label="Continue" color="primary" /> */}
        {/* <MUIButton clicked={props.cancel} label="Cancel" color="secondary" /> */}
        <Button onClick={props.continue} variant="contained" color="primary" >Continue</Button>
        <Button onClick={props.cancel} variant="contained" color="default">Cancel</Button>
      </div>
    </div>
  );
};

export default batchDownload;
