import React, {
  Component,
  useState,
  useEffect
} from "react";

import classes from "./TabItems.css";
import TabItem from "./TabItem/TabItem";
import * as contentViews from "../../../constants/contentViews";

const tabitems = ({
  changeView,
  currentTab
}) => {
  const [selected, setSelected] = useState(currentTab);
  useEffect(()=>setSelected(currentTab), [currentTab]);
  const clickHandler = option => {
    setSelected(option);
    changeView(option);
  };
  return (
    <div className={classes.TabItems}>
        <TabItem
          name="Map"
          active={
            selected === contentViews.DETAIL_VIEW_MAP ? true : false
          }
          clicked={() => clickHandler(contentViews.DETAIL_VIEW_MAP)}
        />
        <TabItem
          name="Properties"
          active={
            selected === contentViews.DETAIL_VIEW_PROPERTIES
              ? true
              : false
          }
          clicked={() => clickHandler(contentViews.DETAIL_VIEW_PROPERTIES)}
        />
        <TabItem
          name="Comments"
          active={
            selected === contentViews.DETAIL_VIEW_COMMENTS
              ? true
              : false
          }
          clicked={() => clickHandler(contentViews.DETAIL_VIEW_COMMENTS)}
        />
        <TabItem
          name="Photos"
          active={
            selected === contentViews.DETAIL_VIEW_PHOTOS
              ? true
              : false
          }
          clicked={() => clickHandler(contentViews.DETAIL_VIEW_PHOTOS)}
        />
      </div>
  );
}

export default tabitems;
