import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const  TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`debug-tabpanel-${index}`}
        aria-labelledby={`debug-tab-${index}`}
        {...other}
        >
            {value === index && (
            <Box component={'div'} p={0}>
                <Typography component={'span'} p={0}>{children}</Typography>
            </Box>
        )}
        </div>
    );
}

export default TabPanel;