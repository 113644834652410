import db from "../db";
import axios_api from "../../api/axios-api";
import { removeSyncTask } from "./utils";
import { renderData } from "../actions/alerts";
import SioLogger from "../../modules/Logger/logger";

const logger = SioLogger('processors/comments-media.js');

export const updateObservation = source => {
  return patchObservation(source.data ? source.data : source).then(response => {
    if (source.data) {
      removeSyncTask(source);
      logger.info('Updated Observation (' + source.data.id + ') to Status(' + source.data.status +')');
    }
    if (!response.error) {
      if (response.constructor === Array) {
        const responseList = [];

        response.forEach(item => {
          
          // Only send back successful results
          if (item.status === 200) {
            db.table("alerts").put(item.body);
            responseList.push(item.body);
          } else {
            logger.error(
              "Server Error for : " +
              item.id + 
              item.status + 
              item.message
            );
          }
        });
        return responseList;
      } else {
        // Deprecate this once the API returns the data
        // with the correct property names
        backportProperties(response);
        db.table("alerts").put(response);
      }
    }
    return response;
  });
};

const backportProperties = (record) => {
  // Temp solution until API endpoint returns
  // properties with new names
  const remapFields = ['asset', 'classification', 'diff_result', 'obs_result', 'package', 'product', 'project', 'prv_result', 'prv_source', 'source', 'status', 'indicator']
  Object.keys(record.properties).forEach(prop => {
    if (remapFields.includes(prop)) {
      record.properties[prop+"_id"] = record.properties[prop]
      delete record.properties[prop]
    } 
  })
  // Need to also rehydrate the record
  renderData(record);
}

const patchObservation = data => {
  if (data.constructor === Array) {
    return axios_api.patch("/v2/observations", data).then(response => {
      return response.data;
    });
  } else {
    return axios_api
      .patch("/v2/observations/" + data.id, data)
      .then(response => {
        return response.data;
      });
  }
};
