import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  root: {
    width: "100%",
    padding: ".4rem",
    margin: "auto"
  }
});

const input = props => {
  let inputElement = null;

  switch (props.elementType) {
    case "input":
      inputElement = (
        <TextField className={props.classes.root}
          inputProps={props.elementConfig}
          {...props.muiConfig}
          value={props.value}
          onChange={props.changed}
          variant="outlined"
        />
      );
      break;
    case "textarea":
      inputElement = (
        <TextField className={props.classes.root}
          inputProps={props.elementConfig}
          {...props.muiConfig}
          value={props.value}
          onChange={props.changed}
          variant="outlined"
        />
      );
      break;
    case "file":
      inputElement = (
        <TextField className={props.classes.root}
          inputProps={props.elementConfig}
          {...props.muiConfig}
          value={props.value}
          onChange={props.changed}
          variant="outlined"
        />
      );
      break;
    default:
      inputElement = (
        <TextField
          inputProps={props.elementConfig}
          {...props.muiConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
  }

  return (
    <React.Fragment>
      {inputElement}
    </React.Fragment>
  );
};

export default withStyles(styles)(input);
