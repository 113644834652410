import React from "react";
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import moment from "moment";
// import classes from "./Header.css";
import * as Actions from "../../../store/actions/index";
import L from "leaflet";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import GroupWork from "@material-ui/icons/GroupWork";
import Status from "../../../containers/Status/Status";
import Indicator from "../../../containers/Indicator/Indicator";

const styles = theme => ({
  header: {
    height: "3rem",
    backgroundColor: "rgba(0, 0, 0, 0)",
    flexDirection: "row",
    flexWrap: "nowrap",
    display: "flex",
    alignItems: "center",
  },
  icon: {
    display: "flex",
    alignItems: "center",
  },
  main: {
    width: "100%",
    padding: "5px",
    flexDirection: "column",
    display: "flex",
  },
  product: {
    width: "100%",
  },
  alignRight: {
    textAlign: "right",
  },
  subHeader: {
    fontSize: "x-small",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  }
  
});

const Header = props => {
  const {selected_alert} = props;
  const geom = props.observation
    ? L.geoJSON(props.observation)
        .getBounds()
        .getCenter()
    : null;

  return (
    <div className={props.classes.header}>
      <div className={props.classes.icon}>
        <KeyboardArrowLeft onClick={props.closeDetails} fontSize="large" />
      </div>
      <div className={props.classes.icon}>
        <Indicator observation={props.observation} display={"symbol"} />
      </div>

      <div className={props.classes.main}>
        <div className={props.classes.product}>
          <strong>{props.observation.properties.product_name}</strong>
        </div>
        <div className={props.classes.subHeader}>
          <div><strong>ID:</strong>{props.observation.id}</div>
          <div>
            <strong>Date:</strong>
            {moment(props.observation.properties.obs_date).format("ll")}
          </div>
          <div>
            <strong>Lat/Lng: </strong>{geom ? geom.lat.toFixed(4) : 0} /{" "}
            {geom ? geom.lng.toFixed(4) : 0}
          </div>
        </div>
      </div>

      <div className={props.classes.icon + " " + props.classes.alignRight}>
        <Status
          observation={props.selected_alert}
          changedEvent={props.statusChangeHandler}
          display={"symbol"}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    selected_alert: state.alerts.selectedAlert,
  };
}

const mapDispatchToProps = (dispatch) => ({});

const HeaderWithState = connect(mapStateToProps, mapDispatchToProps)(Header);

export default withStyles(styles)(HeaderWithState);

