export const UPDATE_AVAILABLE = "UPDATE_AVAILABLE";
export const APP_ONLINE = "APP_ONLINE";
export const APP_OFFLINE = "APP_OFFLINE";
export const APP_ISLOADING = "APP_ISLOADING";
export const APP_TOGGLE_THEME_MODE = "APP_TOGGLE_THEME_MODE";
export const UPDATE_GPS_SETTINGS = "UPDATE_GPS_SETTINGS";

export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";

export const FETCH_LOOKUPS_START = "FETCH_LOOKUPS_START";
export const FETCH_LOOKUPS_FAILED = "FETCH_LOOKUPS_FAILED";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_INDICATORS_SUCCESS = "FETCH_INDICATORS_SUCCESS";
export const FETCH_STATUS_SUCCESS = "FETCH_STATUS_SUCCESS";
export const FETCH_SYSTEM_DEFAULTS_SUCCESS = "FETCH_SYSTEM_DEFAULTS_SUCCESS";
export const FETCH_PACKAGES_SUCCESS = "FETCH_PACKAGES_SUCCESS";
export const FETCH_CLASSIFICATIONS_SUCCESS = "FETCH_CLASSIFICATIONS_SUCCESS";
export const FETCH_SOURCES_SUCCESS = "FETCH_SOURCES_SUCCESS";
export const FETCH_ASSETS_SUCCESS = "FETCH_ASSETS_SUCCESS";

export const FETCH_ALERTS_START = "FETCH_ALERTS_START";
export const FETCH_ALERTS_SUCCESS = "FETCH_ALERTS_SUCCESS";
export const FETCH_ALERTS_FAILED = "FETCH_ALERTS_FAILED";
export const SAVE_ALERT_SUCCESS = "SAVE_ALERT_SUCCESS";
export const SELECT_ALERT_SUCCESS = "SELECT_ALERT_SUCCESS";

export const FETCH_COMMENTS_SUCCESS = "FETCH_COMMENTS_SUCCESS";
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
export const SAVE_COMMENT_SUCCESS = "SAVE_COMMENT_SUCCESS";
export const REMOVE_COMMENT_SUCCESS = "REMOVE_COMMENT_SUCCESS";

export const MANAGE_MEDIA_START = "MANAGE_MEDIA_START";
export const FETCH_MEDIA_SUCCESS = "FETCH_MEDIA_SUCCESS";
export const ADD_MEDIA_SUCCESS = "ADD_MEDIA_SUCCESS";
export const SAVE_MEDIA_SUCCESS = "SAVE_MEDIA_SUCCESS";
export const REMOVE_MEDIA_SUCCESS = "REMOVE_MEDIA_SUCCESS";

export const SET_MAIN_MAP = "SET_MAIN_MAP";
export const TILE_GENERATION_START = "TILE_GENERATION_START";
export const TILE_GENERATION_SUCCESS = "TILE_GENERATION_SUCCESS";
export const TILE_DOWNLOAD_READY = "TILE_DOWNLOAD_READY";
export const TILE_DOWNLOAD_START = "TILE_DOWNLOAD_START";
export const TILE_DOWNLOAD_SUCCESS = "TILE_DOWNLOAD_SUCCESS";
export const TILE_DOWNLOAD_CANCEL = "TILE_DOWNLOAD_CANCEL";

export const FETCH_PROJECTS_START = "FETCH_PROJECTS_START";
export const FETCH_PROJECTS_SUCCESS = "FETCH_PROJECTS_SUCCESS";
export const FETCH_PROJECTS_FAILED = "FETCH_PROJECTS_FAILED";

export const CHANGE_FILTERS = "CHANGE_FILTERS";
export const UPDATE_MAP_ZOOM_CENTER = "UPDATE_MAP_ZOOM_CENTER";
export const UPDATE_TIME_FILTER = "UPDATE_TIME_FILTER";
export const UPDATE_CURRENT_VIEW = "UPDATE_CURRENT_VIEW";

export const FETCH_REF_LAYERS_START = "FETCH_REF_LAYERS_START";
export const FETCH_REF_LAYERS_SUCCESS = "FETCH_REF_LAYERS_SUCCESS";
export const FETCH_REF_LAYERS_FAILED = "FETCH_REF_LAYERS_FAILED";

export const FETCH_BASE_LAYERS_START = "FETCH_BASE_LAYERS_START";
export const FETCH_BASE_LAYERS_SUCCESS = "FETCH_BASE_LAYERS_SUCCESS";
export const FETCH_BASE_LAYERS_FAILED = "FETCH_BASE_LAYERS_FAILED";

export const DEBUG_ON = "DEBUG_MODE_ON";
export const DEBUG_OFF = "DEBUG_MODE_OFF";
