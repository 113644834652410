import { createSelector } from "reselect"


const getToken = state => state.auth.token;
const getOnline = state => state.app.online;
const getswUpdated = state => state.app.swUpdated;
const getDarkMode = state => state.app.darkMode;
const getLoading = state => state.app.isLoading;
const getLoadCount = state => state.app.loadCount;
const getLoadTotal = state => state.app.loadTotal;

export const isAuthenticated = createSelector(
    getToken,
    token => (token !== null)
)

export const isOnline = createSelector(
    getOnline,
    online => (online)
)

export const isSoftwareUpdated = createSelector(
    getswUpdated,
    swUpdated => (swUpdated)
)

export const isDarkMode = createSelector(
    getDarkMode,
    darkMode => (darkMode)
)

export const isLoading = createSelector(
    getLoading,
    loading => (loading)
)

export const getProgress = createSelector(
    [getLoadCount, getLoadTotal],
    (loadCount, loadTotal) => ((loadCount/loadTotal)*100)
)