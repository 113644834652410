import axios from "axios";
import axios_api from "../../api/axios-api";
import db from "../db";
import * as requestResults from "../../constants/requestResults";

const LOG_LEVEL = {
  DEBUG: 'DEBUG',
  INFO: 'INFO',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
  CRITICAL: 'CRITICAL',
};

// { source, user, timestamp, data, level, message }
const storeLog = (level, message, data) => {
  switch (level) {
    case LOG_LEVEL.INFO: console.info(`[Shared Worker] ${message}`);
    break;
    case LOG_LEVEL.WARNING: console.warn(`[Shared Worker] ${message}`);
    break;
    case LOG_LEVEL.ERROR: console.error(`[Shared Worker] ${message}`);
    break;
    case LOG_LEVEL.CRITICAL: console.error(`[Shared Worker] ${message}`);
    break;
    default: console.log(`[Shared Worker] ${message}`);
  }
  
  db.table('logs').add({
    source: 'store/processors/shared-workers.js',
    user: 'system',
    timestamp: (new Date).toISOString(),
    data: data,
    level: level,
    message: message
  }).catch((err) => {
    // respond(400, 'ERROR_STORING_LOG', err)
    console.error('[Shared Workers] Error storing log', err, log)
  })
}

export const getCommentsFromServer = (
    obsid,
    props = {
      cancelToken: null,
      silent: false,
      header: null
    }
  ) => {
    const opt = {
      cancelToken: props.cancelToken,
    }
    if (props.header){
      opt.headers = {'Authorization' : props.header}
    }
    return axios_api
      .get("/v2/observations/" + obsid + "/comments", opt)
      .then(response => {
        db.table("comments")
          .where("observation")
          .equals(obsid)
          .delete()
          .then(deleteCount => {
            if (deleteCount > 0 ) {
              storeLog(LOG_LEVEL.INFO, "Removing " + deleteCount + " records from comments table in local DB.");
            }
            if (response.data.length > 0 ) {
              storeLog(LOG_LEVEL.INFO, "Adding " + response.data.length + " records to the comments table in the local DB.");
            }
            db.table("comments")
              .bulkAdd(response.data);
          });
  
        if (props.silent) {
          return "";
        } else {
          return response.data;
        }
      })
      .catch(fetchError => {
        if (axios.isCancel(fetchError)) {
          storeLog(LOG_LEVEL.WARNING, 'Getting comments from server was cancelled.');
          return requestResults.REQUEST_CANCELED;
        } else {
          if (fetchError.response && fetchError.response.status === 404) {
            storeLog(LOG_LEVEL.ERROR, 'Received 404 when getting Comments from Server.', fetchError);
            return requestResults.REQUEST_NOT_FOUND;
          } else {
            storeLog(LOG_LEVEL.ERROR, 'Getting Comments from Server has failed.', fetchError);
            return requestResults.REQUEST_ERROR;
          }
        }
      });
  };
  
  export const getMediaFromServer = (
    obsid,
    props = {
      cancelToken: null,
      silent: false,
      header: null
    }
  ) => {
    const opt = {
      cancelToken: props.cancelToken,
    }
    if (props.header){
      opt.headers = {'Authorization' : props.header}
    }
    return axios_api
      .get("/v2/observations/" + obsid + "/media",opt)
      .then(response => {
        db.table("media")
          .where("observation")
          .equals(obsid)
          .delete()
          .then(deleteCount => {
            if (deleteCount > 0 ) {
              storeLog(LOG_LEVEL.INFO, "Removing " + deleteCount + " records from comments table in local DB.");
            }
            if (response.data.length > 0 ) {
              storeLog(LOG_LEVEL.INFO, "Adding " + response.data.length + " records to the comments table in the local DB.");
            }
            db.table("media")
              .bulkAdd(response.data);
          });
  
        if (props.silent) {
          // Part of the batch process so be sure to
          // add the images to the cache
          if (response.data.length > 0) {
            caches.open("uploaded-media")
              .then(cache => {
                response.data.forEach(item => {
                  storeLog(LOG_LEVEL.ERROR, "Adding photo to cache:" + item.download_url)
                  cache.add(item.thumbnail_url);
                  cache.add(item.download_url);
                });
              });
          }
          return "";
        } else {
          return response.data;
        }
      })
      .catch(fetchError => {
        if (axios.isCancel(fetchError)) {
          storeLog(LOG_LEVEL.WARNING, 'Getting photo from server was cancelled.');
          return requestResults.REQUEST_CANCELED;
        } else {
          if (fetchError.response && fetchError.response.status === 404) {
            storeLog(LOG_LEVEL.ERROR, 'Getting photo from server recieved a 404.', fetchError);
            return requestResults.REQUEST_NOT_FOUND;
          } else {
            storeLog(LOG_LEVEL.ERROR, 'Getting photo from server failed.', fetchError);
            return requestResults.REQUEST_ERROR;
          }
        }
      });
  };
  