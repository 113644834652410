import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import makeStyles from '@material-ui/core/styles/makeStyles';
import db from '../../store/db';
import LogTab from './LogTab';
import SyncItemsTab from './SyncItemsTab';
import TabPanel from './TabPanel';
import useSyncItems from './useSyncItems';
import useTelemetry from './useTelemetry';
import store from '../../store';


const useStyles = makeStyles(theme => ({
    green_button: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.success.light
        }
    }
}));

export default function Debug() {

    const classes = useStyles();
    const is_offline = !store.getState().app.online;
    const [currentTab, setCurrentTab] = useState(1);
    const [upload_message, setUploadMessage] = useState('');
    const [message_severity,  setMessageSeverity] = useState('success');
    const [logs, setLogs] = useState([]);
    const [sync_items, setSyncItems] = useState([]);
    const {uploadAllSyncItems, deleteAllSyncItems} = useSyncItems(sync_items, setSyncItems);
    const is_on_log_tab = currentTab === 0;
    const button_settings = {
        size: 'small',
        variant: 'contained'
    }    
    const {transmitSyncItems,transmitLogs} = useTelemetry();

    const getLogsFromDexie = () => {
        db.table('logs').orderBy('timestamp').desc().toArray().then(data => {
            setLogs(data)
        });
    }

    // Loading the data stored in the syncitems table in dexie
    useEffect(() => {
        getLogsFromDexie()
    }, []);

    /**
     * State setter for the tab panels
     */
    const handleChange = (event, value) => {
        setCurrentTab(value);
    }

    const transmitTelemetry = async () => {

        const logs = await db.table('logs').orderBy('timestamp').desc().toArray();
        await transmitLogs(logs);

        const sync_items = await db.table('syncitems').toArray();
        await transmitSyncItems(sync_items);

        setMessageSeverity('info');
        setUploadMessage('Transmitted diagnostics to SIO.')
    }

    /**
     * Clears out all of the logs in the table.
     */
    const purgeLogs = () => {
        if( !confirm('Do you want to delete all of the diagnostic data?')) return;

        db.table('logs').clear().then(() => {
            setLogs([])
        }).catch(err => {
            console.error('Error deleting logs', err);
        })
    }

    const uploadSyncItems = async () => {
        const result = await uploadAllSyncItems();

        setMessageSeverity(result.error === true ? 'error' : 'info');
        setUploadMessage(result.message);
    }

    return <div>
        {/* Snackbar for messages for transmitting and uploading. */}
        <Snackbar open={upload_message !== ''} autoHideDuration={6000} onClose={() => setUploadMessage('')}>
            <MuiAlert elevation={6} variant="filled" onClose={() => setUploadMessage('')} severity={message_severity} >{upload_message}</MuiAlert>
        </Snackbar>

        <AppBar position="relative">
            {/* Tab Headers */}
            <Tabs value={currentTab} onChange={handleChange} aria-label="Debugging Tabs">
                <Tab label="Logs" id='debug-tab-1' aria-controls='debug-tabpanel-1' />
                <Tab label="Sync Items" id='debug-tab-2' aria-controls='debug-tabpanel-2' />                
            </Tabs>

            {/* General button events */}
            <div style={{margin: '10px 10px 10px auto', display: 'flex', gap: '5px'}} >
                <Button {...button_settings} color='primary' onClick={transmitTelemetry} disabled={is_offline}>
                    Transmit Diagnostics
                </Button>
                {is_on_log_tab
                    ? <Button {...button_settings} onClick={purgeLogs} color='secondary'>
                        Purge Logs
                        </Button>
                    : <>
                        <Button {...button_settings}color='secondary' onClick={deleteAllSyncItems} >
                            Delete All
                        </Button>
                        <Button {...button_settings} onClick={uploadSyncItems} className={classes.green_button} disabled={is_offline}>
                            Upload All
                        </Button>
                    </>
                }
            </div>

            {/* Tab Panels */}
            <TabPanel value={currentTab} index={0}>
                <LogTab logs={logs}/>
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
                <SyncItemsTab sync_items={sync_items} setSyncItems={setSyncItems}/>
            </TabPanel>
        </AppBar>
    </div>

}