import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const styles = theme => ({
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing(3)
  },
  group: {
    margin: `${theme.spacing}px 0`
  }
});

class RadioButtonsGroup extends React.Component {
  state = {
    values: this.props.domainValues,
    selectedValue: this.props.defaultValue
  };

  changeHandler = event => {
    this.setState({ selectedValue: event.target.value });
    if (this.props.emitEvent) {
      this.props.emitEvent(event);
    }
  };

  render() {
    const { classes, formLabel = "", groupName = "rgb1" } = this.props;
    const items = this.state.values.map(item => {
      return (
        <FormControlLabel
          key={item.id}
          value={item.id.toString()}
          control={<Radio />}
          label={item.label}
        />
      );
    });
    return (
      <div className={classes.root}>
        <FormControl className={classes.formControl}>
          <FormLabel>{formLabel}</FormLabel>
          <RadioGroup
            name={groupName}
            className={classes.group}
            value={this.state.selectedValue}
            onChange={this.changeHandler}
          >
            {items}
          </RadioGroup>
        </FormControl>
      </div>
    );
  }
}

RadioButtonsGroup.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RadioButtonsGroup);
