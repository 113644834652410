import React, {
  Component
} from "react";
import {
  connect
} from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import * as contentViews from "../../../constants/contentViews";
import * as appActions from "../../../store/actions/index";
// import classes from "./Content.css";

import Modal from "../../UI/Modal/Modal";
import TabItems from "../TabItems/TabItems";
import ActionbarItems from "../../Navigation/Actionbar/ActionbarItems/ActionbarItems";
import Comments from "../Comments/comments";
import NewComment from "../../Dialogs/NewComment/NewComment";
import NewMedia from "../../Dialogs/NewMedia/NewMedia";
import Photos from "../Photos/photos";
import ObservationProperties from "../Properties/properties";
import Map2 from "../../Map/Map2";
import Confirmation from "../../Dialogs/Confirmation/Confirmation";
import Notification from '../../UI/Notification/Notification';
import SioLogger from "../../../modules/Logger/logger";

const logger = SioLogger("containers/alertContainer.js");

const styles = theme => ({
  content: {
    height: `calc(100% - 3rem)`,
    display:"flex",
    flexDirection:"column",
  }
});
class Content extends Component {
  componentDidMount() {
    // Get the Comment and Media entries for this observation
    // this is to enclose this into the reloadCommentsMedia function
    // so that the children component can call it without the issue of
    // conflicting this
    this.reloadCommentsMedia(this)();
  }

  componentWillUnmount() {
    if (this.state.map){
      this.state.map.stopLocate();
    }
  }

  reloadCommentsMedia(self) {
    return (message) => {
      const {
        onFetchComments,
        onFetchMedia,
        observation
      } = self.props;
      Promise.all([onFetchComments(observation.id), onFetchMedia(observation
          .id)])
        .then(() => {
          if (message) {
            console.log(message);
            self.setState({
              open: true,
              message,
            });
          }
        });
    };
  }

  state = {
    selectedView: contentViews.DETAIL_VIEW_MAP,
    map: null,
    dialogVisible: false,
    commentDialogVisible: false,
    mediaDialogVisible: false,
    layerControlDialogVisible: false,
    confirmationDialogVisible: false,
    confirmationTarget: null,
    confirmationType: null,
    showComment: true,
    comment: null,
    open: false,
    message: '',
    watching: false,
    locationFound: false,
    locationError: false,
    locationErrorMessage: ""
  };

  onClose(self) {
    return () => {
      self.setState({
        open: false
      });
    }
  }

  // Simply hide the map so that it does not need to be recreated each
  // time the user switches back to the map.  The other components
  // can be recreated as needed

  changeViewHandler = option => {
    this.setState({
      selectedView: option
    });
  };

  addCommentHandler = () => {
    this.setState({
      dialogVisible: true,
      commentDialogVisible: true
    });
  };

  addMediaHandler = () => {
    this.setState({
      dialogVisible: true,
      mediaDialogVisible: true
    });
  };

  toggleLayerControlHandler = () => {
    this.setState( (prev) => ( {
      dialogVisible: false,
      layerControlDialogVisible: !prev.layerControlDialogVisible
    } ));
  };

  mapHandler = map => {
    this.setState({
      map: map
    });
  };

  locateMeHandler = () => {
    if (this.state.map) {
      // Check if actively watching location
      if (this.state.watching) {
        logger.info("Locate Me turned off.");
        this.state.map.stopLocate();
        this.setState({ watching: false });
      } else {
        logger.info("Locate Me turned on.");
        this.state.map.on("locationfound", () => {
          this.setState({
            locationErrorMessage: "",
            locationFound: true
          });
          setTimeout(() => {
            this.setState({
              locationFound: false
            });
          }, 200);
        });
        this.state.map.on("locationerror", e => {
          this.setState({
            locationError: true,
            locationErrorMessage: e.message
          });
          setTimeout(() => {
            this.setState({
              locationError: false
            });
          }, 200);
        });
        this.state.map.locate({
          setView: true,
          watch: this.props.gps.watch,
          enableHighAccuracy: this.props.gps.highAccuracy
        });
        this.setState({ watching: this.props.gps.watch });
      }
    }
  };
  
  modalCancelHandler = event => {
    let selectedView = this.state.selectedView;
    if (event === 'NewMedia') selectedView=contentViews.DETAIL_VIEW_PHOTOS;
    if (event === 'NewComment') selectedView=contentViews.DETAIL_VIEW_COMMENTS;
    this.setState({
      dialogVisible: false,
      commentDialogVisible: false,
      mediaDialogVisible: false,
      showComment: true,
      selectedView,
    });
  };

  confirmationHandler = result => {
    const {
      onRemoveComment,
      onFetchMedia,
      onRemoveMedia,
      observation,
      comments,
    } = this.props;
    const {
      confirmationTarget,
      confirmationType,
    } = this.state;
    if (result) {
      if (confirmationType === "COMMENT") {
        onRemoveComment(confirmationTarget);
        // This is a temp fix - does not work in offline mode
        if (confirmationTarget.has_media) {
          onFetchMedia(observation.id);
        }
        //
      }
      if (confirmationType === "MEDIA") {
        onRemoveMedia(confirmationTarget);
        if (confirmationTarget.comment) {
          const cmt = comments.find(item => {
            return item.id === confirmationTarget.comment;
          });
          if (cmt) {
            onRemoveComment(cmt);
          }
        }
      }
    }
    this.setState({
      dialogVisible: false,
      confirmationDialogVisible: false,
      confirmationTarget: null,
      confirmationType: null
    });
  };

  addMediaHandlerNoComment = (item) => {
    this.setState({
      dialogVisible: true,
      mediaDialogVisible: true,
      showComment: false,
      comment: item,
    });
  };

  // addMediaHandlerNoComment = item => {
  //     this.setState({
  //       dialogVisible: true,
  //       confirmationDialogVisible: true,
  //       confirmationTarget: item,
  //       confirmationType: "MEDIA",
  // //      showComment: false,
  //     });
  //   };
  removeCommentHandler = item => {
    this.setState({
      dialogVisible: true,
      confirmationDialogVisible: true,
      confirmationTarget: item,
      confirmationType: "COMMENT"
    });
  }
  removeMediaHandler = item => {
    this.setState({
      dialogVisible: true,
      confirmationDialogVisible: true,
      confirmationTarget: item,
      confirmationType: "MEDIA"
    });
  }
  render() {
    let pane;
    const {
      observation,
      bookmarks,
      statusChangeHandler,
      comments,
      media,
      onUpdateMedia,
      onUpdateComment,
      closeDetails,
    } = this.props;
    const {
      selectedView,
      dialogVisible,
      commentDialogVisible,
      mediaDialogVisible,
      showComment,
      confirmationDialogVisible,
      comment,
      confirmationType,
      layerControlDialogVisible,
      open,
      message,
    } = this.state;
    switch (selectedView) {
      case contentViews.DETAIL_VIEW_PROPERTIES:
        pane = (
          <ObservationProperties
            observation={observation}
            statusChangeHandler={statusChangeHandler}
          />
        );
        break;
      case contentViews.DETAIL_VIEW_COMMENTS:
        pane = (
          <Comments
          comments={comments}
          media={media}
          onDelete={this.removeCommentHandler}
          onAddMedia={ this.addMediaHandlerNoComment }
          onUpdateComment={onUpdateComment}
          onReloadCommentsMedia={this.reloadCommentsMedia(this)}
          />
        );
        break;
      case contentViews.DETAIL_VIEW_PHOTOS:
        pane = (
          <Photos onReloadCommentsMedia={ this.reloadCommentsMedia(this) } onUpdate={onUpdateMedia} media={media} onDelete={this.removeMediaHandler} />
        );
        break;
      default:
    }

    return (
      <div className={this.props.classes.content}>
        <Modal
          show={dialogVisible}
          modalClosed={this.modalCancelHandler}
        >
          {commentDialogVisible ? (
            <NewComment
              observation={observation}
              cancel={this.modalCancelHandler}
            />
          ) : null}
          {mediaDialogVisible ? ( showComment ? (
            <NewMedia
              observation={observation}
            cancel={this.modalCancelHandler}
            visible={true}
            />
          ) :( <NewMedia
           observation={observation}
           cancel={this.modalCancelHandler}
               comment={comment}
           /> ) ):null}
          {confirmationDialogVisible ? (
            <Confirmation
            message={confirmationType === "COMMENT" ? "Do you want to remove this comment?":"Do you want to remove this photo?"}
              close={this.confirmationHandler}
            />
          ) : null}
        </Modal>

        <TabItems changeView={this.changeViewHandler} currentTab = {selectedView} />

        <Map2
          id={"DetailMap"}
          observation={observation}
          bookmarks={bookmarks}
          detail={true}
          showLayerControl={layerControlDialogVisible}
          toggleLayerControl={this.toggleLayerControlHandler}
          isVisible={selectedView === contentViews.DETAIL_VIEW_MAP}
          mapInitialized={this.mapHandler}
        />

        {pane}
      { open && <Notification message={message} close={this.onClose(this)} /> }
        <ActionbarItems
          currentView={selectedView}
          closeDetails={closeDetails}
          addComment={this.addCommentHandler}
          addMedia={this.addMediaHandler}
          layerControl={this.toggleLayerControlHandler}
          locateMe={this.locateMeHandler}
          watching={this.state.watching}
          locationErrorMessage={this.state.locationErrorMessage}
          locationFound={this.state.locationFound}
          locationError={this.state.locationError}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    comments: state.comment_media.comments.filter(
      item => item.observation === ownProps.observation.id
    ),
    media: state.comment_media.media.filter(
      item => item.observation === ownProps.observation.id
    ),
    gps: state.app.gps
  };
};

const mapDisptachToProps = dispatch => {
  return {
    onFetchMedia: itemId => dispatch(appActions.fetchMedia(itemId)),
    onFetchComments: itemId => dispatch(appActions.fetchComments(itemId)),
    onRemoveComment: item => dispatch(appActions.removeComment(item)),
    onRemoveMedia: item => dispatch(appActions.removeMedia(item)),
    onUpdateMedia: item => dispatch(appActions.updateMedia(item)),
    onUpdateComment: item => dispatch(appActions.updateComment(item)),

  };
};
export default connect(
  mapStateToProps,
  mapDisptachToProps
)(withStyles(styles)(Content));
