import Dexie from "dexie";

const db = new Dexie("SatelyticsDB");

db.version(1).stores({
  alerts: "id,properties.project,properties.product,properties.status",
  tiles: "url,layername,type,timestamp",
  products: "id",
  indicators: "id",
  status: "id",
  projects: "id",
  comments: "id,observation",
  media: "id,observation,comment",
  baselayers: "id",
  reflayers: "id",
  renderers: "id",
  syncitems: "id,action,type,timestamp"
});

db.version(2).stores({
  systemdefaults: "id"
});

db.version(3).stores({
  reference_layers: "url"
});

db.version(4).stores({
  logs: "++,level,timestamp"
})

db.version(5).stores({
  alerts: "id,properties.project_id,properties.product_id,properties.status_id,properties.indicator_id",
  packages: "id",
  classifications: "id",
  sources: "id",
  assets: "id",
})

db.version(6).stores({
  pmtiles: "url"
})

db.open();
export default db;
