import React, {
  useState,
  useRef,
  useEffect
} from "react";
import IconButton from "@material-ui/core/IconButton";
import {
  withStyles
} from '@material-ui/core/styles';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';

const styles = theme => ({
  scanInProgressText: {
    position: 'absolute',
    top: '3rem',
    left: '50%',
    transform: 'translateX(-50%)'
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  buttonLeft: {
    position: 'absolute',
    left: '0.5rem',
    top: '0.5rem',
  },
  buttonRight: {
    position: 'absolute',
    right: '0.5rem',
    top: '0.5rem',
  }
});

function ImagePreview(props) {
  const {
    file,
    dataURL,
    imgURL,
    classes,
  } = props;
  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);
  const url = imgURL ? imgURL : URL.createObjectURL(file);
  const canvasRef = useRef(null);
  const imgRef = useRef(null);
  const image = new Image();
  image.src = url;

  useEffect(() => {
    setRotation(0);
    setScale(1.0);
  }, [imgURL, file]);
  
  const drawImageScaled = (canvas, rot) => {

    let canvasWidth = image.width;
    let canvasHeight = image.height;

    if (image.width > 2000 || image.height > 2000) {
      if (image.width > image.height) {
        canvasWidth = 2000;
        canvasHeight = image.height / image.width * 2000;
      }
      if (image.width < image.height) {
        canvasHeight = 2000;
        canvasWidth = image.width / image.height * 2000;
      }
    }
    
    if (rot % 180 !== 0) {
      canvas.height = canvasWidth;
      canvas.width = canvasHeight;
    } else {
      canvas.height = canvasHeight;
      canvas.width = canvasWidth;
    }
    
    const ctx = canvas.getContext("2d");

    ctx.save();
    
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    
    ctx.translate(canvas.width/2, canvas.height/2);
    
    ctx.rotate(rot * Math.PI/180);
    
    ctx.drawImage(image, 0, 0, image.width, image.height, -canvasWidth/2,
      -canvasHeight/2, canvasWidth, canvasHeight);
    
    ctx.restore();
    
    if (dataURL && typeof(dataURL) === 'function') {
      canvas.toBlob(newImageBlob => {
        dataURL(newImageBlob);
      }, "image/png");
    }
  };
  const rotateImage = direction => () => {
  
    let rotAngle = 90;
    if (direction === 'left') rotAngle = -90;
    let rot = rotation + rotAngle;
    
    rot = rot >= 360 ? 0 : rot;
    rot = rot <= -360 ? 0 : rot;
    setRotation(rot);
    
    const cHeight = imgRef.current.parentElement.clientHeight;
    const cWidth = imgRef.current.parentElement.clientWidth;
    const imageRatio = image.height / image.width;
    const rotatedClientRatio = cWidth / cHeight;
    
    if (rot % 180 !== 0) {
      setScale(Math.min(imageRatio, rotatedClientRatio));
    }
    else setScale(1);
    
    if (file) {
      const canvas = canvasRef.current;
      drawImageScaled(canvas, rot);
    }
  };

  const canvas = file ?
    <canvas id="canvas" width='450' height='300' ref={canvasRef} style={{display:'none'}}></canvas> :
    null;
  return (
    <>
      {url === '/assets/scanprocessing.jpeg' && 
        <p className={classes.scanInProgressText}>This file is being scanned. Please check back later.</p>}
      <img ref={imgRef} className={classes.image} src={url} style={{transform:'rotate('+rotation+'deg) scale('+scale+')' }} />
      {canvas}
      <IconButton onClick={rotateImage("left")} id="rotateLeft" className={classes.buttonLeft} ><RotateLeftIcon/></IconButton>
      <IconButton onClick={rotateImage("right")} id="rotateRight" className={classes.buttonRight} ><RotateRightIcon/></IconButton>
    </>
  );
}
export default withStyles(styles)( ImagePreview );
