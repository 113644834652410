import * as modelTypes from "../modelTypes";
import * as modelActions from "../modelActions";
import db from "../db";
import { processItem } from "./index";
import { saveAlertSuccess } from "../actions/alerts";
import {
  saveCommentSuccess,
  addCommentSuccess,
  removeCommentSuccess,
  saveMediaSuccess,
  addMediaSuccess,
  removeMediaSuccess
} from "../actions/comments-media";
import { formDataToJson } from "../utility";
import SioLogger from "../../modules/Logger/logger";

const logger = SioLogger('processors/sync.js');

export const storeActionForSync = (action, sourceType, data) => {
  const stamp = new Date();
  if (data instanceof FormData) {
    data = formDataToJson(data);
  }

  db.table("syncitems").add({
    id: stamp.getTime(),
    action: action,
    type: sourceType,
    timestamp: stamp,
    data: data
  });
};

export const syncUpdates = () => {
  return async dispatch => {
    return db.table("syncitems")
            .toArray()
            .then(async items => {
              if (items.length > 0) {
                for (const item of items) {

                  if (item.hasOwnProperty('enabled') && !item.enabled) continue;

                  if (item.type === modelTypes.MEDIA) {
                    if (item.data.file instanceof File){
                      logger.info('Processing the image: ' + item.data.file.name);
                    }

                    const postData = new FormData();
                    Object.keys(item.data).forEach(property => {
                      postData.append(property, item.data[property]);
                    });
                    item.data = postData;
                  }

                  if( item.data.hasOwnProperty('comment')) {
                    logger.info('Processing the comment: ' + item.data.comment);
                  }
                  else if( item.type === 'OBSERVATION') {
                    logger.info('Running ' + item.action + ' on record: ' + item.data.id)
                  }

                  try {
                    await processItem(item.action, item.type, item).then(response => {
                      if (response) {
                        dispatch(updateStore(item, response));
                      }
                    });
                  } catch (error) {
                    console.log('%cUpdating sync item with error code', 'color: red', {error, message: error.data.message});
                    // Update the sync item entry to include an error message.
                    const updated_sync_item = {
                      ...item,
                      error: true,
                      message: error && error.data && error.data.message ? error.data.message : 'Something went wrong!',
                    }

                    db.table('syncitems').put(updated_sync_item)
                  }
                  
                }
              } else {
                logger.info('Did not have records to synchronize');
              }
            });
  };
};

const updateStore = (item, response) => {
  return dispatch => {
    switch (item.type) {
      case modelTypes.OBSERVATION:
        dispatch(saveAlertSuccess(response));
        break;
      case modelTypes.COMMENT:
        switch (item.action) {
          case modelActions.CREATE:
            dispatch(removeCommentSuccess(item));
            dispatch(addCommentSuccess(response));
            break;
          case modelActions.UPDATE:
            dispatch(saveCommentSuccess(response));
            break;
          case modelActions.DELETE:
            dispatch(removeCommentSuccess(item.data));
            break;
          default:
        }
        break;
      case modelTypes.MEDIA:
        switch (item.action) {
          case modelActions.CREATE:
            dispatch(removeMediaSuccess(item));
            dispatch(addMediaSuccess(response));
            break;
          case modelActions.UPDATE:
            dispatch(saveMediaSuccess(response));
            break;
          case modelActions.DELETE:
            dispatch(removeMediaSuccess(item.data));
            break;
          default:
        }
        break;
      default:
    }
  };
};
