import * as actionTypes from "./actionTypes";
import axiosAuth from "../../api/axios-auth";
import Cookies from 'js-cookie'
import { updateDefaultTilesURL, updateDefaultApiURL } from "../../api/utility";
import { decodeJWT } from "../utility";
import {
  initializeObservations,
  fetchLookups,
  fetchProjects,
  fetchBaseLayers,
  fetchReferenceLayers,
  updateGPSSettings,
  appOffline,
  appOnline
} from "./index";
import {
  removeSessionItems,
  setSessionItems,
  getToken,
  getExpirationDate,
  getGPSSettings,
  getOfflineMode
} from "../localStorage/index";
import SioLogger from "../../modules/Logger/logger";
import { isUsingFusionAuth, fusionAuthLogout } from "../../containers/Auth/fusion-auth-service";

const logger = SioLogger('src/store/actions/auth.js');

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = ({ token, userId, userName, userType, account, appId }) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userId: userId,
    userType: userType,
    userName: userName,
    account: account,
    appId: appId
  };
};

export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};

export const logoutStart = () => {
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

const djangoLogout = (token) => {
  const config = {
    headers: {
      Authorization: "JWT " + token,
      "Content-Type": "application/json"
    }
  };

  axiosAuth.post("/logout", {}, config).catch(error => {
    if (error.message === "Network Error") {
      logger.error('Encountered a network error during login.');
    } else if (error.response.status === 401) {
      // token had expired before issuing logout request
      logger.warn('Token has expired during logout process.');
    } else {
      logger.error('Error issuing logout process: ' + error.message, error);
    }
  });
}


export const logout = token => {
  console.log("Logging out...")
  removeSessionItems();

  // Send logout request to server for tracking purposes
  // and we don't care about the response
  return dispatch => {
    dispatch(logoutStart());
    dispatch(setAuthRedirectPath("/"));
    

    if (isUsingFusionAuth()) {
      console.log("Issue fusionauth log out")
      fusionAuthLogout(token);
    } else {
      console.log("Issue django log out")
      djangoLogout(token);
    }
  };
};

export const checkAuthTimeout = (expirationTime, token) => {
  return dispatch => {
    setTimeout(() => {
      dispatch(authCheckState());
    }, expirationTime * 1000);
  };
};

const updateDefaultURLs = (account, token) => {
  updateDefaultTilesURL(account, token);
  updateDefaultApiURL(account, token);
};

const authenticatedHandler = (dispatch, response) => {
  const payload = decodeJWT(response.data.token);
  const expirationDate = new Date(payload.exp * 1000);
  setSessionItems({
    token: response.data.token
  });
  updateDefaultURLs(payload.tid, response.data.token);
  dispatch(fetchLookups()).then(() => {
  });
  dispatch(fetchProjects());
  dispatch(fetchBaseLayers());
  dispatch(fetchReferenceLayers());
  dispatch(
    authSuccess({
      token: response.data.token,
      userId: payload.sub,
      userName: payload.firstName + " " + payload.lastName,
      userType: payload.user_type,
      account: payload.tid,
      appId: null
    })
  );
  dispatch(
    checkAuthTimeout(
      (expirationDate.getTime() - new Date().getTime()) / 1000,
      response.data.token
    )
  );
}

export const authenticate = (email, password) => {
  return dispatch => {
    dispatch(authStart());
    const authData = {
      email: email,
      password: password
    };

    return axiosAuth
      .post("/login", authData)
      .then(response => {
        if (response.data.hasOwnProperty("ephemeral_token")){
          // MFA enabled
          return response.data;
        }
        else if (response.data.hasOwnProperty('token')){
          authenticatedHandler(dispatch, response);
          return response.data;
        }
        
      })
      .catch(err => {
        if (err.response && err.response.data) {
          logger.error('Authentication failed: ' + err.response.data.message, err)
        }
        dispatch(authFail(err.response ? err.response.data : err));
      });
  };
};

export const authenticateConfirm = (token, code) => {
  return dispatch => {
    
    return axiosAuth
      .post("/login/code", {ephemeral_token:token, code:code})
      .then(response => {
          authenticatedHandler(dispatch, response);
      })
      .catch(err => {
        if (err.response && err.response.data) {
          logger.warn('2FA failed: ' + err.response.data.message, err);
        }
        dispatch(authFail(err.response ? err.response.data : err));
        throw err;
      });
  };
};

export const setAuthRedirectPath = path => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path
  };
};

const djangoCheckState = (dispatch) => {
  console.log("Checking FusionAuth Authentication state...")
  const token = getToken();
  if (!token) {
    dispatch(logoutStart());
  } else {
    const expirationDate = getExpirationDate();
    if (expirationDate <= new Date()) {
      if (!getOfflineMode()){
        // Do not log them out if still offline
        dispatch(logout(token));
      }
    } else {
      const payload = decodeJWT(token);
      updateDefaultURLs(payload.tid, token);
      
      dispatch(updateGPSSettings((getGPSSettings()).gps));
      if (getOfflineMode()){
        dispatch(appOffline())
      } else {
        dispatch(appOnline())
        dispatch(fetchLookups())
        // .then(() => {
          // dispatch(initializeObservations());  

        // });
        dispatch(fetchProjects());
        dispatch(fetchBaseLayers());
        dispatch(fetchReferenceLayers());
      }
      
      dispatch(
        authSuccess({
          token: token,
          userId: payload.sub,
          userName: payload.firstName + " " + payload.lastName,
          userType: payload.user_type,
          account: payload.tid,
          appId: null
        })
      );
      if (!getOfflineMode()){
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000,
            token
          )
        );
      }
    }
  }
}

const fusionAuthCheckState = (dispatch) => {
  console.log("Checking FusionAuth Authentication state...")
  const token = Cookies.get('otg.idt');
  if (!token) {
    console.log('No token found, "logging out"');
    return dispatch(logoutStart());
  }
  // console.log("token to be decoded", token)
  const decoded_token = decodeJWT(token);
  const expirationDate = new Date(decoded_token.exp * 1000);
  
  if (expirationDate <= new Date()) {
    // Do not log them out if still offline
    if (!getOfflineMode()){
      console.log('Token was expired, "logging user out"\n', {decoded_token: decoded_token, expirationDate})
      return dispatch(logout(token));
    }
    
  }
  console.log("Still running...")
  updateDefaultURLs(decoded_token.tid, token);
  dispatch(updateGPSSettings((getGPSSettings()).gps));

  if (getOfflineMode()){
    dispatch(appOffline())
  } else {
    dispatch(appOnline())
    dispatch(fetchLookups())
    dispatch(fetchProjects());
    dispatch(fetchBaseLayers());
    dispatch(fetchReferenceLayers());
  }

  dispatch(
    authSuccess({
      token: token,
      userId: decoded_token.sub,
      userName: decoded_token.firstName + " " + decoded_token.lastName,
      userType: decoded_token.user_type,
      account: decoded_token.tid,
      appId: decoded_token.aud
    })
  );
  
  if (!getOfflineMode()){
    dispatch(
      checkAuthTimeout(
        (expirationDate.getTime() - new Date().getTime()) / 1000,
        token
      )
    );
  }
  
}

export const authCheckState = () => {
  console.log("Running auth check state...")
  if (isUsingFusionAuth()) { 
    console.log("Using FusionAuth...")
    return fusionAuthCheckState;
  }
  console.log("Using Django...")
  return djangoCheckState;
};

export const authTokenExpired = () => {
  console.log("Checking if token has expired...")
  let token;
  if (isUsingFusionAuth()) { 
    token = Cookies.get('otg.idt');
  } else {
    token = getToken();
  }
  if (!token) {
    return true;
  }
  const decoded_token = decodeJWT(token);
  const expirationDate = new Date(decoded_token.exp * 1000);

  return (expirationDate <= new Date()) 
}