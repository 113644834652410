import React, {
  Component
} from "react";
import Grid from '@material-ui/core/Grid';
import {
  connect
} from "react-redux";
import {
  withStyles
} from '@material-ui/core/styles';
import * as Actions from "../../store/actions/index";
import CheckBoxList from "../CheckBoxList/CheckBoxList";
import TimeSeriesChart from "../TimeSeriesChart/TimeSeriesChart";
import moment from 'moment';
import IconButton from "@material-ui/core/IconButton";
import Restore from "@material-ui/icons/Restore";

import {
  getDateRangeSelection
} from "../../store/localStorage/index";

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: 5
  },
  button: {
    padding: 0,
    marginLeft: 0
  }
});

class Filters extends Component {
  constructor(props) {
    super(props);
    this.selectionChange = this.selectionChange.bind(this);
  }

  // componentDidMount() {
  //   const {
  //     projects,
  //     products,
  //     status,
  //     indicators,
  //     dataCount,
  //     onFetchData,
  //     onFetchLookups,
  //     onFetchBaseLayers,
  //     onFetchRefLayers
  //   } =
  //   this.props;

  //   if (projects.length === 0 || products.length === 0 || status.length ===
  //     0 ||
  //     indicators.length === 0 || dataCount.length === 0) {

  //     console.log("Fetching a bunch of data...");
  //     // onFetchData();
  //     // onFetchLookups();
  //     // onFetchBaseLayers();
  //     // onFetchRefLayers();
  //   }
  // }

  shouldComponentUpdate(nextProps, nextState) {
    if ((nextProps.projects.length) && (nextProps.products.length) && (
        nextProps.status.length) && (nextProps.indicators.length)) {
      return true;
    } else {
      return false;
    }
  }

  getUniqueProductsByProjects(selection) {
    let projects = this.props.projects;
    let products = [];
    projects.filter(project => selection.indexOf(project.id) >= 0)
      .forEach(project => {
        products = products.concat(project.products);
      });
    products = products.filter((value, index) => products.indexOf(value) ===
      index);
    return products;

  }

  selectionChange(previousCheckedIDs, updatedCheckedIDs, element) {

    switch (element) {
      case 'Projects':
     
        this.props.checkProjects(updatedCheckedIDs);

        let removedProjectIDs = previousCheckedIDs.filter(val =>
          updatedCheckedIDs
          .indexOf(val) < 0);

        if (removedProjectIDs.length > 0) {
          let remainingProjectIDs = previousCheckedIDs.filter(val =>
            updatedCheckedIDs
            .indexOf(val) >= 0);

          let remainingProducts =
            this.getUniqueProductsByProjects(remainingProjectIDs) || [];

          this.props.checkProducts(remainingProducts);

          let proposedRemovalProducts =
            this.getUniqueProductsByProjects(removedProjectIDs);

          let previousProducts = this.getUniqueProductsByProjects(
            previousCheckedIDs);

        }

        let addedProjectIDs = updatedCheckedIDs.filter(val =>
          previousCheckedIDs
          .indexOf(val) < 0);

        if (addedProjectIDs.length > 0) {
          let proposedAdditionalProducts = this.getUniqueProductsByProjects(
            addedProjectIDs);

          let existingProducts =
            this.getUniqueProductsByProjects(previousCheckedIDs);

          const expandedProducts = [...existingProducts];
          proposedAdditionalProducts.forEach(val => {
            if (existingProducts.indexOf(val) < 0) expandedProducts
              .push(val);
          });

          this.props.checkProducts(expandedProducts);

        }
        break;
      case 'Products': 
        this.props.checkProducts(updatedCheckedIDs);
        break;
      case 'Status':   
        this.props.checkStatus(updatedCheckedIDs);
        break;
      case 'Indicators':
        this.props.checkIndicators(updatedCheckedIDs);
        break;
      default:
        break;
    }

  }

  timeFilterChange = (start, end) => {
    this.props.updateTimeFilter({
      start: start,
      end: end
    });
  }

  resetTimeFilterHandler = () => {
    let DRS = getDateRangeSelection();
    this.timeFilterChange(moment(DRS.startDate)
      .subtract(1, 'week')
      .valueOf(), moment(DRS.endDate)
      .add(1, 'week')
      .valueOf());
  }

  render() {

    const {
      classes,
      projects,
      products,
      indicators,
      status,
      dataCount,
      filteredCount,
      timeFilter
    } = this.props;


    // const sorted_statuses = status.filter(item => item.enabled).sort((a,b) => a.state_order > b.state_order)

    return (
      
      <Grid container className = {classes.root} >
        <Grid container item xs = {12}  alignItems="center">
          <Grid item xs={11}>
            <TimeSeriesChart chartData={dataCount} timeFilter={timeFilter} onTimeFilterChange ={this.timeFilterChange}/>
          </Grid>
          <Grid container item xs={1} direction="column">
            <Grid container item xs={12} justifyContent="center" >
              {filteredCount}
            </Grid>
            <Grid container item xs={12} justifyContent="center" >
              <IconButton className={this.props.classes.button} color="inherit" onClick={this.resetTimeFilterHandler}><Restore fontSize="large"/></IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs = {12} lg = {3} >
          <CheckBoxList  items={projects} title="Projects" onChange={this.selectionChange}></CheckBoxList>
        </Grid>
        <Grid item xs = {12} lg = {3} >
          <CheckBoxList  items={products} title="Products" onChange={this.selectionChange}></CheckBoxList>
        </Grid>
        <Grid item xs = {12} lg = {3} >
          <CheckBoxList items={status} title="Status" onChange={this.selectionChange}></CheckBoxList>
        </Grid>
        <Grid item xs = {12} lg = {3} >
          <CheckBoxList  items={indicators} title="Indicators" onChange={this.selectionChange}></CheckBoxList>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => {

  // Prepare list of projects
  let projects = state.projects.projects.map(project => ({
    id: project.id,
    name: project.project_name,
    checked: project.checked,
    products: project.products.map(prd => prd.product_id),
  })).sort((a, b) =>((a.name > b.name)? 1: -1));
  
  // Only show products that have been associated with a project
  let products = state.lookups.products.filter(product => (
      projects.some(project => (project.products.indexOf(product.id) >= 0))
    ))
    .map(product => {
      if (product.hasOwnProperty('package')){
        return {
          package: product.package,
          name: product.name,
          id: product.id,
          checked: product.checked,
          level: product.package_name,
        }
      }
      // Deprecated v 0.2.6
      return {
       package: product.major_code,
       name: product.minor_title,
       id: product.id,
       checked: product.checked,
       level: product.major_title
      }
    }).sort((a, b) =>((a.level > b.level)? 1: -1));
    
  // Only show status values for those products in use
  // let status = state.lookups.status
  //   .filter(status => (
  //     products.some(product => {
  //       if (product.hasOwnProperty('package')){
  //         return product.package === status.package
  //       }
  //       // Deprecated v 0.2.6
  //       return product.major_code === status.product
  //     })      
  //   ))
  //   .map(status => {
  //     let product = state.lookups.products.filter(prd => {
  //       if (prd.hasOwnProperty('package')){
  //         return prd.package === status.package
  //       }
  //       // Deprecated v 0.2.6
  //       return prd.major_code === status.product
  //     });

  //     if (product[0].hasOwnProperty('package')){
  //       return {
  //         level: product[0].package_name,
  //         group: product[0].package,
  //         name: status.name,
  //         id: status.id,
  //         checked: status.checked,
  //         products: product.map(product => product.id)
  //       };
  //     }
  //     // Deprecated v 0.2.6
  //     return {
  //       level: product[0].major_title,
  //       group: product[0].major_code,
  //       name: status.name,
  //       id: status.id,
  //       checked: status.checked,
  //       products: product.map(product => product.id)
  //     };

  //   }).sort((a, b) =>((a.level > b.level)? 1: -1));

  const status = state.lookups.status.map(s => {
    s.id = s.code;

    return s;
  })
  .sort( (a,b) => {
    return a.state_order > b.state_order
  })
  console.log("%cFilters.js: Statuses\n", 'color: lightblue', status);
  
  // Only show indicator values for those products in use
  let indicators = state.lookups.indicators
      .filter(indicator => (
      products.some(product => {
        if (product.hasOwnProperty('package')){
          return product.package === indicator.package
        }
        // Deprecated v 0.2.6
        return product.major_code === indicator.product
      })))
    .map(indicator => {
      let product = state.lookups.products.filter(prd => {
        if (prd.hasOwnProperty('package')){
          return prd.package === indicator.package
        }
        // Deprecated v 0.2.6
        return prd.major_code === indicator.product
      });
      if (product[0].hasOwnProperty('package')){
        return {
          level: product[0].package_name,
          name: indicator.name,
          id: indicator.id,
          checked: indicator.checked,
          products: product.map(product => product.id),
        };
      }
      // Deprecated v 0.2.6
      return {
        level: product[0].major_title,
        name: indicator.name,
        id: indicator.id,
        checked: indicator.checked,
        products: product.map(product => product.id),
      };
    }).sort((a, b) =>((a.level > b.level)? 1: -1));

  const indicator_filters = indicators.filter(val => val.checked)
    .map(val => val.id);
  const product_filters = products.filter(val => val.checked)
    .map(val => val.id);
  const status_filters = status.filter(val => val.checked)
    .map(val => val.id);
  const project_filters = projects.filter(val => val.checked)
    .map(val => val.id);

  const {
    timeFilter
  } = state.map;
  const hasTimeFilter = timeFilter && timeFilter.start && timeFilter.end;

  const filtered_alerts = state.alerts.alerts.filter(alert => {
    const {
      properties
    } = alert;
    const obs_date = moment(properties.obs_date)
      .valueOf();
    return (
      (product_filters.length === 0 ||
        product_filters.indexOf(properties.product_id) >= 0) &&
      (status_filters.length === 0 || status_filters.indexOf(properties
          .status_code) >=
        0) &&
      (project_filters.length === 0 || project_filters.indexOf(
          properties
          .project_id) >=
        0) &&
      (indicator_filters.length === 0 ||
        indicator_filters.indexOf(properties.indicator_id) >= 0) &&
      (!hasTimeFilter ||
        (obs_date >= timeFilter.start && obs_date <= timeFilter.end))
    );
  });

  //  Get unique list of dates and quantity of alerts for each date/time
  let alerts = filtered_alerts.map(alert =>
    moment(alert.properties.obs_date)
    .valueOf());

  let dataCount = [];
  alerts.forEach(date => {
    if (dataCount.indexOf(date) < 0) dataCount.push(date)
  });

  dataCount = dataCount.sort()
    .map(date => ({
      time: date,
      index: 1,
      value: alerts.filter(val => val === date)
        .length
    }));

  return {
    isAuthenticated: state.auth.token !== null,
    projects,
    status,
    indicators,
    products,
    dataCount,
    filteredCount: alerts.length,
    timeFilter
  };
};

const mapDisptachToProps = dispatch => {

  return {
    checkProjects: (selected) => dispatch(Actions.checkProjects(selected)),
    checkProducts: (selected) => dispatch(Actions.checkProducts(selected)),
    checkStatus: (selected) => dispatch(Actions.checkStatus(selected)),
    checkIndicators: (selected) => dispatch(Actions.checkIndicators(
      selected)),
    updateTimeFilter: (timeFilter) =>
      dispatch(Actions.updateTimeFilter(timeFilter)),
    onFetchData: () => {

      dispatch(Actions.refreshProjects());
      dispatch(Actions.refreshLookups());
      dispatch(Actions.initializeObservations());
    },
    onFetchLookups: () => dispatch(Actions.refreshLookups()),
    onFetchBaseLayers: () => dispatch(Actions.fetchBaseLayers()),
    onFetchRefLayers: () => dispatch(Actions.fetchReferenceLayers())
  };
};
export default connect(
  mapStateToProps,
  mapDisptachToProps
)(withStyles(styles)(Filters));
