import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Tooltip from '@material-ui/core/Tooltip';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.grey[600],
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const StyledButton = withStyles((theme) => ({
  root: {
    color: theme.palette.grey["A400"],
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.grey["A400"],
      backgroundColor: theme.palette.common.white,
    },
  },
}))(Button);


const bookmarks = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickHandler = (bookmark) => {
    handleClose();
    props.clicked(bookmark);
  }

  const elements = props.bookmarks.map(val => {
    return (
      <StyledMenuItem key={val.id} onClick={() => clickHandler(val)}>
          <ListItemText primary={val.name} />
        </StyledMenuItem>
    )
  });

  return (
    <div>
      <Tooltip title="Bookmarks">
        <StyledButton
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          color="primary"
          disableRipple={true}
          onClick={handleClick}
        >
          <BookmarksIcon />
          {/* <Hidden xsDown>Bookmarks</Hidden> */}
        </StyledButton>
      </Tooltip>
      
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {elements}
      </StyledMenu>
    </div>
  );
}

export default bookmarks
