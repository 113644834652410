import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import moment from "moment";

import Indicator from "../../../containers/Indicator/Indicator";
import Status from "../../../containers/Status/Status";

const paperStyles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    [theme.breakpoints.up("xs")]: {
      paddingTop: theme.spacing(1.0),
      paddingBottom: theme.spacing(1.0),
      paddingLeft: theme.spacing(1.0),
      paddingRight: theme.spacing(1.0)
    }
  }
});

const alertStyles = theme => ({
  secondary: {
    color: "grey",
    fontSize: 10
  },
  icon: {
    fontSize: 12
  }
});

const StyledPaper = withStyles(paperStyles)(Paper);

class Alerts extends React.Component {
  handleClick = id => event => {
    const { selectedHandler } = this.props;
    selectedHandler(id);
  };

  render() {
    const { observations, classes, selectedObservation } = this.props;
    const alertItem = ({ data, index, style }) => {
      const {
        id,
        properties: {
          product_name: product,
          obs_date,
          media_count
        }
      } = data[index];

      return (
        <StyledPaper key={id} style={style}>
          <Grid
            container
            style={
              id === selectedObservation.id ? { backgroundColor: "lightgrey" } : {}
            }
          >
            <Grid item xs>
              <Status
                observation={data[index]}
                changedEvent={this.props.handleChangeStatus}
                display="dropdown"
              />
            </Grid>

            <Grid item xs onClick={this.handleClick(id)}>
              <div> {product}{(media_count > 0 ? <ImageOutlinedIcon style={{ verticalAlign: "sub" }} fontSize="small" color="action" ></ImageOutlinedIcon> : null)} </div>
              <div className={classes.secondary}>
                ID: {id} Date: {moment(obs_date).format("ll")} 
              </div>
            </Grid>
            <Grid item xs style={{ textAlign: "center" }}>
              <Indicator observation={data[index]} display="both" />
            </Grid>
          </Grid>
        </StyledPaper>
      );
    };

    return (
      
      <AutoSizer>
        {({ height, width }) => (
          <List
            height={height}
            itemCount={observations.length}
            itemSize={65}
            width={width}
            itemData={observations}
            style={{ position: "fixed" }}
          >
            {alertItem}
          </List>
        )}
      </AutoSizer>
      
    );
  }
}

export default withStyles(alertStyles)(Alerts);
