import React from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FolderIcon from '@material-ui/icons/Folder';
import SettingsIcon from '@material-ui/icons/Settings';
import TuneIcon from '@material-ui/icons/Tune';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  drawerPaper: props => ({
    width: props.drawerWidth,
    padding: theme.spacing(0),
  }), 
}));

export default function SideDrawer(props) {
    const classes = useStyles(props);
    const history = useHistory();
    
    const handleButtonClick = (navitem) => {
        if (props.mobileOpen){
          props.handleDrawerToggle();
        } 
          
        history.push("/" + navitem)
      }
    
    return (
        <div>
            <div className={classes.toolbar} />
            <Divider />
            <List>
                <ListItem button key={'projects'} onClick={() => handleButtonClick("")}>
                    
                    <ListItemIcon><FolderIcon /></ListItemIcon>
                    <ListItemText primary={"Projects"} />
                    
                </ListItem>
                <ListItem button key={'alerts'} onClick={() => handleButtonClick("alerts")}>
                    
                    <ListItemIcon><ImageSearchIcon /></ListItemIcon>
                    <ListItemText primary={"Observations"} />
                    
                </ListItem>
                <ListItem button key={'filters'} onClick={() => handleButtonClick("filters")}>
                
                    <ListItemIcon><TuneIcon /></ListItemIcon>
                    <ListItemText primary={"Filters"} />
                
                </ListItem>
                {/* <ListItem button key={'settings'} onClick={() => handleButtonClick("settings")}>
                
                    <ListItemIcon><SettingsIcon /></ListItemIcon>
                    <ListItemText primary={"Settings"} />
                
                </ListItem> */}
            </List>
        </div>
      );
}  