import authURL from "./axios-auth";
import apiURL from "./axios-api";
import tilesURL from "./axios-tiles";
import { setBucket } from "../store/localStorage/index"
import { isUsingFusionAuth } from "../containers/Auth/fusion-auth-service";


export const initializeBaseURLs = host => {
  
  const rootHost = setHost(host)
  initializeAuthBaseURL(rootHost);
  initializeApiBaseURL(rootHost);
  initializeTilesBaseURL(rootHost);
};

export const setHost = host => {
  
  let region = host.split('.')[1].toLowerCase();
  
  region = region
    .replace('eu-central-1','eua')
    .replace('ap-southeast-2','apa')
  
  if (region === 'ca'){
    region = 'caa'
  }    
  
  if (region === 'satelytics') {
    region = 'usa'; // Handling the old "otg.satelytics.io". Can be removed almost immediately.
  }
  // Localhost testing with DEV system
  // region = "dev";

  // Returns the new version: usa, demo, dev, local but doesn't change the original.
  // Original urls will still have their old regions: (ap-southeast-2, eu-central-1, ca, (prod))
  return `${region}.satelytics.io`;
}

export const initializeAuthBaseURL = host => {
  let auth_url;
  
  if (isUsingFusionAuth()) {
    auth_url = `https://web.${host}`;
  }
  else {
    // Using the old server
    auth_url = `https://auth.${host}/auth`;
  }

  authURL.defaults.baseURL = auth_url;
  authURL.defaults.headers["Content-Type"] = "application/json;charset=utf-8"; 
  console.log("Setting authentication url:", authURL.defaults.baseURL);
};

export const initializeApiBaseURL = host => {
  apiURL.defaults.rootURL = `https://api.${host}${host === 'localhost.satelytics.io' ? ':8000': ''}/placeholder/api`;
  apiURL.defaults.headers["Content-Type"] = "application/json;charset=utf-8";  
  console.log('Setting API Url: ' + apiURL.defaults.rootURL);
};

const initializeTilesBaseURL = host => {
    tilesURL.defaults.rootURL = `https://rtiles.${host}`;  
    console.log("Setting raster tile url:", tilesURL.defaults.rootURL);
};

export const initializeS3Bucket = host => {
  const region = setHost(host).split('.')[0];
  const region_bucket_map = {
    '': '',
    localhost: '-dev',
    dev: '-dev',
    demo: '',
    usa: '',
    caa: '-ca',
    apa: '-ap-southeast-2',
    eua: '-eu-central-1'
  }
  const bucket_region = region_bucket_map[region];
  const bucket = `sioclients${bucket_region}`;

  setBucket(bucket);


};


export const updateDefaultTilesURL = (account, token) => {
  tilesURL.defaults.baseURL = tilesURL.defaults.rootURL;
  console.log("Updating raster tile url:", tilesURL.defaults.baseURL);
  const authorization_prefix = isUsingFusionAuth() ? 'Bearer' : 'JWT';
  tilesURL.defaults.headers["Authorization"] = `${authorization_prefix} ${token}`;
  tilesURL.defaults.headers["Content-Type"] = "image/png";
  
};

export const updateDefaultApiURL = (account, token) => {
  let authorization_prefix = isUsingFusionAuth() ? 'Bearer' : 'JWT';
  apiURL.defaults.baseURL = apiURL.defaults.rootURL.replace('placeholder', account);
  apiURL.defaults.headers["Authorization"] = `${authorization_prefix} ${token}`;
  console.log("Updating api url:", apiURL.defaults.baseURL);
};

