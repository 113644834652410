// ***********************************************************************************
// function:    Process one or more objects and account for online/offline environment
// action:  CREATE (post)
//          UPDATE (patch)
//          DELETE (delete)
// ItemType:    modelTypes.OBSERVATION (U)
//              modelTypes.COMMENT (CUD)
//              modelTypes.MEDIA (CUD)
// item:    single or array of entities
// ***********************************************************************************
import * as modelTypes from "../modelTypes";
import * as modelActions from "../modelActions";
import store from "../index";
import db from "../db";
import {
  updateObservation
} from "./observations";
import {
  createComment,
  updateComment,
  removeComment,
  createMedia,
  updateMedia,
  removeMedia,
  setMediaCache,
  removeMediaCache
} from "./comments-media";
import {
  storeActionForSync
} from "./sync";
import {
  // setCommentForObservation,
  setMediaProperties,
  setMediaDownloadProperties,
  setCommentProperties
} from "./comments-media";
import {
  formDataToJson
} from "../utility";
import SioLogger from "../../modules/Logger/logger";

const logger = SioLogger('processors/index.js')

export const processItem = (action, itemType, item) => {
  // if ("serviceWorker" in navigator && "SyncManager" in window) {
  //   logger.info(`Service Worker/SyncManager is working on: ${action} ${itemType}`)
  //     // TODO Service Worker SyncManager does not conform to Online/offline state
  //     // Even if state = offline, it will still send/receive if a internet
  //     // connection is detected on the device
  //     return processOnline(action, itemType, item)
  //     .catch(error => {
  //       if (
  //         error.response === undefined &&
  //         (error.request.readyState === 4 && error.request.status === 0)
  //       ) {
  //         // Network Error
  //         return processOffline(action, itemType, item);
  //       } else {
  //         logger.error('Something went wrong processing the items: ' + error, error)
  //         return Promise.reject(error);
  //       }
  //     });
  // } else {
    logger.info('Processing handled by fallback functionality.');
    if (store.getState()
      .app.online) {
      return processOnline(action, itemType, item);
    } else {
      storeActionForSync(action, itemType, item);
      return processOffline(action, itemType, item);
    }
  // }
};

const processOnline = (action, itemType, item) => {
  
  switch (action) {
    case modelActions.CREATE:
      return createItem(itemType, item)
        .then(response => {
          return response;
        });

      // case modelActions.READ:
      //   return readItems(itemType).then(response => {
      //     return response;
      //   });

    case modelActions.UPDATE:
      return updateItem(itemType, item)
        .then(response => {
          return response;
        });

    case modelActions.DELETE:
      return deleteItem(itemType, item)
        .then(response => {
          return response;
        });

    default:
      logger.warning('Recieved an invalid action type for processing: ' + action)
      return Promise.reject({
        message: "Invalid action type"
      });
  }
};

const processOffline = (action, itemType, item) => {
  
  switch (action) {
    case modelActions.CREATE:
      return offlineCreateDBRecord(itemType, item);

      // case modelActions.READ:
      //   return offlineReadDBRecords(itemType).then(response => {
      //     return response;
      //   });

    case modelActions.UPDATE:
      if (item.constructor === Array) {
        return offlineUpdateDBBulk(itemType, item)
          .then(response => {
            return response;
          });
      } else {
        return offlineUpdateDBRecord(itemType, item)
          .then(response => {
            return response;
          });
      }

    case modelActions.DELETE:
      return offlineRemoveDBRecord(itemType, item);

    default:
      logger.warn('Could not process offline action type: ' + action)
      return Promise.reject({
        message: "Invalid action type"
      });
  }
};

const createItem = (sourceType, source) => {
  switch (sourceType) {
    case modelTypes.COMMENT:
      return createComment(source);
    case modelTypes.MEDIA:
      return createMedia(source);
    default:
      logger.warn('Could not create an item of type: ' + sourceType);
      return Promise.reject({
        message: "Cannot create this type of item."
      });
  }
};

// const readItems = sourceType => {
//   return Promise.resolve({});
// };

const deleteItem = (sourceType, source) => {
  switch (sourceType) {
    case modelTypes.COMMENT:
      return removeComment(source);
    case modelTypes.MEDIA:
      return removeMedia(source);
    default:
      logger.warn('Could not delete object of type: ' + sourceType);
      return Promise.reject({
        message: "Cannot remove this type of item."
      });
  }
};

const updateItem = (sourceType, source) => {
  switch (sourceType) {
    case modelTypes.OBSERVATION:
      return updateObservation(source);
    case modelTypes.COMMENT:
      return updateComment(source);
    case modelTypes.MEDIA:
      return updateMedia(source);
    default:
      logger.warn('Could not update object of type: ' + sourceType);
      return Promise.reject({
        message: "Cannot update this type of item."
      });
  }
};

const offlineCreateDBRecord = (itemType, data) => {
  let _table;
  const stamp = new Date();
  let _cachePromise;
  let logItem = '';

  switch (itemType) {
    case modelTypes.COMMENT:
      _table = "comments";
      data = setCommentProperties(data);
      logItem = data.comment.length > 25 ? data.comment.substr(0, 25) + '...' : data.comment;
      
      _cachePromise = Promise.resolve({});
      break;
    case modelTypes.MEDIA:
      _table = "media";
      data = formDataToJson(data);
      data = updateOrCreateCommentForMieda(data);
      data = setMediaProperties(data);
      data = setMediaDownloadProperties(data);
      logItem = data.filename;
      _cachePromise = setMediaCache(data);
      break;
    default:
      _cachePromise = Promise.resolve({});
  }

  // These are server populated fields
  data.created = stamp;
  data.modified = stamp;

  logger.info('Created a syncitem: ' + logItem);
  return Promise.all([_cachePromise])
    .then(values => {
      return db
        .table(_table)
        .put(data)
        .then(key => {
          return data;
        });
    });
};

// const offlineReadDBRecords = itemType => {
//   return Promise.resolve({});
// };
const updateOrCreateCommentForMieda = (data) => {

  if (data.hasOwnProperty("comment") && data["comment"].length > 0) {
    const comment = {
      observation: data["observation"],
      comment: data["comment"],
      has_media: true
    };
    if (data.hasOwnProperty("comment_text") && data["comment_text"]) {
      //comment exists update the comment
      const id = data["comment_text"];
      comment["id"] = id;
      offlineUpdateDBRecord(modelTypes.COMMENT, comment);
      logger.info('update comment ' + id);
    } else {
      //comment needs to be created
      logger.info('create comment for record id ' + comment.observation);
      const stamp = new Date();
      comment["id"] = stamp.getTime();
      offlineCreateDBRecord(modelTypes.COMMENT, comment);
    }
    data["comment"] = comment.id;
    data["comment_text"] = comment.comment;
  }
  return data;
};

const updateMiedaForComment = (data) => {

  db
    .table('media')
    .get({
      comment: data.id
    })
    .then(record => {
      if (data.hasOwnProperty("comment") && data["comment"]) {
        record["comment_text"] = data["comment"];
      }
      db.table('media').put(record);
    });

  return data;

};

const offlineUpdateDBRecord = (itemType, data) => {
  let _table;
  switch (itemType) {
    case modelTypes.OBSERVATION:
      _table = "alerts";
      break;
    case modelTypes.COMMENT:
      _table = "comments";
      data = setCommentProperties(data);
      data = updateMiedaForComment(data);
      break;
    case modelTypes.MEDIA:
      _table = "media";
      if (data instanceof FormData) {
        data = formDataToJson(data);
      }
      data = updateOrCreateCommentForMieda(data);
      break;
    default:
  }

  return db
    .table(_table)
    .get(data.id)
    .then(async( record ) =>  {
      let _cachePromise;
      if (record) {
        let responses;
        if (itemType === modelTypes.MEDIA) {
          responses = await removeMediaCache(record);
        }

        Object.keys(data)
          .forEach(property => {
            let _root = record;
            if (record.hasOwnProperty("geometry")) {
              _root = record["properties"];
            }

            if (_root.hasOwnProperty(property)) {
              _root[property] = data[property];
            }
          });

        if (data.hasOwnProperty("file")) {
          record["file"] = data["file"];
        }
        if (data.hasOwnProperty("comment") && data["comment"]) {
          record["comment"] = data["comment"];
        }
        if (data.hasOwnProperty("comment_text") && data["comment_text"]) {
          record["comment_text"] = data["comment_text"];
        }
        if (
          data.hasOwnProperty("file") ||
          data.hasOwnProperty("filename") ||
          data.hasOwnProperty("capture_date")
        ) {
          record = setMediaDownloadProperties(record);
          _cachePromise = setMediaCache(record, responses);
          if (record.hasOwnProperty("file")) {
            delete record["file"];
          }
        } else {
          _cachePromise = Promise.resolve({});
        }

        if (itemType === modelTypes.OBSERVATION && data.hasOwnProperty('status_code')) {
          const status_lookups = store.getState().lookups.status; 
          record.properties.status_code = data.status_code;

          if (data.status_code) {
            const status = status_lookups.find(item => {
              return item.code === data.status_code;
            });
            if (status) {
              record.properties.status_name = status.name;
            }
          }
        }

        // if (modelTypes.OBSERVATION && data.hasOwnProperty(
        //     "status")) {
        //   const comment = setCommentForObservation(data);
        //   offlineCreateDBRecord(modelTypes.COMMENT, comment)
        //     .catch(err => {
        //       logger.warn('Error creating offline comment for observation status change', {comment, error: err.message})
        //     });
        // }
        db.table(_table)
          .put(record);
      }
      return Promise.all([_cachePromise])
        .then(values => {
          // Need to wait for the cache insertion to complete so
          // that the UI can find the entry in the cache
          return record;
        });
    });
};

const offlineUpdateDBBulk = async (itemType, arr) => {
  const responseList = [];
  for (const item of arr) {
    responseList.push(await offlineUpdateDBRecord(itemType, item));
  }
  return responseList;
};

const offlineRemoveDBRecord = (itemType, data) => {
  let _table;

  switch (itemType) {
    case modelTypes.COMMENT:
      _table = "comments";
      break;
    case modelTypes.MEDIA:
      _table = "media";
      break;
    default:
  }
  // Let any errors bubble up
  return db.table(_table)
    .delete(data.id);
};
