import db from "../db";
import * as modelTypes from "../modelTypes";
import SioLogger from "../../modules/Logger/logger";

const logger = SioLogger('src/store/processors/utils.js');

export const removeSyncTask = data => {
  db.table("syncitems")
    .delete(data.id)
    .catch(err => {
      // console.log("Error trying to remove sync task:", data, err.message);
      logger.error('Error trying to remove a sync item: ' + err.message, err);
    });
};

export const removeTemporaryEntry = data => {
  let _table;
  let _key;

  switch (data.type) {
    case modelTypes.MEDIA:
      _table = "media";
      _key = data.data.get("id");
      break;
    case modelTypes.COMMENT:
      _table = "comments";
      _key = data.data.id;
      break;
    default:
  }

  db.table(_table)
    .delete(_key)
    .catch(err => {
      logger.error('Error removing temporary entry: ' + err.message, err);
    });
};
