import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Title from "./Title";
import Logo from "./Logo";
import OfflineIndicator from "./Indicators/OfflineIndicator";
import UpdateIndicator from "./Indicators/UpdateIndicator";
import SideDrawer from "./SideDrawer/SideDrawer";
import SideDrawerButton from "./SideDrawer/SideDrawerButton";
import ToolbarMenuIcons from "./ToolbarMenu/ToolbarMenuIcons";
import ProgressBar from "../UI/ProgressBar/ProgressBar";
import store from '../../store';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap:"nowrap",
    height: "100%",
  },
  grow: {
    flexGrow: 1
  },
  
  appBar: {
    marginLeft:0,
    backgroundColor: theme.palette.grey['A400'],
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  progressbar: {
    position: 'fixed',
    marginTop: '3rem',
    width: '100%'
  },
  content: {
    margin:"3.0rem 0 0 0",    
    width:"100%",
    height:`calc(100% - 3.0rem)`,
  },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
        display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
        display: 'none',
        },
    },
}));


export default function AppShell(props) {
  const classes = useStyles();
  const history = useHistory();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const {userType} = store.getState().auth;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  const handleDrawerExpansion = () => {
    setOpen(!open);
  };


  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };


  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };


  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };


  /**
   * Route handlers. 
   * Ensures that the dropdown menu closes before triggering the router.
   */
  const handleRoute = (route) => {
    handleMenuClose()
    history.push(route);
  }
  // const handleDebug = (event) => handleRoute('/debug')

  const handleSettings = (event) => handleRoute('/settings')

  const handleLogout = (event) => handleRoute('/logout')

  const handleAccount = () => handleRoute('/account');
  

  /**
   * Creating the men
   */
  const menuFactory = (anchor, id, openFlag, closeEvent) => (
    <Menu
      anchorEl={anchor}
      id={id}
      open={openFlag}
      onClose={closeEvent}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      {/* {props.debugEnabled && <MenuItem onClick={handleDebug}>Debug Menu</MenuItem>} */}
      <MenuItem onClick={handleSettings}>Settings</MenuItem>

      {userType === 'I' && <MenuItem onClick={handleAccount}>Change Account</MenuItem>}

      <MenuItem onClick={handleLogout}>Log out</MenuItem>
    </Menu>
  );

  const renderMenu = menuFactory(anchorEl, 'primary-search-account-menu', isMenuOpen, handleMenuClose)
  
  const renderMobileMenu = menuFactory(mobileMoreAnchorEl, 'primary-search-account-menu-mobile', isMobileMenuOpen, handleMobileMenuClose);

  let downloadProgress = (<div className={classes.progressbar}><ProgressBar color="secondary" /></div>)
  
  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
            [classes.appBarShift]: (open && !mobileOpen),
          })}
      >
        <Toolbar variant="dense">

          {props.isAuthenticated && 
            <SideDrawerButton handleDrawerToggle={handleDrawerToggle} handleDrawerExpansion={handleDrawerExpansion} />
          }
          
          <Logo />    
          
          {/* <Title /> */}

          <div className={classes.grow} />

          {props.updated ? <UpdateIndicator /> : null}

          {props.online ? null : <OfflineIndicator />}

          {props.isAuthenticated &&
              <>
                <ToolbarMenuIcons handleProfileMenuOpen={handleProfileMenuOpen} handleMobileMenuOpen={handleMobileMenuOpen} />
                {renderMobileMenu}
                {renderMenu}
              </>
          }
        </Toolbar>
      </AppBar>
      
      {props.isAuthenticated &&
        <SideDrawer open={open} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
      }
     {props.loading ? downloadProgress : null}
      <main className={classes.content}>
        {props.children}

      </main>
      
    </div>
  );
}

