import Cookies from 'js-cookie';
import auth_api from '../../api/axios-auth';
import { decodeJWT } from '../../store/utility';

const fusion_auth_supported_regions = ['localhost', 'demo', 'dev','usa', 'caa', 'eua', 'apa'];


export const isUsingFusionAuth = () => {
    try {
        
        let host = window.location.hostname;
        // Localhost testing with DEV system
        // host = "otg.dev.satelytics.io";
        
        let region = host.split('.')[1].toLowerCase();
        region = region
            .replace('eu-central-1','eua')
            .replace('ap-southeast-2','apa')
        if (region === 'ca'){
            region = 'caa'
        }    
        return fusion_auth_supported_regions.includes(region);
    } catch (error) {
        return false;
    }
}

const generateRandomString = () => {
    const arr = new Uint32Array(52/2);
    const dec_2_hex = (dec) => ('0' + dec.toString(16)).substr(-2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec_2_hex).join('');
}

const generateServerUrl = (endpoint, query_parameters) => {
    const query = new URLSearchParams(query_parameters).toString();

    return `${auth_api.defaults.baseURL}/${endpoint}?${query}`;
}

/**
 * Fusion Auth Login
 * Sends a preflight check to confirm that client id and tenant name for the users email address
 * Then sets the cookies and the redirects to fusion auth to handle the login procedure
 * @param {string} email 
 */
export const fusionAuthLoginRedirect = async (email) => {
    try {
        // Sending a prelogin check with the endpoint to get the tenant name.
        //  const query_parameters = new URLSearchParams({email, app: 'otg'}).toString();
        //  const auth_endpoint = `${auth_api.defaults.baseURL}/verify?${query_parameters}`
        const auth_endpoint = generateServerUrl('verify', {email, app: 'otg'})
        const precheck_response = await auth_api.get(auth_endpoint, {withCredentials:true});

        const {id: appId, name: tenantName} = precheck_response.data;
        const state = generateRandomString();
        // const scope = 'openid offline_access';
        const redirect_uri = window.location.protocol + '//' + window.location.host;

        // Generating the redirect url
        const login_url = generateServerUrl('app/login', {
            client_id: appId,
            redirect_uri,
            state,
            email
        });

        // Redirecting... Once login has completed they will be sent back here
        // See fusionAuthCheckState for the next step.
        // console.log('login_url: ' + login_url);
        window.location.assign(login_url);
    } catch (error) {
        // TODO: What should happen here? How about notifying the user that something went wrong?
        console.log(error);
    }
}

export const fusionAuthLogout = (token) => {
    const logout_parameters = {
        client_id: decodeJWT(token).aud,
        tenant_id: decodeJWT(token).tid,
        post_logout_redirect_uri: window.location.protocol + '//' + window.location.host
      } 
    
      // Clear all cookies and session storage.
      Cookies.remove('otg.idt');
    
      // Generate the logout url
    //   const logout_url  = auth_api.defaults.baseURL + '?' + new URLSearchParams(logout_parameters).toString();
    const logout_url = generateServerUrl('app/logout', logout_parameters);
    console.log("sending log out request...", logout_url)
      window.location.assign(logout_url);
    //   console.log('Redirecting to logout url: ' + logout_url);
}

export const getClients = async (token) => {
    return await auth_api.get(`${auth_api.defaults.baseURL}/accounts`, {
        headers: {
            authorization: `Bearer ${token}`
        }
    });    
}

export const changeClient = async (request_options) => {
    const { token, fromTenant, toTenant, id } = request_options;

    const url = `${auth_api.defaults.baseURL}/accounts/change`;

    const headers = {
        authorization: `Bearer ${token}`
    }

    const payload = { fromTenant, toTenant, id };

    return await auth_api.post(url, payload, {headers})
}
