import React from "react";

import classes from "./TabItem.css";

const tabitem = props => {
  let attachedClasses = [classes.TabItem];
  if (props.active) {
    attachedClasses = [classes.TabItem, classes.Active];
  }
  return (
    <div className={attachedClasses.join(" ")} onClick={props.clicked}>
      {props.name}
    </div>
  );
};

export default tabitem;
