import React, {useEffect} from 'react';
import Paper from "@material-ui/core/Paper";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import PublishIcon from '@material-ui/icons/Publish';
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import store from '../../store';
import useSyncItems from './useSyncItems';
import CommentPreview from './CommentPreview';
import ImagePreview from './ImagePreview';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: 20,
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  checkbox_icon: {
    borderRadius: 3,
    width: 13,
    height: 13,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5"
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)"
    }
  },
  checkbox_checked_icon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 13,
      height: 13,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3"
    }
  },
  subText: {
    display: "flex", 
    gap: '1rem',
    flexWrap: 'wrap',
    color: '#959599',
    fontSize: '0.7rem',
    marginBottom: '1rem',
  },
  subTextItem: {
    margin: '0 1rem',
  },
  small_icon: {
    fontSize: 20,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
  error_container: {
    flexBasis: '100%',
    display: 'flex',
    gap: '5px',
    color: theme.palette.secondary.main,
    marginTop: 'calc(-1rem + 5px)',
  },
  upload_button: {
    color: theme.palette.success.main,
    '&:disabled': {
      color: theme.palette.success.light
    }
  }

}));

const SyncItemsTab = ({sync_items, setSyncItems}) => {
    const refresh_rate_in_ms = 15000
    const classes = useStyles();
    const {downloadSyncItems, uploadSyncItem, deleteSyncItem, toggleSyncItem} = useSyncItems(sync_items, setSyncItems);
    const is_offline = !store.getState().app.online;
    
    /**
     * Calls updateItems on an interval every 15 seconds so the user can refresh
     */
    useEffect(() => {
        downloadSyncItems();
    }, []);

    return <>
        {/* Table Listing */}
        <TableContainer component={Paper} style={{height: '80vh'}}>
            <Table sx={{ minWidth: 700 }} aria-label="Sio Debug Table" stickyHeader >
                <TableHead>
                    <TableRow>
                        <TableCell width='20%' align='right'>Admin</TableCell>
                        {/* <TableCell width='10%'>Time</TableCell>
                        <TableCell width='10%'>Info</TableCell> */}
                        <TableCell align="left" >Data</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sync_items && sync_items.map((item, index) => (
                        <TableRow
                        key={index}
                        hover
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align='right'>
                                <Checkbox 
                                    className={classes.root}
                                    disableRipple
                                    color="default"
                                    checked={item.hasOwnProperty('enabled') ? item.enabled : true}
                                    checkedIcon={<span className={clsx(classes.checkbox_icon, classes.checkbox_checked_icon)} />}
                                    icon={<span className={classes.icon} />}
                                    inputProps={{ 'aria-label': 'decorative checkbox' }}
                                    onChange={() => toggleSyncItem(item)}
                                    title='Enable this item'
                                />
                                <IconButton onClick={() => uploadSyncItem(item)} className={classes.green_button}  title='Upload this item' size='small' disabled={is_offline}>
                                    <PublishIcon className={classes.small_icon}/>
                                </IconButton>

                                <IconButton onClick={() => deleteSyncItem(item)} color='secondary' title='Upload this item' size='small'>
                                    <DeleteIcon className={classes.small_icon} />
                                </IconButton>
                                 
                            </TableCell>

                            <TableCell align="left"  size='medium'>
                                <Typography variant='body1' component='div' className={classes.subText}>
                                    {/* <div className={classes[item.action]}>{item.action}</div> */}
                                    {/* {item.action === 'CREATE' && <CreateIcon style={{fontSize: 16}} />}
                                    {item.action === 'UPDATE' && <UpdateIcon style={{fontSize: 16}} />}
                                    {item.action === 'DELETE' && <DeleteIcon style={{fontSize: 16}} />} */}
                                    <div>{item.action}</div>
                                    <div classes={classes.subTextItem}>{item.data.observation}</div>
                                    <div classes={classes.subTextItem}>{(new Date(item.timestamp)).toLocaleString()}</div>

                                    {item.hasOwnProperty('error') && item.error === true && <div className={classes.error_container}>
                                      <ErrorIcon style={{fontSize: 16}} />
                                      <div className={classes.error_message}>{item.message}</div>
                                    </div>}
                                </Typography>
                                {item.type === 'MEDIA'
                                  ? <ImagePreview item={item} />
                                  : <CommentPreview item={item} />
                                }
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>
}


export default SyncItemsTab;