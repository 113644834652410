import React, {useState, useEffect} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import CommentPreview from './CommentPreview';

const useStyles = makeStyles((theme) => ({
    warning_text: {
        color: theme.palette.warning.main,
        marginTop: -10,
    },
    image_filename: {
        marginTop: -10,
        color: theme.palette.text.secondary
    }, 
    remove_top_spacing: {
        marginTop: -10,
    },
    remove_bottom_spacing: {
        marginBottom: -10,
    }
}))

export default function ImagePreview({item}) {
    const classes = useStyles();
    const [image_source, setImageSource] = useState(null);
    
    
    if (item.action === 'UPDATE' && item.data.comment) {
        return <>
            <Typography variant='body2' className={`${classes.image_filename} ${classes.remove_bottom_spacing}`}>
                Adding comment to the image: {item.data.filename}
            </Typography>
            <CommentPreview item={item}/>
        </>
    }

    if (item.action === 'DELETE' && item.data.filename) {
        return <Typography variant='body2' className={classes.warning_text}>
            {item.data.filename}
        </Typography>
    }



    useEffect( () => {
        
        if (!item.data.file) return;

        const reader = new FileReader();

        reader.onload = (e) => {
            setImageSource(e.target.result);
        }

        reader.readAsDataURL(item.data.file);

    }, [item])

    return <>
        <Typography variant='body2' className={classes.image_filename}>{item.data.file.name}</Typography>
        <img src={image_source} className='image_preview' style={{width: 'auto', height: 100}} /> 
    </>
}