import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
  root: {
    width: '100%'
  },
});

function ProgressBar(props) {
  const {
    classes,
    color = "primary"
  } = props;
  return (
    <div className={classes.root}>
      <LinearProgress 
        color={color} 
        />
    </div>
  );
}

export default withStyles(styles)(ProgressBar);