import React, { Component } from "react";
import { connect } from "react-redux";

import Input from "../../UI/Input/Input";
import Button from '@material-ui/core/Button';
import * as appActions from "../../../store/actions/index";
import { withStyles } from "@material-ui/core/styles"

const styles = theme => ({
  root: {
    width: "100%",
    textAlign: "center",
    padding: "1rem"
  }  
});

class NewComment extends Component {
  state = {
    controls: {
      comment: {
        elementType: "textarea",
        muiConfig: {
          label: "Comment",
          fullWidth: true,
          multiline: true,
          rows: 5,
          placeholder: "Comment..."
        },
        elementConfig: {
        },
        value: "",
        validation: {
          required: true,
          maxLength: 5000
        },
        valid: false,
        touched: false
      }
    }
  };

  checkValidity(value, rules) {
    let isValid = true;
    if (!rules) {
      return true;
    }

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid;
    }

    if (rules.isEmail) {
      const pattern = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
      isValid = pattern.test(value) && isValid;
    }

    if (rules.isNumeric) {
      const pattern = /^\d+$/;
      isValid = pattern.test(value) && isValid;
    }

    return isValid;
  }

  inputChangedHandler = (event, controlName) => {
    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        valid: this.checkValidity(
          event.target.value,
          this.state.controls[controlName].validation
        ),
        touched: true
      }
    };
    this.setState({ controls: updatedControls });
  };

  submitHandler = event => {
    event.preventDefault();
    const stamp = new Date();
    const comment = {
      id: stamp.getTime(),
      observation: this.props.observation.id,
      comment: this.state.controls.comment.value
    };

    this.props.onAddComment(comment).then(response => {
      console.log(
        "UI comment creation response:",
        response.message ? response.message : response
      );
      this.props.cancel("NewComment");
    });
  };

  render() {
    const formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key]
      });
    }

    let form = formElementsArray.map(formElement => (
      <Input
        key={formElement.id}
        elementType={formElement.config.elementType}
        muiConfig={formElement.config.muiConfig}
        elementConfig={formElement.config.elementConfig}
        value={formElement.config.value}
        invalid={!formElement.config.valid}
        shouldValidate={formElement.config.validation}
        touched={formElement.config.touched}
        changed={event => this.inputChangedHandler(event, formElement.id)}
      />
    ));

    return (
      <div className={this.props.classes.root}>
        <form onSubmit={this.submitHandler}>
          {form}
          <div>
          <Button aria-controls="customized-menu"
              aria-haspopup="true"
              variant="contained" type="submit" color="primary">Save</Button>
          <Button aria-controls="customized-menu"
              aria-haspopup="true"
              variant="contained" color="default" onClick={this.props.cancel}>
            Cancel
          </Button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAddComment: comment => dispatch(appActions.addComment(comment))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NewComment));
