import React, {
  PureComponent
} from "react";
import PropTypes from "prop-types";
import moment from "moment";

import {
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis,
  ZAxis,
  ReferenceArea,
  ReferenceLine
} from "recharts";

import {
  getDateRangeSelection
} from "../../store/localStorage/index";

class TimeSeriesChart extends PureComponent {

  state = {
    timeWindowStart: null,
    timeWindowEnd: null,
    refVisible: true,
    start: true,
    label: 0
  };

  componentDidMount() {
    let DRS = getDateRangeSelection();

    const {
      chartData,
      timeFilter
    } = this.props;

    let timeWindowStart, timeWindowEnd

    if (timeFilter) {
      timeWindowStart = timeFilter.start;
      timeWindowEnd = timeFilter.end;
    } else if (chartData.length > 0) {
      timeWindowStart = Math.min.apply(null, chartData.map(val => val.time));
      timeWindowEnd = Math.max.apply(null, chartData.map(val => val.time));
    } else {
      timeWindowStart = moment(DRS.startDate)
        .valueOf()
      timeWindowEnd = moment(DRS.endDate)
        .valueOf()
    }

    this.setState({
      timeWindowStart,
      timeWindowEnd,
      label: chartData
        .filter(val => val.time >= timeWindowStart && val.time <=
          timeWindowEnd)
        .map(val => val.value)
        .reduce((acc, cur) => acc + cur, 0)
    })
  }

  zeroMaxRange = data => {
    // if (this.props.chartData.length > 0) {
    // console.log("Use data for range")
    return [0, Math.max.apply(null, this.props.chartData.map(entry => entry
      .value))]
    // } else {
    //   console.log("Use default for range")
    //   return [0, 0]
    // }
  };

  chartClickHandler = event => {
    if (event) {
      const {
        start
      } = this.state;
      if (start) {
        this.setState({
          timeWindowStart: event.xValue,
          start: false,
          refVisible: false
        });
      } else {
        let timeWindowEnd = event.xValue;
        let {
          timeWindowStart
        } = this.state;
        if (timeWindowStart > timeWindowEnd) {
          // swap the start and finish if start is larger than end
          const swap = timeWindowEnd;
          timeWindowEnd = timeWindowStart;
          timeWindowStart = swap;
        }

        const {
          onTimeFilterChange,
          chartData
        } = this.props;

        onTimeFilterChange(timeWindowStart, timeWindowEnd);
        this.setState({
          timeWindowEnd,
          timeWindowStart,
          start: true,
          refVisible: true,
          label: chartData
            .filter(
              val => timeWindowEnd > val.time && val.time >
              timeWindowStart
            )
            .map(val => val.value)
            .reduce((acc, cur) => acc + cur, 0)
        });
      }
    }
  };

  render() {
    const {
      chartData,
      timeFilter
    } = this.props;

    const range = [16, 500];

    let {
      refVisible,
      label,
      timeWindowStart,
      timeWindowEnd
    } = this.state;
    // if (chartData.length === 0) {
    //   chartData.push({
    //     time: moment()
    //       .valueOf(),
    //     index: 1,
    //     value: 0
    //   })
    // }
    // if (timeFilter) {
    //   timeWindowStart = timeFilter.start;
    //   timeWindowEnd = timeFilter.end;
    //   console.log("Using passed in time elements:", timeFilter.start,
    //     timeFIlter.end)
    // } else {

    //   timeWindowStart = Math.min.apply(null, chartData.map(val => val.time));
    //   timeWindowEnd = Math.max.apply(null, chartData.map(val => val.time));
    //   console.log("Getting time window from data:", timeWindowStart,
    //     timeWindowEnd)
    // }

    // const label = chartData
    //   .filter(
    //     val => timeWindowEnd > val.time && val.time >
    //     timeWindowStart
    //   )
    //   .map(val => val.value)
    //   .reduce((acc, cur) => acc + cur, 0)

    return (<React.Fragment>
      <ResponsiveContainer width="100%" height={100} cursor="crosshair">
        <ScatterChart fill="#0000ca" onClick={this.chartClickHandler}>
          <XAxis
            dataKey="time"
            domain={["auto", "auto"]}
            name="Time"
            tickFormatter={unixTime => moment(unixTime).format("MMM DD, YYYY")}
            type="number"
            tickLine={{ transform: "translate(0, -6)" }}
            tick={{ fontSize: 10 }}
            interval="preserveStartEnd"
          />
          <YAxis
            dataKey="index"
            type="number"
            name="index"
            height={10}
            width={10}
            tick={false}
            tickLine={false}
            axisLine={false}
            domain={['auto', 'auto']}
            range={[0, 50]}
          />
          <ZAxis
            type="number"
            dataKey="value"
            name="count"
            domain={this.zeroMaxRange()}
            range={range}
          />
          <Scatter
            data={chartData}
            lineJointType="monotoneX"
            lineType="joint"
            name="Values"
            fill="#2A55A4"
          />
          
          {!refVisible && (
            <ReferenceLine
              x={timeWindowStart}
              stroke="red"
              strokeOpacity={0.6}
              strokeWidth={5}
            />
          )}
        </ScatterChart>
            
      </ResponsiveContainer>
      </React.Fragment>);
  }
}

TimeSeriesChart.propTypes = {
  chartData: PropTypes.arrayOf(
      PropTypes.shape({
        time: PropTypes.number,
        value: PropTypes.number
      })
    )
    .isRequired
};

export default TimeSeriesChart;
