import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const styles = theme => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(3),
    minWidth: 120
  }
});

class SimpleSelect extends React.Component {
  state = {
    values: this.props.domainValues,
    selected: this.props.defaultValue
  };

  changeHandler = event => {
    this.setState({ selected: event.target.value });
    if (this.props.emitEvent) {
      this.props.emitEvent(event);
    }
  };

  render() {
    const { classes } = this.props;
    let entries = this.state.values.map(item => {
      return (
        <MenuItem key={item.id} value={item.id}>
          {item.value}
        </MenuItem>
      );
    });
    return (
      <form autoComplete="off">
        <FormControl className={classes.formControl}>
          <InputLabel>{this.props.formLabel}</InputLabel>
          <Select value={this.state.selected} onChange={this.changeHandler}>
            {entries}
          </Select>
        </FormControl>
      </form>
    );
  }
}

SimpleSelect.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SimpleSelect);
