import store from '../../store/';
import writeLog from './logWriter';

export const LOG_LEVEL = {
    DEBUG: 'DEBUG',
    INFO: 'INFO',
    WARNING: 'WARNING',
    ERROR: 'ERROR',
    CRITICAL: 'CRITICAL',
};

/**
 * Takes in the component source and returns a list of functions
 * Each logging function posts a message to the web worker so that log data can be stored in indexeddb
 * 
 * @param {string} source The component that the log is coming from.
 * @returns 
 */
const SioLogger = (source) => {

    /**
     * Currys the writeLog so we can churn out logging functions
     * 
     * @param {LOG_LEVEL} logLevel 
     * @returns 
     */
    const createLoggingFunction = (level) => {
        return (message, data) => {
            const user = store.getState().auth.userName;
            const timestamp = (new Date).toISOString(); // create a timestamp
            switch (level) {
                case LOG_LEVEL.INFO: console.info(message);
                break;
                case LOG_LEVEL.WARNING: console.warn(message);
                break;
                case LOG_LEVEL.ERROR: console.error(message);
                break;
                case LOG_LEVEL.CRITICAL: console.error(message);
                break;
                default: console.log(message);
            }
            // return writeLog(logLevel, message, data)
            writeLog({
                source, user, timestamp, data:JSON.stringify(data), level, message
            })
        }
    }


    return {
        debug: createLoggingFunction(LOG_LEVEL.DEBUG),
        info: createLoggingFunction(LOG_LEVEL.INFO),
        warn: createLoggingFunction(LOG_LEVEL.WARNING),
        error: createLoggingFunction(LOG_LEVEL.ERROR),
        critical: createLoggingFunction(LOG_LEVEL.CRITICAL),
    }
}

export default SioLogger;