import * as actionTypes from "../actions/actionTypes";
import {
  updateObject
} from "../utility";

const initialState = {
  mapMain: null,
  mapZoomCenter: null,
  timeFilter: null,
  tilesGenerating: false,
  tilesDownloadReady: false,
  tilesDownloadPackage: {},
  tilesDownloading: false,
  baseLayers: [],
  refLayers: [],
  baseLayersLoaded: false,
  refLayersLoaded: false
};

const setMainMap = (state, action) => {
  return updateObject(state, {
    mapMain: action.data
  });
};

const tileGenerationStart = (state, action) => {
  return updateObject(state, {
    tilesGenerating: true
  });
};

const tileGenerationSuccess = (state, action) => {
  return updateObject(state, {
    tilesGenerating: false
  });
};

const tileDownloadReady = (state, action) => {
  return updateObject(state, {
    tilesGenerating: false,
    tilesDownloadReady: true,
    tilesDownloadPackage: action.data
  });
};

const tileDownloadStart = (state, action) => {
  return updateObject(state, {
    tilesDownloading: true,
    tilesDownloadReady: false
  });
};

const tileDownloadSuccess = (state, action) => {
  return updateObject(state, {
    tilesDownloading: false,
    tilesDownloadPackage: {},
    tilesDownloadReady: false,
    tilesGenerating: false
  });
};

const tileDownloadCancel = (state, action) => {
  return updateObject(state, {
    tilesDownloading: false,
    tilesDownloadPackage: {},
    tilesDownloadReady: false,
    tilesGenerating: false
  });
};

const updateMapZoomCenter = (state, action) => {
  return updateObject(state, {
    mapZoomCenter: action.mapZoomCenter
  });
};

const updateTimeFilter = (state, action) => {
  return updateObject(state, {
    timeFilter: action.timeFilter
  });
};

const updateCurrentView = (state, action) => {
  return updateObject(state, {
    currentView: action.currentView
  });
};

const fetchBaseLayersSuccess = (state, action) => {
  return updateObject(state, {
    baseLayers: action.data,
    baseLayersLoaded: true
  });
};

const fetchReferenceLayersSuccess = (state, action) => {
  return updateObject(state, {
    refLayers: action.data,
    refLayersLoaded: true
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MAIN_MAP:
      return setMainMap(state, action);
    case actionTypes.TILE_GENERATION_START:
      return tileGenerationStart(state, action);
    case actionTypes.TILE_GENERATION_SUCCESS:
      return tileGenerationSuccess(state, action);
    case actionTypes.TILE_DOWNLOAD_READY:
      return tileDownloadReady(state, action);
    case actionTypes.TILE_DOWNLOAD_START:
      return tileDownloadStart(state, action);
    case actionTypes.TILE_DOWNLOAD_SUCCESS:
      return tileDownloadSuccess(state, action);
    case actionTypes.TILE_DOWNLOAD_CANCEL:
      return tileDownloadCancel(state, action);
    case actionTypes.UPDATE_MAP_ZOOM_CENTER:
      return updateMapZoomCenter(state, action);
    case actionTypes.UPDATE_TIME_FILTER:
      return updateTimeFilter(state, action);
    case actionTypes.FETCH_BASE_LAYERS_SUCCESS:
      return fetchBaseLayersSuccess(state, action);
    case actionTypes.FETCH_REF_LAYERS_SUCCESS:
      return fetchReferenceLayersSuccess(state, action);
    case actionTypes.UPDATE_CURRENT_VIEW:
      return updateCurrentView(state, action);
    default:
      return state;
  }
};

export default reducer;
