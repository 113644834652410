import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { saveAlert } from "../../store/actions/index";
import IconButton from "@material-ui/core/IconButton";
import TripOrigin from "@material-ui/icons/TripOrigin";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SioLogger from "../../modules/Logger/logger";
import CurrentStatusContext from "../alertContainer/CurrentStatusContext";

const logger = SioLogger('src/containers/Status/Status.js');

const statusStyles = theme => ({
  secondary: {
    fontSize: 14
  },
  icon: {
    fontSize: 18
  },
  status: {
    display: "flex",
    alignItems: "stretch"
  }
});

const Status = (props) => {
  const current_status = React.useContext(CurrentStatusContext);
  
  const statusDropdownChangeEvent = (event) => {
    if (!props.changedEvent) return console.log('No change event defined');
    console.log(props.changedEvent);
    props.changedEvent(props.observation.id, event.target.value);
  }

  const getStatusStyle = (status_record) => {
    let status_color = 'black';

    if (status_record) {
      if (status_record.renderer && status_record.renderer.hasOwnProperty('color')) {
        status_color = status_record.renderer.color;
      } else {
        status_color = JSON.parse(status_record.renderclass).markerColor;
      }
    }

    return {color: status_color};
  }


  // Rendering a single status symbol for the header component
  if (props.display === 'symbol') {
    const status_record = props.statusList.find(item => item.code === current_status);
    const status_style = getStatusStyle(status_record);
    return (
      <TripOrigin style={status_style}/>
    )
  }

  // Rendering the Dropdown list of all statuses for the list view and detail view.
  if (props.display === 'dropdown') {
    return (
      <Select
        autoWidth={true}
        value={props.observation.properties.status_code}
        onChange={statusDropdownChangeEvent}
        className={props.classes.secondary}>
        {props.statusList.map(item => {
          const status_style = getStatusStyle(item);
          return (
            <MenuItem key={item.id} value={item.code} disabled={!item.enabled}>
              <div className={props.classes.status}>
                <div>
                  <TripOrigin style={status_style} className={props.classes.icon} />
                </div>
                <div>{item.name}</div>
              </div>
            </MenuItem>
          )
        })}
      </Select>
    )
  }




 
}

const mapStateToProps = (state, ownProps) => {
  return {
    userType: state.auth.userType,
    statusList: state.lookups.status,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveAlert: alert => dispatch(saveAlert(alert))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(statusStyles)(Status));
