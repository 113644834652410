import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import authReducer from "./reducers/auth";
import appReducer from "./reducers/app";
import alertsReducer from "./reducers/alerts";
import commentMediaReducer from "./reducers/comments-media";
import projectsReducer from "./reducers/projects";
import lookupReducer from "./reducers/lookups";
import mapReducer from "./reducers/map";

const rootReducer = combineReducers({
  lookups: lookupReducer,
  alerts: alertsReducer,
  comment_media: commentMediaReducer,
  projects: projectsReducer,
  auth: authReducer,
  app: appReducer,
  map: mapReducer
});

const store = createStore(rootReducer, compose(applyMiddleware(thunk)));

export default store;
