import React, { Fragment, Component, useEffect } from "react";
import {
  cancelTileDownload,
  startTileGeneration,
  selectAlert
} from "../../../../store/actions/index";
import {
  MAIN_VIEW_MAP,
  MAIN_VIEW_LIST,
  DETAIL_VIEW_MAP,
  DETAIL_VIEW_COMMENTS,
  DETAIL_VIEW_PHOTOS,
  DETAIL_VIEW_PROPERTIES
} from "../../../../constants/contentViews";
import { getSettings } from "../../../../store/localStorage/index";
import { connect } from "react-redux";

import SyncIcon from "@material-ui/icons/Sync";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MyLocation from "@material-ui/icons/MyLocation";
import ViewList from "@material-ui/icons/ViewList";
import Apps from "@material-ui/icons/Apps";
import AddComment from "@material-ui/icons/AddComment";
import AddAPhoto from "@material-ui/icons/AddAPhoto";
import Layers from "@material-ui/icons/Layers";
import Map from "@material-ui/icons/Map";
import Cancel from "@material-ui/icons/Cancel";
import CloudDownload from "@material-ui/icons/CloudDownload";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import SvgIcon from "@material-ui/core/SvgIcon";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function MapLayerConfig(props) {
  return (
    <SvgIcon {...props}>
      <path
        fill="#000000"
        d="M3,5H9V11H3V5M5,7V9H7V7H5M11,7H21V9H11V7M11,15H21V17H11V15M5,20L1.5,16.5L2.91,15.09L5,17.17L9.59,12.59L11,14L5,20Z"
      />
    </SvgIcon>
  );
}

const styles = theme => ({
  progress: {
    margin: theme.spacing(0.5)
  },
  Actionbar: {
    height: "2.5rem",
    width: "100%",
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.25)",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "0 1.25rem",
    boxSizing: "border-box",
    zIndex: 1000,
    color: "rgba(0, 0, 0, 0.75)"
  },
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const actionbar = props => {
  const {
    onCancelDownload,
    startTileGeneration,
    mapMain,
    tilesDownloading,
    mapConfigs,
    currentView,
    setView,
    toggleFilter,
    closeDetails,
    classes,
    layerControl,
    online,
    watching,
    locateMe,
    locationErrorMessage,
    locationFound,
    locationError
  } = props;

  const [openZoomWarning, setOpenZoomWarning] = React.useState(false);
  const handleWarningClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenZoomWarning(false);
  };

  const [openLocateError, setOpenLocateError] = React.useState(false);
  const [openLocationFound, setOpenLocationFound] = React.useState(false);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenLocateError(false);
  };

  const checkLocation = () => {
    if (locationError) {
      setOpenLocateError(true);
    } else if (locationFound) {
      setOpenLocationFound(true);
    }
  }

  useEffect(() => {
    checkLocation();
  }, [locationError, locationFound]);
  
  const handleFoundClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenLocationFound(false);
  };

  let actionItems;
  const download = () => {
    startTileGeneration(mapMain);
  };

  switch (currentView) {
    case MAIN_VIEW_MAP:
      actionItems = (
        <Fragment>
          <ViewList onClick={() => setView(MAIN_VIEW_LIST)} />
          {"geolocation" in navigator ? (
            <MyLocation
              onClick={() => { 
                locateMe();
               }}
              color={watching ? "secondary" : "inherit"}
            />
          ) : null}
          {tilesDownloading ? (
            <CircularProgress size={30} color="primary" />
          ) : null}
          {tilesDownloading ? (
            <Cancel color="error" onClick={onCancelDownload} />
          ) : null}
          <Layers onClick={layerControl} />
          {tilesDownloading ? null : (
            <IconButton
              disabled={!online}
              color={"inherit"}
              onClick={() => download(mapMain)}
            >
              <CloudDownload />
            </IconButton>
          )}
        </Fragment>
      );
      break;
    case MAIN_VIEW_LIST:
      actionItems = (
        <Fragment>
          <Map onClick={() => setView(MAIN_VIEW_MAP)} />
          {/* <Layers />
          <Apps onClick={() => console.log("show map")} /> */}
        </Fragment>
      );
      break;
    case DETAIL_VIEW_MAP:
      actionItems = (
        <Fragment>
          <Layers onClick={layerControl} />
          {"geolocation" in navigator ? (
            <MyLocation
              onClick={() => locateMe()}
              color={watching ? "secondary" : "inherit"}
            />
          ) : null}
          <AddComment onClick={props.addComment} />
          <AddAPhoto onClick={props.addMedia} />
          <Cancel onClick={closeDetails} />
        </Fragment>
      );
      break;
    case DETAIL_VIEW_COMMENTS:
      actionItems = (
        <Fragment>
          <AddComment onClick={props.addComment} />
          <AddAPhoto onClick={props.addMedia} />
          <Cancel onClick={closeDetails} />
        </Fragment>
      );
      break;
    case DETAIL_VIEW_PHOTOS:
      actionItems = (
        <Fragment>
          <AddComment onClick={props.addComment} />
          <AddAPhoto onClick={props.addMedia} />
          <Cancel onClick={closeDetails} />
        </Fragment>
      );
      break;
    case DETAIL_VIEW_PROPERTIES:
      actionItems = (
        <Fragment>
          <AddComment onClick={props.addComment} />
          <AddAPhoto onClick={props.addMedia} />
          <Cancel onClick={closeDetails} />
        </Fragment>
      );
      break;
  }
  return (
    <div className={classes.Actionbar}>
      {actionItems}
      <Snackbar
        open={openZoomWarning}
        autoHideDuration={8000}
        onClose={handleWarningClose}
      >
        <Alert
          onClose={handleWarningClose}
          severity="warning"
          sx={{ width: "50%" }}
        >
          Current map zoom level is higher than the max zoom setting,
          therefore no offline maps will be downloaded. Please either zoom out
          or increase the max zoom on the settings screen.
        </Alert>
      </Snackbar>
      <Snackbar
        open={openLocateError}
        autoHideDuration={8000}
        onClose={handleErrorClose}
      >
        <Alert
          onClose={handleErrorClose}
          severity="error"
          sx={{ width: "50%" }}
        >
          {locationErrorMessage ? locationErrorMessage : null}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openLocationFound}
        autoHideDuration={5000}
        onClose={handleFoundClose}
      >
        <Alert
          onClose={handleFoundClose}
          severity="success"
          sx={{ width: "50%" }}
        >
          Successfully found your location.
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = state => {
  const { tilesDownloading, mapConfigs, mapMain } = state.map;
  const { online, gps } = state.app;
  return {
    mapConfigs,
    tilesDownloading,
    mapMain,
    online,
    gps
  };
};

const mapDisptachToProps = dispatch => {
  return {
    startTileGeneration: map => dispatch(startTileGeneration(map)),
    onCancelDownload: () => dispatch(cancelTileDownload())
  };
};

export default connect(
  mapStateToProps,
  mapDisptachToProps
)(withStyles(styles)(actionbar));
