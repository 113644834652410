import React from 'react';
import axios_api from '../../api/axios-api';
import store from '../../store';

export default function useTelemetry() {

    const payload_base_options = {
        submitter: store.getState().auth.userName,
        app: 'otg',
    }

    const transmitLogs = async (logs) => {
        try {
            const response = await axios_api.post('/diagnostics', {
                ...payload_base_options,
                diag_type: 'LOGS',
                data: logs.slice(0, 100)
            });
            
        } catch (error) {
            console.warn('Error transmitting logs', {error, logs});
        }
    }

    const buildSyncItemPayload = (sync_item) => {
        // When creating media objects we need to upload the file seperately from the data 
        if (sync_item.type === 'MEDIA' && sync_item.action === 'CREATE') {

            // Gather everything up except the data attribute
            const {data, ...post_data} = sync_item;

            // Filter out the file from the sync_item's data attribute
            const {file, ...other_data} = data;

            // Place the data with the original post data.
            post_data.data = {...other_data};

            const payload = new FormData();
            payload.append('submitter', payload_base_options.submitter);
            payload.append('app', 'otg');
            payload.append('diag_type', 'SYNC');
            payload.append('data', JSON.stringify(post_data));
            payload.append('file', file);

            return payload;
        }

        return {
            ...payload_base_options,
            diag_type: 'SYNC',
            data: sync_item
        }
    }

    const transmitSyncItems = async (sync_items) => {

        let number_of_transmitted_sync_items = 0;

        sync_items.forEach( async (sync_item) => {
            const payload = buildSyncItemPayload(sync_item);

            try {
                const response = await axios_api.post('/diagnostics', payload);
                number_of_transmitted_sync_items += 1;
            } catch (error) {
                console.warn('Error transmitting sync item', {sync_item, error});
            }

        });

        console.log(`Successfully transmitted ${number_of_transmitted_sync_items} of ${sync_items.length} sync items.`);
    }

    return {
        transmitLogs: transmitLogs,
        transmitSyncItems: transmitSyncItems
    }
}