import "react-app-polyfill/ie9";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

// Import Promise polyfill
// NOTE: Bluebird has not been added to this project yet!
// import Bluebird from "bluebird";

import { initializeBaseURLs , initializeS3Bucket} from "./api/utility";

// import "./index.css";

import App from "./App";
import store from "./store/index";
import * as serviceWorker from "./registerServiceWorker";
import * as actions from "./store/actions/index";
import { setOfflineMode } from "./store/localStorage/index"
// Handle Promise polyfill
// if (!window.Promise) {
//   window.Promise = Bluebird;
// }

if (navigator.onLine) {
  store.dispatch(actions.appOnline());
}

window.addEventListener("offline", () => {
  console.log("system is offline");
  store.dispatch(actions.appOffline());
  setOfflineMode(true);
});

// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker.addEventListener("message", event => {
//     console.log("Service worker sent a message:", event.data);
//   });
// }

const updateAvailable = registration => {
  store.dispatch(actions.updateAvailable());
};

let host = window.location.hostname;

// Localhost testing
// host = "otg.dev.satelytics.io";

initializeBaseURLs(host);
initializeS3Bucket(host);

const Index = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  )
};

const rootElement = document.getElementById("root");
ReactDOM.render(<Index />, rootElement);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: updateAvailable
});
