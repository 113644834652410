import * as actionTypes from "./actionTypes";
import axios from "../../api/axios-api";
import {
  generateTiles,
  downloadTiles,
  stopTileDownload
} from "../../modules/BatchDownload/batchDownload";
import {
  replaceTableInDB
} from "../utility";
import SioLogger from "../../modules/Logger/logger";

const logger = SioLogger('src/store/actions/map.js');

const setMainMap = value => {
  return {
    type: actionTypes.SET_MAIN_MAP,
    data: value
  };
};

const tileGenerationStart = () => {
  return {
    type: actionTypes.TILE_GENERATION_START
  };
};

const tileGenerationSuccess = () => {
  return {
    type: actionTypes.TILE_GENERATION_SUCCESS
  };
};

const tileDownloadReady = value => {
  return {
    type: actionTypes.TILE_DOWNLOAD_READY,
    data: value
  };
};

const tileDownloadStart = () => {
  return {
    type: actionTypes.TILE_DOWNLOAD_START
  };
};

const tileDownloadSuccess = () => {
  return {
    type: actionTypes.TILE_DOWNLOAD_SUCCESS
  };
};

const tileDownloadCancel = () => {
  return {
    type: actionTypes.TILE_DOWNLOAD_CANCEL
  };
};

export const storeMainMap = map => {
  return dispatch => {
    dispatch(setMainMap(map));
  };
};

export const startTileGeneration = map => {
  return dispatch => {
    dispatch(tileGenerationStart());
    generateTiles(map);
  };
};

export const finishTileGeneration = () => {
  return dispatch => {
    dispatch(tileGenerationSuccess());
  };
};

export const readyTileDownload = value => {
  return dispatch => {
    dispatch(tileDownloadReady(value));
  };
};

export const finishTileDownload = () => {
  return dispatch => {
    dispatch(tileDownloadSuccess());
  };
};

export const startTileDownload = () => {
  return dispatch => {
    dispatch(tileDownloadStart());
    downloadTiles();
  };
};

export const cancelTileDownload = () => {
  return dispatch => {
    dispatch(tileDownloadCancel());
    stopTileDownload();
  };
};

export const updateMapZoomCenter = values => {
  return {
    type: actionTypes.UPDATE_MAP_ZOOM_CENTER,
    mapZoomCenter: values
  };
};

export const updateTimeFilter = values => {
  return dispatch => {
    dispatch(updateTimeFilterAttributes(values));
    // dispatch(updateAlertSelection());
  };
};

export const updateCurrentView = view => {
  return {
    type: actionTypes.UPDATE_CURRENT_VIEW,
    currentView: view
  };
};

const updateTimeFilterAttributes = values => {
  return {
    type: actionTypes.UPDATE_TIME_FILTER,
    timeFilter: values
  };
};

const fetchBaseLayersStart = () => {
  return {
    type: actionTypes.FETCH_BASE_LAYERS_START
  };
};

const fetchBaseLayersSuccess = data => {
  return {
    type: actionTypes.FETCH_BASE_LAYERS_SUCCESS,
    data: data
  };
};

export const fetchBaseLayers = () => {
  return dispatch => {
    dispatch(fetchBaseLayersStart());
    logger.info("Retrieving basemap layers from server...")
    axios
      .get("/v2/basemaps")
      .then(response => {
        replaceTableInDB("baselayers", response.data);
        return response.data;
      })
      .then(data => dispatch(fetchBaseLayersSuccess(data)))
      .catch(err => logger.error('Error fetching base layers from server.', err));
  };
};

const fetchReferenceLayersStart = () => {
  return {
    type: actionTypes.FETCH_REF_LAYERS_START
  };
};

const fetchReferenceLayersSuccess = data => {
  return {
    type: actionTypes.FETCH_REF_LAYERS_SUCCESS,
    data: data
  };
};

export const fetchReferenceLayers = () => {
  console.clear()
  return dispatch => {
    dispatch(fetchReferenceLayersStart());
    logger.info("Retrieving reference layers from server...")
    axios
      .get("/v2/overlays")
      .then(response => {
        const enforce_pmtile = true;
        
        // Loop over results to retrofit the URL to the new format
        // https://vt.{region}.satelytics.io/tile/{uuid}/{table_name}/{z}/{x}/{y}
        // Temporary fix for usa and demo
        let host = window.location.hostname;
        
        // Localhost testing
        // host = "otg.dev.satelytics.io";

        const _region = host.split(".")[1]

        response.data.forEach(reference_layer => {
          if (reference_layer.data.hasOwnProperty('format')){
            // Has new format
            if (reference_layer.data.format == 'both' && !enforce_pmtile){
              // Use the old format
              console.info("Using old format for " + reference_layer.name);
              reference_layer.data.url = reference_layer.data.alt_url;
              if (reference_layer.data.hasOwnProperty("alt_tableName")){
                reference_layer.data.layerOptions.vectorTileLayerStyles[reference_layer.data.alt_tableName] = reference_layer.data.layerOptions.vectorTileLayerStyles[Object.keys(reference_layer.data.layerOptions.vectorTileLayerStyles)[0]];
                console.log("Using alternate table name for rendering: ", reference_layer.data.alt_tableName )
              }
            } else {
              // Use the new format
              // Be sure to use the new DNS namespaces
              reference_layer.data.url = reference_layer.data.url.replace('vt.', 'vtiles.').replace('.eu-central-1.','.eua.').replace('.ap-southeast-2.','.apa.').replace('.ca.','.caa.').replace('vtiles.satelytics.', 'vtiles.' + _region + '.satelytics.').replace('/data/', '/tile/').replace('.pbf', '');
              reference_layer.data.download_url = reference_layer.data.url.replace('/{z}/{x}/{y}', '.pmtiles').replace('/tile/', '/');
              console.info("Using new format for " + reference_layer.name);
            }
          } else {
            // Has old format
            if (enforce_pmtile){
              console.info("Enforcing new format for " + reference_layer.name);
              if (reference_layer.data.hasOwnProperty("url")){
                reference_layer.data.url = reference_layer.data.url.replace('vt.', 'vtiles.').replace('vectortile.', 'vtiles.').replace('vector-tile-server.', 'vtiles.').replace('.eu-central-1.','.eua.').replace('.ap-southeast-2.','.apa.').replace('.ca.','.caa.').replace('vtiles.satelytics.', 'vtiles.' + _region + '.satelytics.').replace('/data/', '/tile/').replace('.pbf', '')
                const re_pattern = /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}\-/
                const match = re_pattern.exec(reference_layer.data.url)
                if (match){
                  reference_layer.data.url = reference_layer.data.url.replace(match[0], match[0].slice(0,-1) + "/").split("?")[0]
                  reference_layer.data.download_url = reference_layer.data.url.replace('/{z}/{x}/{y}', '.pmtiles').replace('/tile/', '/');
                }
              }
            } else {
              console.info("Using old format for " + reference_layer.name);
            }
          }


          // Get rid of unnecessary properties
          delete reference_layer.code;
          if (enforce_pmtile) {
            delete reference_layer.data.alt_url;
          }

        })

        replaceTableInDB("reflayers", response.data)
        return response.data;
      })
      .then(data => dispatch(fetchReferenceLayersSuccess(data)))
      .catch(err => {
        logger.error('Error fetching reference layers from server', err)
        console.log(err)
      });
  };
};
