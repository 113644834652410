import * as actionTypes from "../actions/actionTypes";
import {
  updateObject
} from "../utility";

const initialState = {
  swUpdated: false,
  online: false,
  darkMode: false,
  debugEnabled: true,
  isLoading: false,
  gps:{
    watch:false,
    highAccuracy: false
  }
};

const updateAvailable = (state, action) => {
  return updateObject(state, {
    swUpdated: true
  });
};

const toggleThemeMode = (state, action) => {
  return updateObject(state, {
    darkMode: action.darkMode
  });
};

const updateGPSSettings = (state, action) => {
  return updateObject(state, {
    gps: action.data
  });
};

const appOnline = (state, action) => {
  return updateObject(state, {
    online: true
  });
};

const appOffline = (state, action) => {
  return updateObject(state, {
    online: false
  });
};

const enableDebug = (state, action) => {
  return updateObject(state, {
    debugEnabled: true
  });
};

const disableDebug = (state, action) => {
  return updateObject(state, {
    debugEnabled: false
  });
};

const appIsLoading = (state, action) => {
  return updateObject(state, {
    isLoading: action.isLoading
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_AVAILABLE:
      return updateAvailable(state, action);
    case actionTypes.APP_TOGGLE_THEME_MODE:
      return toggleThemeMode(state, action);
    case actionTypes.UPDATE_GPS_SETTINGS:
      return updateGPSSettings(state, action);
    case actionTypes.APP_ISLOADING:
      return appIsLoading(state, action);
    case actionTypes.APP_ONLINE:
      return appOnline(state, action);
    case actionTypes.APP_OFFLINE:
      return appOffline(state, action);
    case actionTypes.DEBUG_ON:
      return enableDebug(state, action);
    case actionTypes.DEBUG_OFF:
      return disableDebug(state, action);
    default:
      return state;
  }
};

export default reducer;
