import db from "../../store/db"

/**
 * Removes any log older than 2 weeks.
 */
const purgeOldLogs = () => {
    
    let dateCuttoff = new Date();
    dateCuttoff.setDate(dateCuttoff.getDate() -14);
    console.info("Purging logs older than "+ dateCuttoff.toLocaleString())
    db.table('logs').where('timestamp').below(dateCuttoff.toISOString()).delete()
}

export default purgeOldLogs;