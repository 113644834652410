import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";

const styles = theme => ({
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing(3)
  }
});

class CheckboxesGroup extends React.Component {
    constructor(props) {
      super(props);

      const _data = props.domainValues.map(item => {
        if (!item.checked) {
          item.checked = false;
        }
        return item;
      });
      this.state = { values: _data };
    };

  
  
  changeHandler = event => {
    const valueIndex = this.state.values.findIndex(item => {
      const _id = isNaN(event.target.value)
        ? event.target.value
        : parseInt(event.target.value);
      return item.id === _id;
    });

    const updatedItem = { ...this.state.values[valueIndex] };
    updatedItem.checked = event.target.checked;

    const valuesCopy = [...this.state.values];
    valuesCopy[valueIndex] = updatedItem;

    this.setState({ values: valuesCopy });

    if (this.props.emitEvent) {
      this.props.emitEvent(event);
    }
  };

  render() {
    // console.log("Render chekcbox component", this.state, Date());
    const { classes, labelProperty = "name" } = this.props;
    const items = this.state.values.map(item => {
      return (
        <FormControlLabel
          key={item.id}
          control={
            <Checkbox
              checked={item.checked}
              value={item.id.toString()}
              onChange={this.changeHandler}
            />
          }
          label={item[labelProperty]}
        />
      );
    });
    return (
      <div className={classes.root}>
        <FormControl className={classes.formControl}>
          <FormLabel>{this.props.formLabel}</FormLabel>
          <FormGroup>{items}</FormGroup>
          <FormHelperText>{this.props.helperText}</FormHelperText>
        </FormControl>
      </div>
    );
  }
}

CheckboxesGroup.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(CheckboxesGroup);
