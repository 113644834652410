
import React, { Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  ThemeProvider, createTheme, makeStyles, withStyles
} from "@material-ui/core/styles";
import CssBaseline from '@material-ui/core/CssBaseline';
import themeObject from "./theme";
import AppShell from "./components/AppShell/AppShell"
import alertContainer from "./containers/alertContainer/alertContainer";
import homeContainer from "./containers/homeContainer/homeContainer";
import filters from "./components/Filters/Filters";
import Auth from "./containers/Auth/Auth";
import Account from './containers/accountContainer/accountContainer';
import Logout from "./containers/Auth/Logout/Logout";
import Settings from "./containers/Settings/Settings";
import Debug from './containers/Debug/Debug';
import * as actions from "./store/actions/index";
import {
  getDarkMode
} from "./store/localStorage/index";
import { isAuthenticated, isOnline, isSoftwareUpdated, isDarkMode, isLoading } from "./store/selectors/app"

class App extends Component {
  constructor(props){
    super(props);
    
    // const {palette: { type }} = themeObject;
    // const updatedTheme = {
    //   ...themeObject,
    //   palette: {
    //     ...themeObject.palette,
    //     type: props.darkMode ? 'dark' : 'light'
    //   }
    // }

    // this.state = {
    //   theme : updatedTheme
    // }
  }

  // useDarkMode = () => {
  //   const theme = this.state.theme;
    
  //   const {palette: { type }} = theme;
  //   const toggleDarkMode = () => {
  //     const updatedTheme = {
  //       ...theme,
  //       palette: {
  //         ...theme.palette,
  //         type: type === 'light' ? 'dark' : 'light'
  //       }
  //     }
  //     this.setState({theme:updatedTheme});
  //   }

  //   return [theme, toggleDarkMode]
  // }

  toggleDarkMode = () => {
    // console.log("App is Using Dark Mode:", this.props.darkMode)
    return {
      ...themeObject,
      palette: {
        ...themeObject.palette,
        type: this.props.darkMode ? 'dark' : 'light'
      }
    }
  }
  
  componentDidMount() {
    console.log("App.js component did mount")
    this.props.onRedirectPath(this.props.location.pathname);
    this.props.onTryAutoSignin();
    this.props.setMode(getDarkMode())
  }

  render() {
    // const [theme, toggleDarkMode] = this.useDarkMode();
    const themeConfig = createTheme(this.toggleDarkMode());

    let routes = (
      <Switch>
        <Route path="/auth" component={Auth} />
        <Redirect to="/auth" />
      </Switch>
    );

    if (this.props.isAuthenticated) {
      routes = (
        <Switch>
          <Route path="/alerts" component={alertContainer} />
          <Route path="/settings" component={Settings} />
          <Route path='/debug' component={Debug} />
          <Route path="/filters" component={filters} />
          <Route path="/logout" component={Logout} />
          <Route path="/auth" component={Auth} />
          <Route path="/account" component={Account} />
          <Route path="/" exact component={homeContainer} />
          <Redirect to="/" />
        </Switch>
      );
    }

    return (
          <ThemeProvider theme={themeConfig}>
            <CssBaseline />
            <AppShell 
              isAuthenticated={this.props.isAuthenticated} 
              online={this.props.online} 
              updated={this.props.updated} 
              loading={this.props.loading}
              progress={this.props.progress}
            >
              {routes}
            </AppShell>
          </ThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: isAuthenticated(state),
    darkMode: isDarkMode(state),
    online: isOnline(state),
    updated: isSoftwareUpdated(state),
    loading: isLoading(state)
  };
};

const mapDispatchToProps =  {
    onTryAutoSignin: () => actions.authCheckState(),
    onRedirectPath: path => actions.setAuthRedirectPath(path),
    setMode: mode => actions.toggleThemeMode(mode)
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
