import React, { Component } from "react";
import L from "leaflet";


import { withStyles } from "@material-ui/core/styles";
import Modal from "../UI/Modal/Modal";
import LayerControl from "../../containers/LayerControl/LayerControl";
import {
  initializeObservationLayer,
  initializeMap,
  addMarkers
} from "../../modules/Map/map";
import BookmarkMenu from "../BookmarkMenu/BookmarkMenu";
import Button from '@material-ui/core/Button';
import AddComment from "@material-ui/icons/AddComment";
import AddAPhoto from "@material-ui/icons/AddAPhoto";
import * as contentviews from "../../constants/contentViews";


const styles = theme => ({
  map: {
    width: "100%",
    height: "calc(100% - 6rem)",
    position: "fixed"
  },
  detailmap: {
    width: "100%",
    height:"100%",
  },  
  active: {
    display: "block",
    height: `calc(100% - 4.0rem)`
  },
  inactive: {
    display: "none"
  },
  bookmarkbutton: {
    position:"absolute",
    right:"10px",
    top:"10px",
    zIndex:"500"
  }
});

function Map(props) {
  const {
    children,
    classes,
    detail,
    id
  } = props;
  
  return <div className={detail ? classes.detailmap : classes.map} id={id} >{children}</div>;
}

const StyledMap = (withStyles(styles)(Map));

const StyledButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[800],
  },
}))(Button);

class Map2 extends Component {
  componentDidMount() {
    this.map = initializeMap(this.props.id);
    this.props.mapInitialized(this.map);
    const observationLayer = initializeObservationLayer(this.map);
    
    if (this.props.observation) {
      
      observationLayer.addLayers(addMarkers([this.props.observation], contentviews.DETAIL_VIEW_MAP, null))
      if (this.props.observation.geometry.type === "Polygon") {
        this.map.fitBounds(L.geoJSON(this.props.observation).getBounds());
      } else {
        if (this.props.observation.scene_based){
          this.map.fitBounds(this.props.observation.scene_boundary);
        } else {
          this.map.setView(
            L.geoJSON(this.props.observation).getBounds().getCenter(),
            this.map.getMaxZoom()
          );
        }
        
      }
    }
  }

  state = {};

  componentDidUpdate() {
    if (this.map && this.props.isVisible) {
      // If orientation is changed while viewing a non-map pane
      // refresh the map as the height/width dimensions have changed
      // This will fire on a state change event
      this.map.invalidateSize();
    }
  }

  bookmarkClickHandler = item => {
    if (item.hasOwnProperty("boundingbox")){
      this.map.fitBounds(item.boundingbox)
    }
  }

  render() {
    // ensure the point based observation has a valid bounding box entry
    if (!this.props.bookmarks[0].boundingbox && this.map){
      this.props.bookmarks[0].boundingbox = this.map.getBounds()
    }

    return (
      <React.Fragment>
        <Modal
          show={this.props.showLayerControl}
          modalClosed={this.props.toggleLayerControl}
        >
          <LayerControl
            observation={this.props.observation}
            cancel={this.props.toggleLayerControl}
            map={this.map}
          />
        </Modal>
        <div
          className={this.props.isVisible ? this.props.classes.active : this.props.classes.inactive}
        >
          <div style={{width:"100%", height:"100%"}} id={this.props.id}>
          <div className={this.props.classes.bookmarkbutton} >
              <BookmarkMenu clicked={this.bookmarkClickHandler} bookmarks={this.props.bookmarks}/>
              {/* <div>
              <StyledButton
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                disableRipple="true"
              >
                <AddComment />
              </StyledButton>
              </div>
              <div>
              <StyledButton
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                disableRipple="true"
              >
                <AddAPhoto />
              </StyledButton>
              </div> */}
            </div>
          </div>
          {/* <StyledMap detail={this.props.detail} id={this.props.id ? this.props.id : "map"} >
            <div className={this.props.classes.bookmarkbutton} >
              <BookmarkMenu clicked={this.bookmarkClickHandler} bookmarks={this.props.bookmarks}/>
            </div>
          </StyledMap> */}
        </div>
      </React.Fragment>
    );
  }
}


// const mapStateToProps = state => {
//   return {};
// };

// const mapDisptachToProps = dispatch => {
//   return {};
// };
export default withStyles(styles)(Map2);
