import axiosApi from "./axios-api";
import {
  getDateRangeSelection,
  getLatestObservationDate,
  setLatestObservationDate,
  removeLatestObservationDate,
  setDateRangeSelection
} from "../store/localStorage/index";
import * as settings from "../constants/settings";
import SioLogger from "../modules/Logger/logger";

const logger = SioLogger('src/api/functions.js');

export const validateDateRange = () => {
  let DRS = getDateRangeSelection();
  if (!DRS) {
    DRS = setDefaultDRSDefinition();
  }

  return updateDatesForRefresh(DRS).then(result => {
    DRS.startDate = result.startDate;
    DRS.endDate = result.endDate;
    setDateRangeSelection(DRS);
    return result;
  });
};

export const getTimeWindow = () => {
  const DRS = getDateRangeSelection();
  return { startDate: DRS.startDate, endDate: DRS.endDate };
};

const assignEndDate = serverDate => {
  let endDate;
  let storageDate = getLatestObservationDate();
  if (storageDate) {
    endDate = new Date();
    endDate.setTime(storageDate);
    // Need to use getTime function as default comparison
    // does not include partial seconds
    if (endDate.getTime() < serverDate.getTime()) {
      endDate = new Date(serverDate.getTime());
      setLatestObservationDate(endDate.getTime());
    }
  } else {
    endDate = new Date(serverDate.getTime());
    setLatestObservationDate(endDate.getTime());
  }
  return endDate;
};

const updateDatesForRefresh = DRS => {
  let startDate, endDate;

  removeLatestObservationDate();

  const reply = new Promise((resolve, reject) => {
    switch (DRS.id) {
      case settings.DATE_RANGE_SELECTION_LATEST:
        // Get Latest Observation Date and compare to localStorage value
        axiosApi
          .get("/v2/observations/latestdate")
          .then(response => {
            const serverDate = new Date(response.data.latest_observation);
            endDate = assignEndDate(serverDate);

            startDate = new Date(endDate.getTime());
            startDate.setDate(endDate.getDate() - DRS.numDays);

            resolve({
              id: settings.DATE_RANGE_SELECTION_LATEST,
              numDays: DRS.numDays,
              startDate: formatDateForQuery(startDate),
              endDate: formatDateForQuery(endDate)
            });
          })
          .catch(error => {
            logger.error('Error retrieving latest date: ' + error.message, error);
            
            const result = setDefaultDRSDefinition();

            resolve({
              id: result.id,
              numDays: result.numDays,
              startDate: result.startDate,
              endDate: result.endDate
            });
          });

        break;
      case settings.DATE_RANGE_SELECTION_CUSTOM:
        startDate = DRS.startDate;
        endDate = DRS.endDate;
        resolve({ startDate: startDate, endDate: endDate });
        break;
      default:
      //Do nothing
    }
  });
  return reply;
};

const formatDateForQuery = date => {
  return (
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2)
  );
};

const setDefaultDRSDefinition = () => {
  const numDays = 180; // Make variable
  const startDate = new Date();
  const endDate = new Date();
  startDate.setDate(endDate.getDate() - numDays);
  return {
    id: settings.DATE_RANGE_SELECTION_LATEST,
    numDays: numDays,
    startDate: formatDateForQuery(startDate),
    endDate: formatDateForQuery(endDate)
  };
};
