import React from "react";
import { withStyles } from '@material-ui/core/styles';
// import classes from "./obsDetail.css";
import Content from "../../components/DetailView/Content/Content";
import Header from "../../components/DetailView/Header/Header";

const styles = theme => ({
  detailPane: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
  }
  
});

const alertDetail = props => {
  return (
    <React.Fragment>
      <div className={props.classes.detailPane}>
        <Header
          observation={props.observation}
          closeDetails={props.closeDetails}
          statusChangeHandler={props.statusChangeHandler}
        />
        <Content
          observation={props.observation}
          bookmarks={props.bookmarks}
          closeDetails={props.closeDetails}
          statusChangeHandler={props.statusChangeHandler}
        />
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(alertDetail);
