import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  button: {
    margin: theme.spacing(1)
  },
  input: {
    display: "none"
  }
});

function ContainedButtons(props) {
  const {
    classes,
    label = "",
    disabled = false,
    color = "primary",
    variant = "contained",
    size = "medium"
  } = props;
  return (
    <Button
      variant={variant}
      color={color}
      disabled={disabled}
      className={classes.button}
      onClick={props.clicked}
      size={size}
    >
      {label}
    </Button>
  );
}

ContainedButtons.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ContainedButtons);
