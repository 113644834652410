import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles
} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import ListSubheader from '@material-ui/core/ListSubheader';
import { Accordion } from '@material-ui/core'
import { AccordionSummary } from '@material-ui/core'
import { AccordionDetails } from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
});

class CheckboxList extends React.Component {
  constructor(props) {
    super(props)
  }

  handleToggle = value => () => {

    let checked = this.patchCheckedFromProps();
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {

      if (value) newChecked.push(value);
    } else {

      newChecked.splice(currentIndex, 1);
    }
    this.props.onChange(checked, newChecked, this.props.title);

  };

  patchCheckedFromProps = () => {

    let checked = [];

    //add item that are checked from the props
    let checkedFromProps = this.props.items.filter(item => item.checked)
      .map(item => item.id);

    checkedFromProps.forEach(id => {
      if (checked.indexOf(id) < 0) checked.push(id)
    });
    return checked;
  };

  toggleAll = items => (e) => {
    let checked = this.patchCheckedFromProps();
    const newChecked = [...checked];
    const hasChecked = items.some(item => item.checked);
    if (hasChecked) {
      items.forEach(item => {
        const value = item.id
        const currentIndex = newChecked.indexOf(value);
        if (currentIndex !== -1) {
          newChecked.splice(currentIndex, 1);
        }
      });
    } else {
      items.forEach(item => {
        const value = item.id
        newChecked.push(value);
      });
    }

    this.props.onChange(checked, newChecked, this.props.title);
    e.stopPropagation();
  };
  generateListItems = (items, checked) => {

    return items.map(value => (
      <ListItem dense = { true } key = { value.id }
                    role = { undefined }
                    button onClick = {
                        this.handleToggle(value.id)
                    }
                    disabled={value.hasOwnProperty('enabled') && !value.enabled} 
                    >
                    <Checkbox checked = { checked.indexOf(value.id) !== -1 }
                    tabIndex = { -1 }
                    disableRipple />
                    <ListItemText primary = { value.name } />
                    </ListItem>
    ))

  }

  generateListItemsWithLevel = (items, checked) => {
    if (items.length === 0) return;
    let level = items[0].level;
    if (level === undefined) return this.generateListItems(items, checked);
    let uniqueLevels = [];
    items.forEach(item => {
      if (uniqueLevels.indexOf(item.level) < 0)
        uniqueLevels.push(item.level);
    });
    let itemList = [];
    uniqueLevels.forEach(level => {
      let groupedItems = [];
      items.forEach(item => {
        if (item.level === level)
          groupedItems.push(item)
      });
      let count = 0;
      itemList.push(<Accordion key={'A-' + level + '-' + count++} defaultExpanded={false}>
           <AccordionSummary expandIcon={<ExpandMoreIcon />}>
           <Checkbox checked = {groupedItems.every(item=>item.checked) }
           indeterminate = {groupedItems.some(item=>item.checked) &&
            groupedItems.some(item=>!item.checked)}
            onClick = {this.toggleAll(groupedItems)}
                    tabIndex = { -1 }
                    disableRipple />
            <ListSubheader disableGutters = { true } > { level } </ListSubheader>
            </AccordionSummary>
           <AccordionDetails >
            <List  dense = { true }>
              {this.generateListItems(groupedItems, checked)}
            </List>
           </AccordionDetails>
                 </Accordion>)
    });
    return itemList;
  };

  render() {
    const {
      items,
      classes
    } = this.props;
    let checked = [];

    checked = items.filter(item => item.checked)
      .map(item => item.id);

    return (<div>
            <Accordion defaultExpanded={false} >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Checkbox checked = {items.every(item=>item.checked) }
                    indeterminate = {items.some(item=>item.checked) &&
                    items.some(item=>!item.checked)} onClick = {this.toggleAll(items)}
                    tabIndex = { -1 }
                    disableRipple />
                <ListSubheader  disableGutters = { true } > { this.props.title } </ListSubheader>
              </AccordionSummary>
              <AccordionDetails >
                <List className = { classes.root } dense = { true }>
                  { this.generateListItemsWithLevel(items, checked)}
                </List>
              </AccordionDetails>
            </Accordion>
          </div>);
  }
}

CheckboxList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CheckboxList);
