import L from "leaflet";

import {
  cacheThenNetworkStrategy
} from "../../api/tileStrategies";

L.SatelyticsLayer = L.TileLayer.extend({
  initialize: function(url, options) {
    L.TileLayer.prototype.initialize.call(this, url, options);
    let {
      bounds,
      layername,
      maxZoom,
      minZoom,
      zoomlevels
    } = options;
    this._layername = layername || this._url;

    if (this._map) {
      this._bounds = bounds || this._map.getBounds();
      maxZoom = maxZoom || 20;
      minZoom = minZoom || 15;
      this._zoomlevels =
        zoomlevels ||
        Array.from(Array(maxZoom - minZoom + 1), (_, idx) => minZoom +
          idx);
    }
  },
  createTile: function(coords, done) {
    var tile = document.createElement("img");

    L.DomEvent.on(tile, "load", L.bind(this._tileOnLoad, this, done,
      tile));
    L.DomEvent.on(tile, "error", L.bind(this._tileOnError, this, done,
      tile));

    if (this.options.crossOrigin) {
      tile.crossOrigin = "";
    }

    tile.alt = "";

    tile.setAttribute("role", "presentation");

    this.getTileUrl(coords)
      .then(url => {
        tile.src = url;
        done(null, tile);
      })
      .catch(error => {
        done(error, tile); // Avoiding logging here due to spamming issues.
      });

    return tile;
  },

  getTileUrl: function(coords) {
    const URL = window.URL || window.webkitURL;
    const url = L.TileLayer.prototype.getTileUrl.call(this, coords);

    return new Promise((resolve, reject) => {

      cacheThenNetworkStrategy(url, {
          subdomains: this.options.subdomains ? this.options
            .subdomains : [],
          layername: this._layername,
          type: "dynamic",
          localDownload: false,
          silent: false,
          batch: false,
          imageType: this.options.imageType ? this.options
            .imageType : null
        })
        .then(response => {
          if (response instanceof ArrayBuffer) {
            const blob = new Blob([response], {
              type: "image/png"
            });
            resolve(URL.createObjectURL(blob));
          } else {
            // Need to reject as we don't want
            // an invalid image src rendered in the browser
            reject(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getTileUrls: function(bounds, zoom) {
    const origurl = this._url;
    // getTileUrl uses current zoomlevel, we want to overwrite it
    this.setUrl(this._url.replace("{z}", zoom), true);

    const tileBounds = L.bounds(
      bounds.min.divideBy(this.getTileSize()
        .x)
      .floor(),
      bounds.max.divideBy(this.getTileSize()
        .x)
      .floor()
    );

    let {
      max: {
        x: maxx,
        y: maxy
      },
      min: {
        x: minx,
        y: miny
      }
    } = tileBounds;
    let tiles = new Array((maxx - minx) * (maxy - miny));
    let count = 0;
    for (let i = minx; i <= maxx; i++) {
      for (let j = miny; j <= maxy; j++) {
        const tilePoint = new L.Point(i, j);
        tiles[count++] = L.TileLayer.prototype.getTileUrl.call(this,
          tilePoint);
      }
    }

    this.setUrl(origurl, true);

    return tiles;
  }
});

L.satelyticsLayer = (url, options) => {
  return new L.SatelyticsLayer(url, options);
};
